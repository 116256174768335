import { useTranslation } from 'react-i18next'
import React, { FC, useEffect, useState } from 'react'
import { styled } from '@mui/system'
import { Grid, Typography } from '@mui/material'

import Share from '../Share/Share'
import Seasons from '../Seasons/Seasons'
import AddToMyList from '../AddToMyList/AddToMyList'
import { getDataFromUrl } from '../../utils/utils'
import { VideoDetailSection } from '../../types/section'
import { useGetVideoDetailPageQuery } from '../../store/services/page/pageApi'

import More from './More/More'
import About from './About/About'

import './VideoDetail.scss'

interface VideoDetailProps {
  section: VideoDetailSection
}

export const VideoDetailStyle = styled(Grid)(({ theme }) => ({
  color: '#fff',

  '& .content-title': {
    fontFamily: 'Prompt',
    fontWeight: 300,
    fontSize: 40,
    maxWidth: 'fit-content',
    whiteSpace: 'noWrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  '& .rating': {
    background: '#7f7a7a',
    padding: '5px 16px',
    borderRadius: 5,
    maxHeight: 32,
    marginTop: 10
  },
  '& .year': {
    fontSize: 18,
    marginBlock: 5
  },
  '& .content': {
    width: '60%',
    margin: 'auto'
  },
  '& .more-content': {
    backgroundColor: '#1f1f1f',
    paddingBlock: 40,
    '& .more-content-item': {
      width: '100%',
      overflow: 'auto',
      marginLeft: '15%'
    }
  },
  [theme.breakpoints.between('sm', 'md')]: {},
  [theme.breakpoints.down('sm')]: {
    '& .content-header': {
      padding: '0 0px 40px 10px'
    },
    '& .content-title': {
      fontSize: 22
    },
    '& .year': {
      fontSize: 12
    },
    '& .share-icon': {
      flex: 1,
      marginTop: -50
    },
    '& .content': {
      width: '100%'
    },
    '& .About': {
      padding: 0
    },
    '& .About-tab': {
      marginLeft: 0
    },
    '& .more-content': {
      padding: 20,
      '& .more-content-item': {
        marginLeft: '0'
      }
    }
  }
}))
const VideoDetail: FC<VideoDetailProps> = ({ section, section: { content } }) => {
  const { t } = useTranslation()

  const getQuery = new URLSearchParams(window.location?.search)
  const trailerId = getQuery.get('trailer')
  const { data } = useGetVideoDetailPageQuery({ id: trailerId as string })
  const [updatedContent, setUpdatedContent] = useState(content)
  const objParam = getDataFromUrl(getQuery.get('data')!)
  const displayName = objParam['displayName']
  const seriesName = objParam['seriesName']

  useEffect(() => {
    if (trailerId) {
      setUpdatedContent(data?.sections[1]?.['content'])
    } else {
      setUpdatedContent(content)
    }
  }, [updatedContent, data, trailerId, content])

  useEffect(() => {
    document.title = trailerId ? '' + seriesName : displayName || t('app.title')
  }, [updatedContent])

  return (
    <VideoDetailStyle className='video-details'>
      <Grid container justifyContent='space-between' className='content content-header'>
        <Grid item xs={8} justifyContent={'center'}>
          <Grid item container gap={2} alignItems={'center'}>
            <Grid xs={8}>
              <Typography className='content-title' variant='h1'>
                {trailerId ? seriesName : displayName}
              </Typography>
            </Grid>
            {updatedContent?.custom_fields['rating'] && (
              <Grid justifyContent={'center'}>
                <Grid bgcolor={'#575757'} borderRadius={2} padding={1}>
                  {updatedContent?.custom_fields['rating']}
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid xs={2}>{updatedContent?.custom_fields['year']}</Grid>
        </Grid>

        <Grid item xs={4} style={{ flex: 'none' }}>
          <Grid item container>
            <Grid mt={1.4}>
              {(!trailerId || trailerId === '') && (
                <AddToMyList item={updatedContent} page={'vod_player'}></AddToMyList>
              )}
            </Grid>
            <Grid>
              <Share item={updatedContent} checkShare={true} page={'vod_player'}></Share>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item container>
        <Grid item className='content'>
          <About section={section} />
        </Grid>
      </Grid>
      {content?.series_id && content?.series_id !== '' && (
        <Grid item container>
          <Grid item className='content'>
            <Seasons series_id={content?.series_id} />
          </Grid>
        </Grid>
      )}
      {updatedContent?.related_content && updatedContent?.related_content.length > 0 && (
        <Grid item container className='more-content'>
          <Grid item className='more-content-item'>
            <More items={updatedContent?.related_content}></More>
          </Grid>
        </Grid>
      )}
    </VideoDetailStyle>
  )
}

export default VideoDetail
