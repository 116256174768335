import { useNavigate } from 'react-router'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import TagManager from 'react-gtm-module'
import React, { FC, useEffect, useState } from 'react'
import { Flex } from '@brightcove/studio-components'

import AddToMyList from '../AddToMyList/AddToMyList'
import {
  clearPrevTrailerIds,
  getItemDetailScreen,
  isVideoItem,
  objectToQueryString
} from '../../utils/utils'
import { VideoContentItem } from '../../types/item'
import './SearchTile.scss'
import { userSelector } from '../../store/slices/config/configSelectors'
import { SVGImage } from '../../assets/images'

interface SearchTileProps {
  item: VideoContentItem
  section?: any
}

const SearchTile: FC<SearchTileProps> = ({ item, section }) => {
  const { t } = useTranslation()
  const [tileInfo, setTileInfo] = useState(item)
  const { name, display_name } = tileInfo
  const year = tileInfo.custom_fields?.['year']
  const navigate = useNavigate()
  const user = useSelector(userSelector)

  const getImageSource = (itemToCheck) => {
    if (isVideoItem(itemToCheck)) {
      return itemToCheck?.images?.['poster']?.src
    } else {
      return itemToCheck.image
    }
  }

  const showContentDetailsRoute = (item) => {
    clearPrevTrailerIds()
    let videoDetails = {
      seriesName: '',
      next_up_id: '',
      seriesDescription: '',
      displayName: ''
    }
    const id = getItemDetailScreen(item)
    const tagManagerArgs = {
      dataLayer: {
        event: 'card_click',
        page_name: 'search',
        event_action: 'click_card',
        page_location: location?.href,
        user_id: user?.uid,
        collection_title: item?.name,
        content_id: id?.video_id,
        content_position: section?.findIndex((obj) => obj?.video_id === id?.video_id)
      }
    }
    TagManager.dataLayer(tagManagerArgs)
    if (
      id?.series_id !== '' &&
      id?.['seriesTrailerId'] !== '' &&
      item?.['collection_type'] === 'Series'
    ) {
      videoDetails = {
        seriesName: item?.name,
        next_up_id: '',
        seriesDescription: item?.description ? item?.description : '',
        displayName: id?.display_name ? id?.display_name : ''
      }
      navigate(
        `/video/${id?.video_id}?trailer=${id?.[`seriesTrailerId`]}&data=${btoa(
          objectToQueryString(videoDetails)
        )}`
      )
    } else if (
      id?.series_id !== '' &&
      id?.['seasonTrailerId'] !== '' &&
      item?.['collection_type'] === 'Season'
    ) {
      videoDetails = {
        seriesName: item?.name,
        next_up_id: item?.id,
        seriesDescription: item?.description ? item?.description : '',
        displayName: id?.display_name ? id?.display_name : ''
      }
      navigate(
        `/video/${id?.video_id}?trailer=${id?.[`seasonTrailerId`]}&data=${btoa(
          objectToQueryString(videoDetails)
        )}`
      )
    } else {
      videoDetails = {
        seriesName: '',
        next_up_id: '',
        seriesDescription: '',
        displayName: id?.display_name ? id?.display_name : ''
      }
      navigate(`/video/${id?.video_id}?data=${btoa(objectToQueryString(videoDetails))}`)
    }
  }

  useEffect(() => {
    setTileInfo(item)
  }, [item])

  useEffect(() => {
    document.title = 'oneD: ' + t('search.header_search_placeholder')
  }, [])

  return (
    <Flex className='Search-Tile-info' flexDirection='row'>
      <Flex className='Search-Tile-img'>
        <div
          className='search-thumbnail'
          style={{ backgroundImage: `url(${getImageSource(item)})` }}
          onClick={() => showContentDetailsRoute(item)}
        />
      </Flex>
      <Flex className='Search-Tile-info-metadata' flexDirection='column'>
        <Flex className='Search-Tile-wrapper' flexDirection='column'>
          <label className='Search-Tile-info-title'>{display_name || name}</label>
          <label className='Search-Tile-info-sub-title'>{year}</label>
        </Flex>
        <Flex className='Search-Tile-button' flexDirection='row'>
          <div onClick={() => showContentDetailsRoute(item)}>
            <Flex className='Search-Tile-wrapper-button' flexDirection='row'>
              <img className={'Search-Tile-icon'} src={SVGImage.PlayIcon} />
              <label className='Search-Tile-info-button-text'>{t('search.play')}</label>
            </Flex>
          </div>
          {isVideoItem(item) && (
            <div className='Tile-wrapper-add'>
              <AddToMyList item={item}></AddToMyList>
            </div>
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default SearchTile
