import { Dispatch } from 'redux'
import { useLocation } from 'react-router-dom'
import { useState } from 'react'
import { fetchSignInMethodsForEmail } from 'firebase/auth'
import classnames from 'classnames'

import { getStorage, isLocalStorageSupported } from '../utils/localStorageSupport'
import { setToken } from '../store/slices/config/configSlice'
import { auth } from '../firebase'

export const activeClassName = (className: string, link: string): string => {
  const { pathname } = useLocation()
  return classnames({ [className]: link?.includes(pathname) })
}

export function useLocalStorage(
  key: string,
  initialValue?: string,
  type: 'local' | 'session' = 'local'
): any {
  const storage = getStorage()

  const storageType = !isLocalStorageSupported()
    ? type === 'local'
      ? localStorage
      : sessionStorage
    : storage
  const getLocalItem = storageType?.getItem(key)
  const initial: string | undefined = getLocalItem ? getLocalItem : initialValue
  const [value, setValue] = useState<string>(initial as string)
  const setStoredValue = (newValue: string | any) => {
    const valueToStore = newValue instanceof Function ? newValue(value) : newValue
    setValue(valueToStore)
    storageType?.setItem(key, valueToStore)
  }

  return [value, setStoredValue] as const
}

function checkEmailExist(email: string) {
  return fetchSignInMethodsForEmail(auth, email)
    .then((user) => user.length > 0)
    .catch(() => false)
}

export function useCheckEmailExists(): any {
  const checkEmail = async (email: string) => {
    if (email.trim() !== '') {
      return await checkEmailExist(email)
    }
  }
  return { checkEmail }
}

export const refreshToken = async (dispatch: Dispatch): Promise<void> => {
  const user = auth?.currentUser
  if (user) {
    const token = await user.getIdToken(true)
    if (token) dispatch(setToken(token))
  }
}
