import { Route, Routes } from 'react-router'
import React, { FC } from 'react'

import Subscription from '../Subscription'
import ProfileDetail from '../ProfileDetail'
import NotificationsPage from '../NotificationsPage'
import useSubscriptionStatus from '../../Common/SubscriptionStatus/SubscriptionStatus'

type ProfileContentProps = {
  isProfileVisible?: boolean
}

const ProfileContent: FC<ProfileContentProps> = ({ isProfileVisible = false }) => {
  const isSubscriptionUser = useSubscriptionStatus()

  if (isProfileVisible) return null
  return (
    <div className='content'>
      <Routes>
        <Route path='/subscription' element={isSubscriptionUser && <Subscription />} />
        <Route path='/profile-detail' element={<ProfileDetail />} />
        <Route path='/notification' element={<NotificationsPage />} />
      </Routes>
    </div>
  )
}

export default ProfileContent
