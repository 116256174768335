/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unsafe-optional-chaining */
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux' // useSelector
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import TagManager from 'react-gtm-module'
import React, { FC, useEffect, useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { Button, Grid } from '@mui/material'
import { Flex, Form, Label } from '@brightcove/studio-components'

import useSubscriptionStatus from '../Common/SubscriptionStatus/SubscriptionStatus'
import DialogMaxChars from '../Common/DialogMaxChars/DialogMaxChars'
import { setUserProfileData } from '../../store/slices/userProfileSlice'
import {
  // useGetUserProfileQuery,
  useUpdateUserProfileMutation
} from '../../store/services/user/userProfileApi'
import { useLazyGetMyListApiQuery } from '../../store/services/user/myListApi'
import { RootState } from '../../store'
import { useBreakPoints } from '../../hooks/useBreakPoints'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import { charsMaxLength } from '../../constants'

import ProfileFieldItems from './Components/ProfileFields'
import ProfileEditMobileHeader from './Components/ProfileEditMobileHeader'

import './ProfileDetail.scss'

const ProfileDetail: FC = () => {
  const { t } = useTranslation()
  const [isEditable, setIsEditable] = useState(false)
  const dispatch = useDispatch()

  const { data: userData, dayDisable } = useSelector((state: RootState) => state.userProfile)

  const { isMobile } = useBreakPoints()
  const [updateUserProfile] = useUpdateUserProfileMutation()

  const [profileLazyData]: any = useLazyGetMyListApiQuery()
  const [, setProfileData] = useState()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false)
  const [isMaxValidation, setIsMaxValidation] = useState<boolean>(false)
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const isSubscriptionUser = useSubscriptionStatus()

  useEffect(() => {
    const getProfileData = async () => {
      try {
        setIsLoading(true)
        const profile = await profileLazyData()
        setProfileData(profile?.data)
        dispatch(setUserProfileData(profile?.data))
        setIsLoading(false)
      } catch (err) {
        dispatch(setUserProfileData(null))
        setIsLoading(false)
        // Error
      }
    }
    getProfileData()
  }, [profileLazyData])

  const [updatedData, setUpdatedData] = useState<any>({})
  const [checkdisabled, setCheckDisabled] = useState('')

  const {
    handleSubmit,
    // setValue,
    // getValues,
    register,
    setError,
    formState: { errors, isSubmitted },
    reset,
    clearErrors
  } = useForm({})

  const setFormData = (data) => {
    setUpdatedData(data)
  }

  const [inputValues, setInputValuse] = useState<any>({})

  const appendCountryCode = (phone) => {
    //let validatePhone = '';
    if (/^\d{10}$/.test(phone) === true && /^0/.test(phone) === true) {
      return '+66' + phone.slice(1)
    } else {
      return phone
    }
  }
  const phoneRegex = /^(\+\d{1,4})?\d{10,}$/
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

  const onSubmit = async (data) => {
    confirmClick()
    setIsSubmitLoading(true)

    let newData = {
      ...data,
      ...updatedData,
      gender: updatedData.gender ? updatedData?.gender : userData?.gender,
      date_of_birth: updatedData.date_of_birth
        ? updatedData?.date_of_birth
        : userData?.date_of_birth
    }
    if (newData?.firstName.length > charsMaxLength || newData?.lastName.length > charsMaxLength) {
      setIsDialogOpen(true)
      if (isMaxValidation) {
        return
      }
      return
    }
    // let newData = {
    //   firstName: 'deva',
    //   lastName: 'O',
    //   email: 'nayada4657@avidapro.co',
    //   phone: '+11111111111',
    //   date_of_birth: '',
    //   gender: ''
    // }
    let validatedPhone = { phone: '' }
    validatedPhone.phone = appendCountryCode(newData?.phone)
    newData = { ...newData, ...validatedPhone }

    if (!emailRegex.test(newData?.email)) {
      setError('email', {
        type: 'manual',
        message: t('profile.invalid_email') as string
      })
      setIsSubmitLoading(false)
      return
    }
    if (!phoneRegex.test(newData?.phone)) {
      setError('phone', {
        type: 'manual',
        message: t('profile.invalid_phone') as string
      })
      setIsSubmitLoading(false)
      return
    }

    try {
      // Update the user data on the server
      const response: any = await updateUserProfile(newData)
      if ('data' in response) {
        dispatch(setUserProfileData(response?.data))
        setIsEditable(false)
        setIsSubmitLoading(false)
      }
      if ('error' in response) {
        setIsSubmitLoading(false)
        if (response.error?.data) {
          if (response.error.data.message.includes('email-already-exists')) {
            setError('email', {
              type: 'manual',
              message: t('signup.email_exist') as string
            })
            return
          }
        }
        if (response.error?.data) {
          if (response.error.data.message.includes('phone-number-already-exists')) {
            setError('phone', {
              type: 'manual',
              message: t('signup.phone_exist') as string
            })
            return
          }
        }
        if (response.error?.data) {
          if (response.error.data.message.includes('auth/invalid-phone-number')) {
            setError('phone', {
              type: 'manual',
              message: t('profile.invalid_phone') as string
            })
            return
          }
        }
      }
    } catch (error) {
      setIsSubmitLoading(false)
      // console.error('Error updating user data:', error)
    }
  }

  const isProfileMobileEdit = isMobile && isEditable

  const onSetInputs = (value) => setInputValuse(value)

  const validUserData = {
    firstName: userData?.firstName,
    lastName: userData?.lastName,
    date_of_birth: userData?.date_of_birth,
    email: userData?.email,
    gender: userData?.gender,
    phone: userData?.phone
  }
  const [isEmptyUserData, setEmptyUserData] = useState(
    Object.values(validUserData).some((val) => val === '' || val === undefined)
  )

  const isDisabled =
    inputValues && Object.values(inputValues)?.some((val) => val === '' || val === undefined)

  useEffect(() => {
    if (isEmptyUserData) {
      const mergeValidation = { ...validUserData, ...inputValues }
      if (inputValues !== undefined) {
        setEmptyUserData(
          Object.values(mergeValidation).some((val) => val === '' || val === undefined)
        )
      }
    }
  }, [validUserData, inputValues])

  useEffect(() => {
    if (isSubmitted) {
      if (
        inputValues?.firstName?.length > charsMaxLength ||
        inputValues?.lastName?.length > charsMaxLength
      ) {
        setIsMaxValidation(true)
      } else {
        setIsMaxValidation(false)
      }
    }
  }, [inputValues, isSubmitted, setIsMaxValidation])

  const onDialogClose = () => {
    setIsDialogOpen(false)
    setIsSubmitLoading(false)
  }

  const editClick = () => {
    const tagManagerArgs = {
      dataLayer: {
        event: `profile_edit`,
        event_category: 'myaccount',
        event_action: `click_button`,
        event_label: 'edit_button'
      }
    }
    TagManager.dataLayer(tagManagerArgs)
  }
  const backClick = () => {
    const tagManagerArgs = {
      dataLayer: {
        event: `profile_back_button`,
        event_category: 'myaccount',
        event_action: `click_button`,
        event_label: 'back_to_myaccount'
      }
    }
    TagManager.dataLayer(tagManagerArgs)
  }
  const confirmClick = () => {
    const tagManagerArgs = {
      dataLayer: {
        event: `profile_confirm_info`,
        event_category: 'myaccount',
        event_action: `click_button`,
        event_label: 'confirm_info'
      }
    }
    TagManager.dataLayer(tagManagerArgs)
  }
  return (
    <Flex className={`profile-detail ${isProfileMobileEdit ? 'mobile' : ''}`}>
      {!isEditable && (
        <div className='profile-detail-header'>
          <h2 className='profile-detail-header-text'>
            {isMobile
              ? t('profile.profile') || ''
              : (
                  <>
                    {t('profile.update_profile')}
                    {isLoading && (
                      <CircularProgress
                        sx={{
                          color: '#fff',
                          display: 'inline-block',
                          marginInline: 2,
                          opacity: 0.5
                        }}
                        size={15}
                      />
                    )}
                  </>
                ) || ''}
          </h2>
          {!isEditable && (
            <Button
              className='edit-button'
              type='button'
              onClick={() => {
                setIsEditable(true)
                editClick()
              }}
              disabled={isLoading}
            >
              {t('profile.edit') || ''}
            </Button>
          )}
        </div>
      )}
      {isEditable && !isMobile && (
        <>
          <h2 className='profile-detail-header-text'>{t('profile.update_profile') || ''}</h2>
        </>
      )}
      {isProfileMobileEdit && <ProfileEditMobileHeader setIsEditable={setIsEditable} t={t} />}
      {!isProfileMobileEdit && <hr className='horizontal-line' />}
      <Form onSubmit={handleSubmit(onSubmit)}>
        {ProfileFieldItems({
          // data: userDataUpdated,
          // data: profileData,
          data: userData,
          register,
          errors,
          formData: updatedData,
          setFormData,
          setCheckDisabled,
          onSetInputs,
          clearErrors
        }).map((item) => (
          <div
            key={item.id}
            className={`profile-detail-form ${isProfileMobileEdit ? 'isMobile' : ''}`}
          >
            {!isProfileMobileEdit && item.label}
            <div style={{ flex: 1 }}>{!isEditable ? item.nonEditable : item.editable}</div>
          </div>
        ))}
        {isEditable && (
          <Grid container className='profile-detail-form profile-footer' display={'flex'}>
            {!isMobile && (
              <Label className='profile-detail-label'>
                <Grid item></Grid>
              </Label>
            )}
            <Grid
              item
              container
              style={{ flex: 1 }}
              display={'flex'}
              justifyContent={'space-between'}
            >
              <Button
                className='back-profile-button'
                type='button'
                sx={{ flex: '.5px' }}
                onClick={() => {
                  backClick()
                  setIsEditable(false)
                  // setuserData(userDataUpdated)
                  setUpdatedData({})
                  reset()
                }}
              >
                {t('profile.back') || ''}
              </Button>
              <Button
                className='profile-confirm-button'
                type='submit'
                sx={{ flex: '.5px' }}
                disabled={isDisabled || isEmptyUserData || dayDisable}
                endIcon={isSubmitLoading && <CircularProgress sx={{ color: '#fff' }} size={20} />}
              >
                {t('profile.confirm') || ''}
              </Button>
            </Grid>
          </Grid>
        )}
      </Form>
      {isEditable ||
        (!isSubscriptionUser ? (
          <Button component={Link} to={'/oned/premium'} className='subscribe_now-button'>
            {t('profile.subscribe_now') || ''}
          </Button>
        ) : (
          <Grid padding={2} />
        ))}
      <DialogMaxChars isDialogOpen={isDialogOpen} onDialogClose={onDialogClose} />
    </Flex>
  )
}

export default ProfileDetail
