import { useSelector } from 'react-redux'
import React, { FC } from 'react'

import VideoDetail from '../VideoDetail/VideoDetail'
import SearchBar from '../SearchBar/SearchBar'
import Search from '../Search/SearchSection'
import ReactPlayer from '../ReactPlayer/ReactPlayer'
import LivePlayer from '../ReactPlayer/LivePlayer'
import OneDOriginalDetail from '../OneDOriginalDetail/OneDOriginalDetail'
import Hero from '../Hero/Hero'
import FeaturedCarousel from '../FeaturedCarousel/FeaturedCarousel'
import CustomSection from '../CustomSection/CustomSection'
import Content from '../Content/Content'
import Carousel from '../Carousel/Carousel'
import {
  CarouselSection,
  CustomSectionType,
  HeroSection,
  PlayerSection,
  SearchBarSection,
  Section,
  VideoDetailSection,
  SearchSection,
  ContentSection
} from '../../types/section'
import { RootState } from '../../store'

import { EntitlementStyles } from './Sections.style'

interface SectionsProps {
  sections: Section[] | undefined
  type?: string
  page?: string
}

const Sections: FC<SectionsProps> = ({ sections, type, page }) => {
  const { isNonValidEntitlement } = useSelector((state: RootState) => state.entitlement)
  return (
    <>
      {sections?.map((section, index) => {
        switch (section.type) {
          case 'Hero':
            if (section?.['items'] && section?.['items'].length > 0) {
              return <Hero key={index} section={section as HeroSection} />
            }
            break
          case 'Carousel':
            if (section?.['items'] && section?.['items'].length > 0) {
              if (section?.['collectionType'] === 'Featured') {
                return (
                  <FeaturedCarousel key={index} section={section as CarouselSection} page={page} />
                )
              } else {
                return <Carousel key={index} section={section as CarouselSection} page={page} />
              }
            }
            break
          case 'Grid':
            return <Content key={index} sectionResult={section as ContentSection} />
          case 'SearchBar':
            return <SearchBar input={''} key={index} {...(section as SearchBarSection)} />
          case 'Player':
            if (type === 'OriginalPage') {
              return <LivePlayer key={index} section={section as PlayerSection} />
            } else if (!isNonValidEntitlement) {
              return <ReactPlayer key={index} section={section as PlayerSection} />
            } else {
              return <EntitlementStyles></EntitlementStyles>
            }

          case 'VideoDetail':
            if (type === 'OriginalPage') {
              return <OneDOriginalDetail key={index} section={section as VideoDetailSection} />
            } else {
              return <VideoDetail key={index} section={section as VideoDetailSection} />
            }
          case 'Custom':
            return <CustomSection key={index} section={section as CustomSectionType} />
          case 'Search':
            return <Search key={index} sectionResult={section as SearchSection} />
          default:
            return null
        }
      })}
    </>
  )
}

export default Sections
