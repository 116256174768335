import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { getApiHeader } from '../../../utils/utils'
import config from '../../../config'
import { StepTwoFormData } from '../../../components/SignUp/types'

export const signupApi = createApi({
  reducerPath: 'signupApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.apiUrl}`,
    prepareHeaders: (headers, { getState }) => getApiHeader(headers, { getState }, true)
  }),
  endpoints: (builder) => ({
    updateUserProfile: builder.mutation<StepTwoFormData, Partial<StepTwoFormData>>({
      query: (userProfileData) => ({
        url: `/user/profile`,
        method: 'POST',
        body: userProfileData
      })
    }),
    checkRegistration: builder.query<StepTwoFormData, Partial<StepTwoFormData>>({
      query: (phoneNumber) => ({
        url: `/users/check_registration?phone_number=${phoneNumber}`,
        method: 'GET',
        body: phoneNumber
      })
    })
  })
})

export const { useUpdateUserProfileMutation, useCheckRegistrationQuery } = signupApi
