import { styled, TableContainer } from '@mui/material'

export const PackageItemsStyle = styled(TableContainer)(({ theme }) => ({
  ' .MuiTable-root': {
    boxShadow: '0 0 0 .5px inset rgba(255, 255, 255,.3)',
    borderRadius: 10,
    background: 'transparent',
    marginBottom: 100,
    width: '75vw',
    margin: '0 auto',
    height: 'auto',
    ' .MuiTableHead-root': {
      ' .MuiTableCell-root': {
        fontFamily: 'Prompt',
        padding: 30,
        fontSize: 30,
        fontWeight: 700,
        color: '#FFF'
      }
    },
    ' .MuiTableRow-root': {
      ' .MuiTableCell-root': {
        borderBottom: '1px solid rgba(255, 255, 255,.3) !important',
        borderRight: '1px solid rgba(255, 255, 255,.3) !important'
      }
    },
    ' .MuiTableBody-root': {
      whiteSpace: 'nowrap',
      ' .MuiTableCell-root': {
        fontFamily: 'Prompt',
        fontSize: 18,
        fontWeight: 500,
        color: '#FFF'
      },
      ' .MuiSvgIcon-root': {
        fill: theme.palette.primary.main
      }
    }
  },
  [theme.breakpoints.between('sm', 'md')]: {
    marginTop: -100,
    transform: 'scale(.7)',
    display: 'flex',
    justifyContent: 'center',
    overflow: 'visible',
    ' .MuiTable-root': {
      ' .MuiTableHead-root': {
        ' .MuiTableCell-root': {
          fontSize: 20
        }
      }
    }
  },

  [theme.breakpoints.down('sm')]: {
    marginTop: -180,
    transform: 'scale(.5)',
    display: 'flex',
    justifyContent: 'center',
    overflow: 'visible'
  }
}))
