import { styled } from '@mui/system'
import { Button, Grid } from '@mui/material'

export const ButtonGroupElStyle = styled(Grid)(() => ({
  label: {
    display: 'block'
  }
}))
export const ButtonGroupStyle = styled(Button)(({ theme }) => ({
  background: theme.palette.colors.lightGray,
  fontFamily: '"Prompt", sans-serif !important',
  fontSize: 16,
  fontWeight:400,
  textTransform: 'capitalize',
  height: 50,
  border: 'none',
  borderRadius: '12px',
  justifyContent:'start',
  color: `${theme.palette.colors.blackOlive}`,
  boxShadow: `0 0 0 1px inset ${theme.palette.colors.silverGray}`,
  '&:hover': {
    background: theme.palette.colors.lightGray,
    border: 'none'
  },
  '&.active': {
    boxShadow: `0 0 0 1px inset ${theme.palette.primary.main}`
  },
  [theme.breakpoints.down('sm')]: {
    gap: '4%',
    justifyContent: 'revert',
    '& button': {
      height: '55px',
      gap: '4%'
    }
  }
}))
