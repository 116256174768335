import { useTheme } from '@mui/system'
import { useMediaQuery } from '@mui/material'

type breakpointsT = {
  isMobile: boolean
  isTablet: boolean
}

export const useBreakPoints = (): breakpointsT => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))
  return {
    isMobile,
    isTablet
  }
}
