import { createTheme } from '@mui/material'

declare module '@mui/material/styles/createPalette' {
  export interface PaletteOptions {
    colors?: {
      [key: string]: string | undefined
    }
    fontVariant?: {
      small: '0.875rem'
      xs: '1rem'
      smx: '1.125rem'
      sm: '1.5rem'
      md: '2rem'
      mdx: '2.25rem'
      lg: '2.5rem'
      xl: '3rem'
    }
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: '#f20b7e'
    },
    secondary: {
      main: '#101010'
    },
    colors: {
      primary: '#f20b7e',
      blackOlive: '#404040',
      silverGray: '#ccc',
      lightGray: '#f6f6f6',
      error: '#ff3a44',
      chineseBlack: '#101010'
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'Prompt',
          fontSize: '1.125rem',
          textTransform: 'none',
          height: 55,
          color: '#ffffff',
          borderRadius: 12
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: 'Prompt'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontFamily: 'Prompt',
          overflow: 'hidden',
          height: 50,
          borderRadius: 10,
          minWidth: '100% !important',
          width: '100%',
          '& .MuiInputBase-input-MuiOutlinedInput-input': {
            padding: 0,
            minWidth: '100%'
          }
        }
      }
    },
    MuiDrawer: {},
    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: 'Prompt',
          background: 'linear-gradient(0, #484848 0%, #000 100%)',
          color: '#909290 !important',
          '&.Mui-selected': {
            color: '#fff !important'
          }
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'Prompt'
        }
      }
    }
  },

  breakpoints: {
    values: {
      xs: 300, // phone
      sm: 600, // tablets
      md: 960, // small laptop
      lg: 1280, // desktop
      xl: 1920 // large screens,
    }
  }
})
