import { useNavigate } from 'react-router-dom'
import { FC, useEffect } from 'react'
import { User } from 'firebase/auth'

type AuthType = {
  currentUser: User | null
}

function useRequireAuth(auth: AuthType | null) {
  const navigate = useNavigate()

  useEffect(() => {
    if (auth?.currentUser) {
      navigate(-1)
    }
  }, [auth?.currentUser, navigate])

  return !auth?.currentUser
}

const RedirectBack: FC<{ auth: AuthType | null }> = ({ auth }) => {
  useRequireAuth(auth)

  return null
}

export default RedirectBack
