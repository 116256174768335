import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet-async'
import TagManager from 'react-gtm-module'
import React, { FC, useEffect, useLayoutEffect } from 'react'

import { getStorage } from '../../utils/localStorageSupport'
import {
  marketingFailure,
  marketingStart,
  marketingSuccess
} from '../../store/slices/marketingSlice'
import { setMaintenanceMessage, setMarketing } from '../../store/slices/config/configSlice'
import { marketingSelector, userSelector } from '../../store/slices/config/configSelectors'
import { useGetHomePageQuery } from '../../store/services/page/pageApi'
import { useLazyGetMarketingApiQuery } from '../../store/services/marketing/marketingApi'
import { RootState } from '../../store'
import { useLocalStorage } from '../../hooks/UIHooks'
import Sections from '../../components/Sections/Sections'
import PageHead from '../../components/PageHead/PageHead'
import Marketing from '../../components/Marketing/Marketing'
import Loading from '../../components/Loading/Loading'
import './HomePage.scss'
import ContinueWatchingSkeleton from '../../components/ContinueWatching/ContinueWatchingSkeleton'

const HomePage: FC = () => {
  const { data, isLoading, error } = useGetHomePageQuery({})
  const dispatch = useDispatch()
  const user = useSelector(userSelector)
  const { t } = useTranslation()
  const viewMarketing = useSelector(marketingSelector)
  const [marketingResult]: any = useLazyGetMarketingApiQuery()
  const { campaignLoading, items } = useSelector((state: RootState) => state.marketing)
  const [marketingIds] = useLocalStorage('dont_show_marketing')
  const storage = getStorage()
  const uid = storage.getItem('uid')

  const filterForDontShowItems = (arrItems) => {
    arrItems = arrItems.filter((el) => !marketingIds?.includes(el.id))
    return arrItems
  }

  const getMarketingCampaign = () => {
    dispatch(marketingStart())
    const getCampaigns = async () => {
      try {
        const campaigns = await marketingResult()
        const arrItems = campaigns.data.results.items
        dispatch(marketingSuccess(filterForDontShowItems(arrItems)))
      } catch (err) {
        dispatch(marketingFailure('' + err))
      }
    }
    getCampaigns()
  }

  useEffect(() => {
    document.title = t('app.title')
    getMarketingCampaign()
    const tagManagerArgs = {
      dataLayer: {
        event: 'page_view',
        page_title: 'homepage',
        page_location: location,
        member_id: uid,
        user_id: uid
      }
    }
    TagManager.dataLayer(tagManagerArgs)
  }, [])

  const getSiblingNode = () => {
    let firstNode, heroSiblingNode
    if (document.getElementsByClassName('main-content-home')[0]) {
      firstNode = document.getElementsByClassName('main-content-home')[0].firstChild as HTMLElement
    }
    if (
      document.getElementsByClassName('Hero-slider') != undefined &&
      document.getElementsByClassName('Hero-slider').length > 0
    ) {
      heroSiblingNode = document.getElementsByClassName('Hero-slider')[0]?.nextElementSibling
    }
    if (heroSiblingNode) {
      heroSiblingNode?.classList.add('margin_top')
    }
    if (!firstNode?.isSameNode(heroSiblingNode) && !firstNode?.classList.contains('Hero-slider')) {
      firstNode?.classList.add('margin_top_large')
      if (
        document.getElementsByClassName('Hero-slider') != undefined &&
        document.getElementsByClassName('Hero-slider').length > 0
      ) {
        document.getElementsByClassName('Hero-slider')[0].classList.add('margin_top')
      }
    }
    return true
  }

  useLayoutEffect(() => {
    getSiblingNode()
  })

  useEffect(() => {
    if (error && error?.['data']?.name === 'MaintenanceMode') {
      dispatch(setMaintenanceMessage(error?.['data']?.message))
    } else {
      dispatch(setMaintenanceMessage(null))
    }
  }, [error])

  if (isLoading || campaignLoading) {
    return <Loading />
  }

  return (
    <>
      <div className='home_container'>
        <div>
          <Helmet>
            <meta
              name='description'
              content='oneD ดูทีวีออนไลน์ ช่อง one31 และ GMM25 ดูละครย้อนหลัง ซีรี่ส์ รายการวาไรตี้ ซิตคอม'
            />
            <meta
              name='keywords'
              content='ดูละครออนไลน์, ดูทีวีออนไลน์, ละครไทย, ช่อง one31, ช่อง GMM25, ซีรี่ส์เกาหลี, ซิตคอม, oneD Original, บางกอกคณิกา, Love Lesson, oneD, รายการวาไรตี้, ละครเวที, ดูทีวีสด, ละครย้อนหลัง'
            />
          </Helmet>

          <PageHead data={data?.head} />
          <h1 className='hidden'>
            oneD ดูทีวีออนไลน์ ช่อง one31 และ GMM25 ดูละครย้อนหลัง ซีรี่ส์ รายการวาไรตี้ ซิตคอม
          </h1>
          <div className='main-content-home'>
            <Sections sections={data?.sections} />
            {user && (
              <div className='Continue-Watching-Grid-home'>
                <h2 className='Continue-Watching-Grid-header-home'>
                  {t('continue-watching.continue-watching')}
                </h2>
                <ContinueWatchingSkeleton
                  requireAsList={true}
                  onListUpdate={(listSize: number) => {
                    const element = document.getElementsByClassName(
                      'Continue-Watching-Grid-home'
                    )[0]
                    if (element) {
                      if (listSize === 0) {
                        element.classList.add('hide_cw')
                      } else {
                        element.classList.remove('hide_cw')
                      }
                    }
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {viewMarketing && items && items.length > 0 && (
        <div className='marketing_holder'>
          <Marketing
            onClickClose={() => {
              dispatch(setMarketing(false))
              const element = document.getElementsByClassName('marketing_holder')[0]
              if (element) {
                element.classList.add('hide_marketing')
              }
            }}
            campaigns={items}
          />
        </div>
      )}
    </>
  )
}

export default HomePage
