import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
// import Cookies from 'js-cookie';
import {
  ConfirmationResult,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPhoneNumber,
  UserCredential
} from 'firebase/auth'

import { User } from '../store/slices/config/types'
import { clearToken, setUser } from '../store/slices/config/configSlice'
import {
  appleAuthProvider,
  auth,
  facebookAuthProvider,
  googleAuthProvider,
  signInWithPopup
} from '../firebase'

type AuthFunctions = {
  signup: (email: string, password: string) => Promise<UserCredential>
  login: (email: string, password: string) => Promise<UserCredential>
  loginWithPhone: (phoneNumber: string, applicationVerifier: any) => Promise<ConfirmationResult>
  facebookLogin: () => Promise<UserCredential>
  googleLogin: () => Promise<UserCredential>
  appleLogin: () => Promise<UserCredential>
  logout: () => Promise<void>
  resetPassword: (email: string) => Promise<void>
}

const useAuth = (runEffect = false): AuthFunctions => {
  const dispatch = useDispatch()

  const signup = (email: string, password: string) =>
    createUserWithEmailAndPassword(auth, email, password)
  const login = async (email: string, password: string) =>
    signInWithEmailAndPassword(auth, email, password)

  const loginWithPhone = (phoneNumber: string, applicationVerifier: any) =>
    signInWithPhoneNumber(auth, phoneNumber, applicationVerifier)
  const resetPassword = (email: string) => sendPasswordResetEmail(auth, email)
  const facebookLogin = () => signInWithPopup(auth, facebookAuthProvider)
  const googleLogin = () => signInWithPopup(auth, googleAuthProvider)
  const appleLogin = () => signInWithPopup(auth, appleAuthProvider)
  const logout = () => auth.signOut()

  useEffect(() => {
    if (runEffect) {
      const unsubscribe = auth.onAuthStateChanged((user) => {
        if (user) {
          dispatch(setUser(user?.toJSON() as User))
          // user.getIdToken().then((token) => {
          // const token = localStorage.getItem('token')
          // dispatch(setToken(token))

          // })
        } else {
          dispatch(setUser(null))
          dispatch(clearToken())
        }
      })
      return () => unsubscribe()
    }
  }, [dispatch, runEffect])

  return {
    login,
    facebookLogin,
    googleLogin,
    appleLogin,
    logout,
    resetPassword,
    signup,
    loginWithPhone
  }
}

export default useAuth
