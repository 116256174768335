import { z } from 'zod'
import { useNavigate } from 'react-router'
import { useDispatch } from 'react-redux'
import OTPInput from 'react-otp-input'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import React, { FC, useEffect, useState } from 'react'
import { Typography } from '@mui/material'

import ActivationButton from '../../Components/ActivationButton/ActivationButton'
import Loading from '../../../Loading/Loading'
import { getStorage } from '../../../../utils/localStorageSupport'
import { setLocation } from '../../../../store/slices/menuSlice'
import { setActivationSuccess } from '../../../../store/slices/activationSlice'
import { useLazyGetUserProfileQuery } from '../../../../store/services/user/userProfileApi'
import { useValidateQACodeMutation } from '../../../../store/services/activation/activationApi'

import { ActivationStyles } from './Activation.style'

const OTPSchema = z.object({
  otp: z
    .string({ required_error: 'Invalid Code' })
    .min(8, { message: 'Invalid Code' })
    .regex(/^[a-zA-Z0-9\s]*$/, 'Only letters and numbers allowed')
})

const { REACT_APP_AIS_REDIRECT_URL } = process.env

const Activation: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [isValidation, setIsValidation] = useState<boolean>(false)
  const [validation, setValidation] = useState<string>('')
  const [validateQACode] = useValidateQACodeMutation()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const storage = getStorage()
  const [getProfileData] = useLazyGetUserProfileQuery()

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors }
  } = useForm({
    mode: 'onSubmit',
    shouldUnregister: false,
    progressive: true
  })

  // activation success handler function
  const onActivationSuccess = async () => {
    getProfileData().then((profileResponse) => {
      const spAccountId = profileResponse?.data?.id ?? ''
      if (profileResponse?.data?.subscription?.subscription_status?.toLowerCase() === 'active') {
        dispatch(setActivationSuccess(true))
        navigate('/activation/confirm')
      } else {
        window.location.href = `${REACT_APP_AIS_REDIRECT_URL}?spAccountId=${spAccountId}&packageId=`
      }
    })
  }

  const onOTPHandle = async (data) => {
    try {
      setIsLoading(true)
      const validation = await OTPSchema.safeParseAsync(data)
      if (!validation?.success) {
        setIsLoading(false)
        setIsValidation(true)
        const errorMessage = t('activation.invalid_code') as string

        setError('otp', {
          type: 'manual',
          message: errorMessage
        })
        return
      }

      const QAData = {
        activation_code: data?.otp?.toUpperCase(),
        expiry: 0
      }

      const QACode: any = await validateQACode(QAData)

      setIsLoading(false)
      if (QACode?.data?.status === 'FAILED' || QACode?.data?.status === 'EXPIRED') {
        dispatch(setActivationSuccess(false))
        navigate('/activation/confirm')
      }
      if (QACode?.data?.status === 'SUCCESS') {
        onActivationSuccess()
      }
    } catch (error) {
      dispatch(setActivationSuccess(false))
      navigate('/activation/confirm')
    }
  }

  useEffect(() => {
    setValidation(errors?.otp?.message as string)
  }, [errors?.otp?.message])

  useEffect(() => {
    if (!storage?.getItem('token')) {
      dispatch(setLocation('/activation'))
      navigate('/login')
    }
  }, [dispatch])

  return (
    <ActivationStyles className='activation'>
      <Typography variant='h6' className='activation-label'>
        {t('activation.activation_label')}
      </Typography>
      <form onSubmit={handleSubmit(onOTPHandle)}>
        <div className='ott-wrapper'>
          <Controller
            name='otp'
            control={control}
            render={({ field: { onChange, value } }) => (
              <OTPInput
                value={value}
                onChange={(value) => {
                  onChange(value)
                  setIsValidation(false)
                }}
                numInputs={8}
                renderSeparator={<span className='separator'>-</span>}
                renderInput={(props) => (
                  <input {...props} className={`opt-input${isValidation ? ' error' : ''}`} />
                )}
                shouldAutoFocus
              />
            )}
          />
        </div>
        {isValidation && <div className='error-otp'>{validation}</div>}
        <div>
          <ActivationButton label={t('activation.confirm')} type='submit' />
          {isLoading && <Loading />}
        </div>
      </form>
    </ActivationStyles>
  )
}

export default Activation
