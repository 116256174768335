import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { Packages } from '../../../types/package'
import config from '../../../config'

export const aisApi = createApi({
  reducerPath: 'aisApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.aisApiUrl}`
  }),
  endpoints: (builder) => ({
    getPackages: builder.query<Packages, void>({
      query: () => ({
        url: `/frontend/v1/api/package`,
        method: 'GET'
      })
    })
  })
})

export const { useLazyGetPackagesQuery } = aisApi
