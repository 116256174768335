import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { getApiHeader } from '../../../utils/utils'
import { UserCheck } from '../../../types/userCheck'
import config from '../../../config'

export const userCheckApi = createApi({
  reducerPath: 'userCheckApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.apiUrl}`,
    prepareHeaders: (headers, { getState }) => getApiHeader(headers, { getState })
  }),
  endpoints: (builder) => ({
    userCheckPhone: builder.query<UserCheck, { phone_number: string }>({
      query: (args) => {
        const phone_number: any = args
        ;('GET')
        return {
          url: `/user/check_registration`,
          params: { phone_number }
        }
      }
    })
  })
})

export const { useLazyUserCheckPhoneQuery } = userCheckApi
