import React, { FC } from 'react'

import { ActivationButtonStyles } from './ActivationButton.style'

interface ActivationButtonI {
  label: string
  onActivationClick?: (() => void) | undefined
  type?: 'button' | 'submit' | 'reset' | undefined
}
const ActivationButton: FC<ActivationButtonI> = ({ label, onActivationClick, type = 'button' }) => (
  <ActivationButtonStyles
    className='activation-button'
    onClick={() => type === 'button' && onActivationClick?.()}
    type={type}
  >
    {label}
  </ActivationButtonStyles>
)

export default ActivationButton
