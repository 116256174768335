import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import TagManager from 'react-gtm-module'
import { FC, useEffect } from 'react'

import { getStorage } from '../../../utils/localStorageSupport'
import { userSelector } from '../../../store/slices/config/configSelectors'
import { RootState } from '../../../store'

interface SignUpGTMEventsParams {
  event: string
  page_title?: string
  page_location?: string
  page_referrer?: string
  source?: string
  medium?: string
  campaign?: string
  user_id?: string
  event_category?: string
  event_action?: string
  event_label?: string
  error_message?: string | undefined
}

interface GTMEventTriggerProps {
  onSignUp?: any
  isGTMError?: boolean
  errorMessage?: object | any
  isSignUpConfirmStep1?: boolean
  isSignUpConfirmStep1OPT?: boolean
  isSignUpConfirmStep1OTPFail?: boolean
  isSignUpConfirmStep2?: boolean
  isSignUpSuccessful?: boolean
}

const SignUpGTMEvents: FC<GTMEventTriggerProps> = ({
  isGTMError = false,
  errorMessage,
  isSignUpConfirmStep1,
  isSignUpConfirmStep1OPT,
  isSignUpConfirmStep1OTPFail,
  isSignUpConfirmStep2,
  isSignUpSuccessful
}) => {
  const { t } = useTranslation()
  const step = useSelector((state: RootState) => state.signup.step)
  const user = useSelector(userSelector)
  const storage = getStorage()
  const uid = storage.getItem('uid')

  const pageView = (params: SignUpGTMEventsParams) => {
    const tagManagerArgs = {
      dataLayer: {
        ...params
      }
    }
    return TagManager.dataLayer(tagManagerArgs)
  }

  const signUpConfirmStep1 = (params: SignUpGTMEventsParams) => {
    const tagManagerArgs = {
      dataLayer: {
        ...params
      }
    }
    return TagManager.dataLayer(tagManagerArgs)
  }

  const signUpConfirmStep1Fail = (params: SignUpGTMEventsParams) => {
    const tagManagerArgs = {
      dataLayer: {
        ...params
      }
    }
    return TagManager.dataLayer(tagManagerArgs)
  }

  const signUpConfirmStep1OPT = (params: SignUpGTMEventsParams) => {
    const tagManagerArgs = {
      dataLayer: {
        ...params
      }
    }
    return TagManager.dataLayer(tagManagerArgs)
  }

  const signUpConfirmStep1OTPFail = (params: SignUpGTMEventsParams) => {
    const tagManagerArgs = {
      dataLayer: {
        ...params
      }
    }
    return TagManager.dataLayer(tagManagerArgs)
  }

  const signUpConfirmStep2 = (params: SignUpGTMEventsParams) => {
    const tagManagerArgs = {
      dataLayer: {
        ...params
      }
    }
    return TagManager.dataLayer(tagManagerArgs)
  }

  const signUpSuccessful = (params: SignUpGTMEventsParams) => {
    const tagManagerArgs = {
      dataLayer: {
        ...params
      }
    }
    return TagManager.dataLayer(tagManagerArgs)
  }

  const signUp = () => {
    const currentLocation = window.location.href

    pageView({
      event: 'page_view',
      page_title: t('signup.signup') || '',
      page_location: currentLocation,
      page_referrer: '',
      source: '',
      medium: '',
      campaign: '',
      user_id: step === 3 ? user?.uid : ''
    })
  }

  useEffect(() => {
    if (step === 1 || step === 3) {
      signUp()
    }
  }, [step])

  useEffect(() => {
    if (isGTMError === true) {
      const { emailOrPhone, passwordOrOtp } = errorMessage
      const error_message = `${emailOrPhone?.message ? emailOrPhone?.message : ''} ${
        passwordOrOtp?.message ? passwordOrOtp?.message : ''
      }`

      if (errorMessage?.emailOrPhone?.message || errorMessage?.passwordOrOtp?.message) {
        signUpConfirmStep1Fail({
          event: 'signup_confirm_step1_fail',
          event_category: 'signup',
          event_action: 'click_button',
          event_label: 'confirm_step1_fail',
          error_message: error_message?.trim()
        })
      }
    }
  }, [isGTMError, errorMessage, errorMessage?.emailOrPhone])

  useEffect(() => {
    if (isSignUpConfirmStep1)
      signUpConfirmStep1({
        event: 'signup_confirm_step1',
        event_category: 'signup',
        event_action: 'click_button',
        event_label: 'confirm_step1'
      })
  }, [isSignUpConfirmStep1])

  useEffect(() => {
    if (isSignUpConfirmStep1OPT)
      signUpConfirmStep1OPT({
        event: 'signup_confirm_step1_otp',
        event_category: 'signup',
        event_action: 'click_button',
        event_label: 'confirm_step1_otp'
      })
  }, [isSignUpConfirmStep1OPT])

  useEffect(() => {
    if (isSignUpConfirmStep1OTPFail) {
      signUpConfirmStep1OTPFail({
        event: 'signup_confirm_step1_otp_fail',
        event_category: 'signup',
        event_action: 'click_button',
        event_label: 'confirm_step1_otp_fail'
      })
    }
  }, [isSignUpConfirmStep1OTPFail])

  useEffect(() => {
    if (isSignUpConfirmStep2) {
      signUpConfirmStep2({
        event: 'signup_confirm_step2',
        event_category: 'signup',
        event_action: 'click_button',
        event_label: 'confirm_step2'
      })
    }
  }, [isSignUpConfirmStep2])

  useEffect(() => {
    if (isSignUpSuccessful) {
      signUpSuccessful({
        event: 'signup_successful',
        event_category: 'signup',
        event_action: 'signup_successful',
        event_label: 'signup_successful',
        user_id: uid
      })
    }
  }, [isSignUpSuccessful])

  return null
}
export default SignUpGTMEvents
