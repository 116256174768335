import { styled } from '@mui/system'
import { Dialog } from '@mui/material'

export const DialogStyle = styled(Dialog)(({ theme }) => ({
  ' .MuiPaper-root': {
    width: 400,
    textAlign: 'center',
    padding: 10,
    height: 220,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#171616',
    color: '#fff'
  },
  ' .MuiTypography-root': {
    fontWeight: 'bold'
  },
  ' .MuiButtonBase-root': {
    width: 150,
    height: 50,
    marginTop: 20,
    display: 'flex',
    fontSize: 16,
    backgroundColor: theme.palette.colors.chineseBlack
  },
  '.subscribe-dialog': {
    display: 'flex',
    flexDirection: 'column'
  },
  '.subscribe-title': {
    marginBottom: 10,
    fontSize: 22
  },
  '.button-container': {
    display: 'flex',
    justifyContent: 'space-around',
    border: 'none',
    width: 360
  },
  '.dialog-message': {
    marginLeft: 40,
    marginRight: 40,
    fontWeight: 600
  },
  ' button': {
    fontWeight: 600
  },
  '.subscribe-cancel-button': {
    background: theme.palette.colors.blackOlive
  },
  '.subscribe-upgrade-button': {
    background: theme.palette.colors.primary
  },
  [theme.breakpoints.down('sm')]: {
    ' .subscribe-title': {
      fontSize: 18,
      fontWeight: 700
    },
    '.dialog-message': {
      fontSize: 14,
      fontWeight: 600
    },
    ' .MuiButtonBase-root': {
      width: 200,
      height: 40,
      fontSize: 12,
      fontWeight: 600
    },
    ' .button-container': {
      width: 260
    }
  }
}))
