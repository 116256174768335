import { styled } from '@mui/system'
import { Button } from '@mui/material'

export const ActivationButtonStyles = styled(Button)(({ theme }) => ({
  background: theme.palette.primary.main,
  width: 350,
  marginBlock: 15,
  fontWeight: '600',
  ':hover': {
    background: theme.palette.primary.main
  },
  [theme.breakpoints.down('sm')]: {
    width: 300
  }
}))
