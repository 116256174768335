import { useDispatch } from 'react-redux'
import OTPInput from 'react-otp-input'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import React, { FC, useState } from 'react'
import { styled } from '@mui/system'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { Flex, Form } from '@brightcove/studio-components'

import { hideLogIn } from '../../../store/slices/config/configSlice'

import OTPTimer from './OTPTimer'
import './OTPDialog.scss'

interface OTPDialogProps {
  handleOTPSubmit: (data: any) => Promise<void>
  emailOrPhoneState?: number | string
  verificationId: any
  isInvalidOtp: boolean | undefined
  // isShowOTPTimer: boolean
  isDialogOpen: boolean
  onDialogClose: () => void
  phoneNumber: string
  isTimerFinished: (finished: boolean) => void
  isTimerExpired: boolean
}
interface FormValues {
  otp: string
}
export const ButtonStyle = styled(Button)(() => ({
  position: 'absolute',
  top: 10,
  right: 10,
  background: 'rgba(0,0,0,0)',
  minWidth: 40,
  height: 40,
  color: '#fff',
  span: {
    textAlign: 'center',
    margin: 0
  }
}))
const OTPDialog: FC<OTPDialogProps> = ({
  handleOTPSubmit,
  emailOrPhoneState,
  verificationId,
  isInvalidOtp,
  // isShowOTPTimer,
  isDialogOpen,
  onDialogClose,
  phoneNumber,
  isTimerFinished,
  isTimerExpired
}) => {
  const { t } = useTranslation()
  const [, setError] = useState<null | string>(null)
  const dispatch = useDispatch()
  const {
    handleSubmit,
    control
    // formState: { errors },
  } = useForm<FormValues>()

  const INPUT_VALUE = 6

  const handleTimeout = () => {
    setError(t('signup.expired_OTP'))
    isTimerFinished(true)
    // setIsButtonDisabled(true)
    // isInvalidOtp = true
  }

  return (
    <Flex className='Login-OTPDialog-login' alignItems='center' justifyContent='center'>
      <Dialog
        open={isDialogOpen}
        onClose={(_, reason) => {
          dispatch(hideLogIn())
          if (reason && reason == 'backdropClick') onDialogClose()
        }}
        PaperProps={{
          className: 'Login-OTPDialog-wrapper'
        }}
      >
        <DialogActions>
          <ButtonStyle startIcon={<CloseOutlinedIcon />} onClick={onDialogClose} />
        </DialogActions>
        <DialogTitle>
          <div
            style={{
              textAlign: 'center'
            }}
          >
            <span className='enter-otp-login'>{t('login.enter_otp') || ''}</span>
          </div>
          <div
            style={{
              textAlign: 'center'
            }}
          >
            <span className='otp-sent-login'>
              {t('login.otp_send_number') || ''}
              <p>{emailOrPhoneState}</p>
            </span>
          </div>
          <div className='otp-phone-number'>{phoneNumber}</div>
        </DialogTitle>

        <Form className='login-form' onSubmit={handleSubmit(handleOTPSubmit)}>
          <DialogContent>
            <span className='otp-code-login'>{t('signup.OTP_code') || ''}</span>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Controller
                name='otp'
                control={control}
                render={({ field: { onChange, value } }) => (
                  <>
                    <OTPInput
                      shouldAutoFocus={true}
                      value={value}
                      numInputs={INPUT_VALUE}
                      onChange={(value) => {
                        // setIsButtonDisabled(value?.length !== INPUT_VALUE)
                        onChange(value)
                      }}
                      renderInput={(props) => <input {...props} />}
                      inputType='number'
                      inputStyle={{
                        width: '48px',
                        height: '53px',
                        marginBottom: '1rem',
                        borderBlockColor: 'gray',
                        background: 'rgba(217, 217, 217, 0.10)',
                        borderRadius: '12px',
                        border: `${
                          isInvalidOtp || isTimerExpired ? '1px solid #FF3A44' : '1px solid #878787'
                        }`,
                        color: 'white',
                        font: '12px',
                        paddingLeft: '17px',
                        fontSize: '22px',
                        textAlign: 'left'
                      }}
                      renderSeparator={
                        <span
                          style={{
                            fontSize: '7px',
                            marginLeft: '5px',
                            marginRight: '5px'
                          }}
                        >
                          {' '}
                        </span>
                      }
                    />
                  </>
                )}
              />
            </div>
            <div
              className={`${
                isTimerExpired
                  ? 'Login-OTPDialog-timer-message-expired'
                  : 'Login-OTPDialog-timer-message'
              }`}
            >
              {verificationId && !isInvalidOtp && <OTPTimer onTimeout={handleTimeout} />}
            </div>

            {isInvalidOtp && (
              <div className='Login-OTPDialog-timer-message' style={{ color: '#FF3A44' }}>
                {t('signup.invalid_OTP')}
              </div>
            )}
            <div className='Login-OTPDialog-confirm-button'>
              <Button className='OTPDialog-confirm-button' type='submit'>
                {t('login.confirm') || ''}
              </Button>
            </div>
          </DialogContent>
        </Form>
      </Dialog>
    </Flex>
  )
}

export default OTPDialog
