import { useNavigate } from 'react-router'
import React, { FC, useEffect, useState } from 'react'
import classNames from 'classnames'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'

import './Thumbnail.scss'
import { getDataFromUrl, objectToQueryString } from '../../utils/utils'
import { VideoDetailSection } from '../../types/section'
import { useLazyGetTrailerDetailsQuery } from '../../store/services/content/contentApi'

interface VideoDetailProps {
  section: VideoDetailSection
  trailerId?: string | null
}

const Thumbnail: FC<VideoDetailProps> = ({ section: { content }, trailerId }) => {
  //const imgSrc = content?.images?.['poster']?.src
  const navigate = useNavigate()
  const [postImage, setPosterImage] = useState('')
  const [trailerSection]: any = useLazyGetTrailerDetailsQuery()
  const getQuery = new URLSearchParams(window.location?.search)
  const objParam = getDataFromUrl(getQuery.get('data')!)
  const displayName = objParam['displayName']
  const seriesName = objParam['seriesName']
  const seriesDescription = objParam['seriesDescription']

  const showContentDetailsRoute = (id, display_name) => {
    const videoDetails = {
      seriesName: seriesName,
      next_up_id: '',
      seriesDescription: seriesDescription,
      displayName: displayName
    }
    if (content?.series_id) {
      if (videoDetails.seriesName === '') {
        videoDetails.seriesName = display_name
      }
      navigate(
        `/video/${content?.video_id}?trailer=${trailerId ? trailerId : id}&data=${btoa(
          objectToQueryString(videoDetails)
        )}`
      )
    } else {
      navigate(
        `/video/${trailerId ? trailerId : id}?data=${btoa(objectToQueryString(videoDetails))}`
      )
    }
  }

  useEffect(() => {
    const getContent = async () => {
      try {
        const sectResp = await trailerSection({
          id: trailerId ? trailerId : content?.custom_fields?.['trailer_id']
        })
        setPosterImage(sectResp?.data?.images?.['thumbnail']?.src)
      } catch (err) {
        // dispatch(contentFailure('' + err))
      }
    }
    getContent()
  }, [])

  return (
    <div
      key={content?.video_id}
      className={classNames('Thumbnail')}
      onClick={() => {
        showContentDetailsRoute(
          `${content?.custom_fields?.['trailer_id']}`,
          `${content?.['display_name']}`
        )
      }}
    >
      <div className='Thumbnail-image' style={{ backgroundImage: `url(${postImage})` }}>
        <div className='Thumbnail-image-overlay-gradient'>
          <PlayArrowIcon
            fontSize='medium'
            style={{
              fill: 'white',
              width: '38px',
              border: '2px solid #FFFFFFBF',
              borderRadius: '50px',
              height: '38px'
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default Thumbnail
