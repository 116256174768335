import { styled } from '@mui/system'
import { Grid } from '@mui/material'

interface macStyle {
  isIOs: boolean
}

export const SmartBannerStyle = styled(Grid)<macStyle>(({ isIOs }) => ({
  padding: '0 10px',
  background: isIOs ? '#f3f3f3' : '#414141',
  boxShadow: !isIOs ? 'inset 0 4px 0 #88b131' : '',
  position: 'fixed',
  top: 0,
  zIndex: 1,
  width: '100%',
  height: 84,
  flexWrap: 'nowrap',
  alignItems: 'center',
  '& .sm-close-button': {
    '& svg': {
      display: 'block',
      background: !isIOs ? '#000' : '',
      color: isIOs ? '#000' : '#767676',
      borderRadius: '100px',
      transform: isIOs ? 'scale(1.3)' : 'scale(.6)',
      boxShadow: !isIOs ? '0px 2px 1px' : '',
      padding: 5,
      marginRight: 5
    }
  },
  '& .smart-banner-label': {
    color: isIOs ? '#000' : '#fff',
    '&.title': {
      fontSize: 14
    },
    '&.author, &.price': {
      fontSize: 12
    }
  },
  '& .sm-content-text': {
    marginLeft: 10
  },
  button: {
    borderRadius: 0,
    lineHeight: '24px',
    padding: '0 10px',
    '&.sm-mac-button': {
      fontSize: 18,
      '& a': {
        color: '#1474FC'
      }
    },
    '&.sm-android-button': {
      fontSize: 14,
      background: 'linear-gradient(180deg,#42b6c9,#39a9bb)',
      width: 85,
      height: 24,
      boxShadow: '0 0 0 1px #333, 0 0 0 2px #dddcdc',
      '& a': {
        color: '#D1D1D1'
      }
    }
  },
  img: {
    width: 64,
    minHeight: 64,
    alignSelf: 'flex-end'
  }
}))
