import { useNavigate } from 'react-router'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet-async'
import React, { createContext, FC, ReactNode, useEffect, useRef, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import { Flex } from '@brightcove/studio-components'

import Thumbnail from '../../Thumbnail/Thumbnail'
import { getDataFromUrl } from '../../../utils/utils'
import eventBus from '../../../utils/EventBus'
import { VideoDetailSection } from '../../../types/section'
import './About.scss'
import { clearSearch, searchStart } from '../../../store/slices/searchSlice'
import { relatedContents } from '../../../store/slices/playerSlice'
import { useGetVideoDetailPageQuery } from '../../../store/services/page/pageApi'

export const VideoDetailContext = createContext('')
interface VideoDetailProps {
  section: VideoDetailSection
}

const InfoGroup: FC<{ label: string; children: ReactNode }> = ({ label, children }) => (
  <div className='About-info-group'>
    <Typography className='About-info-label'>{label}</Typography>
    {children}
  </div>
)

const About: FC<VideoDetailProps> = ({ section, section: { content } }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const castContent: JSX.Element[] = []
  const studioContent: JSX.Element[] = []
  const directorContent: JSX.Element[] = []
  const genreContent: JSX.Element[] = []
  const navigate = useNavigate()
  const getQuery = new URLSearchParams(window.location?.search)
  const trailerId = getQuery.get('trailer')
  const { data } = useGetVideoDetailPageQuery({ id: trailerId as string })
  const objParam = getDataFromUrl(getQuery.get('data')!)
  const seriesDescription = objParam['seriesDescription']
  const META_DESCRIPTION_LENGTH = 160
  const keywords = useRef([''])

  const [updatedContent, setUpdatedContent] = useState(content)
  keywords.current = ['']

  useEffect(() => {
    keywords.current = ['']
    setUpdatedContent(content)
  }, [updatedContent, data, trailerId, content])

  const loadSearch = (query) => {
    eventBus.dispatch('searchQuery', { message: query })
    dispatch(clearSearch())
    dispatch(searchStart(query))
    navigate(`/search`)
  }

  {
    updatedContent?.custom_fields['cast']?.split(',').map((item, index) => {
      keywords.current.push(item)
      item?.trim() &&
        castContent.push(
          <div
            key={index}
            className='About-pill'
            onClick={() => {
              loadSearch(item?.trim())
            }}
          >
            <p className='About-text ellipsis'>{item?.trim()}</p>
          </div>
        )
    })
  }

  {
    updatedContent?.custom_fields['studio']?.split(', ').map((item, index) => {
      keywords.current.push(item)
      studioContent.push(
        <p
          key={index}
          onClick={() => {
            loadSearch(item)
          }}
          className='About-text'
        >
          {item}
        </p>
      )
    })
  }

  {
    updatedContent?.custom_fields['director']?.split(', ').map((item, index) => {
      keywords.current.push(item)
      directorContent.push(
        <p
          key={index}
          onClick={() => {
            loadSearch(item)
          }}
          className='About-text'
        >
          {item}
        </p>
      )
    })
  }

  {
    updatedContent?.custom_fields['genre']?.split(', ').map((item, index) => {
      keywords.current.push(item)
      genreContent.push(
        <p
          key={index}
          onClick={() => {
            loadSearch(item)
          }}
          className='About-text'
        >
          {item}
        </p>
      )
    })
  }

  useEffect(() => {
    dispatch(relatedContents(updatedContent?.related_content ? updatedContent.related_content : []))
  }, [updatedContent, content])

  return (
    <div className='About' key={updatedContent?.video_id + 'about'}>
      <Helmet>
        <meta
          name='description'
          content={`${
            trailerId
              ? seriesDescription.substring(0, META_DESCRIPTION_LENGTH) || ''
              : updatedContent?.long_description?.substring(0, META_DESCRIPTION_LENGTH) || ''
          }`}
        ></meta>
        <meta name='keywords' content={keywords.current.toString()}></meta>
      </Helmet>

      <div className='About-tab'>{t('content-detail.about')}</div>
      <div className='About-container'>
        <p className='About-description'>
          {trailerId ? seriesDescription : updatedContent?.long_description}
        </p>
        <div className='About-info-container'>
          <Grid container spacing={5}>
            <Grid item md={6} xs={12}>
              {(!trailerId || trailerId === '') &&
                updatedContent?.custom_fields['cast']?.split(',').length && (
                  <Flex className='About-info-group' flexDirection='column'>
                    <Typography className='About-info-label'>{t('content-detail.cast')}</Typography>
                    <div className='About-info-row mt-5 mb-5'>
                      <Flex flexWrap='wrap' className='custom_item_flex'>
                        {castContent}
                      </Flex>
                    </div>
                  </Flex>
                )}
            </Grid>
            <Grid item xs={4} md={4}>
              {(!trailerId || trailerId === '') &&
                updatedContent?.custom_fields['director']?.split(',').length && (
                  <Flex className='About-info-group' flexDirection='column'>
                    <Typography className='About-info-label'>
                      {t('content-detail.director')}
                    </Typography>
                    <div className='About-info-row  mt-5'>
                      <Flex flexWrap='wrap' className='custom_item_flex'>
                        {directorContent}
                      </Flex>
                    </div>
                  </Flex>
                )}
            </Grid>
            <Grid item container md={6}>
              <Grid item container>
                <Grid item xs={4} md={4}>
                  {(!trailerId || trailerId === '') &&
                    updatedContent?.custom_fields['studio']?.split(',').length && (
                      <Flex className='About-info-group' flexDirection='column'>
                        <Typography className='About-info-label'>
                          {t('content-detail.studio')}
                        </Typography>
                        <div className='About-info-row  mt-5'>
                          <Flex flexWrap='wrap' className='custom_item_flex'>
                            {studioContent}
                          </Flex>
                        </div>
                      </Flex>
                    )}
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                {(!trailerId || trailerId === '') &&
                  updatedContent?.custom_fields['genre']?.split(',').length && (
                    <Flex className='About-info-group' flexDirection='column'>
                      <Typography className='About-info-label'>
                        {t('content-detail.genre')}
                      </Typography>
                      <div className='About-info-row  mt-5'>
                        <Flex flexWrap='wrap' className='custom_item_flex'>
                          {genreContent}
                        </Flex>
                      </div>
                    </Flex>
                  )}
              </Grid>
            </Grid>
            {/* Trailer UI */}
            <Grid item md={6} flex={0.5}>
              {(updatedContent?.custom_fields['trailer_id'] || trailerId) && (
                <InfoGroup label={t('content-detail.trailer')}>
                  <Thumbnail section={section} trailerId={trailerId} />
                </InfoGroup>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  )
}

export default About
