import { NavLink, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import TagManager from 'react-gtm-module'
import React, { FC } from 'react'
import { Button, List, ListItem, ListItemButton } from '@mui/material'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { Flex, FlexItem } from '@brightcove/studio-components'

import useSubscriptionStatus from '../Common/SubscriptionStatus/SubscriptionStatus'
import { RootState } from '../../store'
import { useBreakPoints } from '../../hooks/useBreakPoints'
import { activeClassName } from '../../hooks/UIHooks'

import SignOutButton from './SignOutButton'
import { ProfileMenuItems } from './Menu/ProfileMenu'
import ProfileContent from './Components/ProfileContent'
import './Sidebar.scss'

type SidebarProps = {
  onSlideBarClick?: () => void
  isSliderBarVisible?: boolean
}

const Sidebar: FC<SidebarProps> = ({ onSlideBarClick, isSliderBarVisible = false }) => {
  const menuItems = ProfileMenuItems()
  const { isMobile } = useBreakPoints()
  const navigate = useNavigate()
  const { notificationLength } = useSelector((state: RootState) => state.notification)
  const isSubscriptionUser = useSubscriptionStatus()

  const menuClick = (name: any) => {
    const tagManagerArgs = {
      dataLayer: {
        event: `${name}_click`,
        event_category: 'myaccount',
        event_action: `select_${name}`,
        event_label: name
      }
    }
    TagManager.dataLayer(tagManagerArgs)
  }

  if (isSliderBarVisible) return null
  return (
    <div className='sidebar'>
      {isMobile && (
        <Button
          onClick={() => navigate(-1)}
          className='profile-close-button'
          startIcon={<CloseOutlinedIcon />}
        />
      )}
      <List className='profile_menu_content'>
        {menuItems.map((menu) => {
          if (menu.name === 'subscription') {
            if (!isSubscriptionUser) return null
          }
          return (
            <ListItem
              key={menu.id}
              className='profile_menu'
              // sx={{ display: !isMobile || !menu.isMobileHide ? 'flex' : 'none' }}
            >
              <ListItemButton key={menu.id} LinkComponent={'a'} className='profile_menu_items'>
                <NavLink
                  to={menu?.link as string}
                  onClick={() => {
                    onSlideBarClick && onSlideBarClick()
                    menuClick(menu?.name)
                  }}
                  className={activeClassName('active-link', menu?.link as string)}
                >
                  <Flex
                    alignItems='center'
                    justifyContent='space-between'
                    style={{ whiteSpace: 'pre' }}
                  >
                    <React.Fragment>
                      <FlexItem flex='1'>{menu.value}</FlexItem>
                      {menu.id === 2 && notificationLength > 0 && (
                        <div className='notf_counter_side'>{notificationLength}</div>
                      )}
                      <FlexItem>{menu.icon}</FlexItem>
                    </React.Fragment>
                  </Flex>
                </NavLink>
              </ListItemButton>
            </ListItem>
          )
        })}
      </List>
      {!isMobile && <SignOutButton />}

      {isMobile && (
        <>
          <ProfileContent />
          <SignOutButton />
        </>
      )}
    </div>
  )
}

export default Sidebar
