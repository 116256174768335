import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import TagManager from 'react-gtm-module'
import * as React from 'react'
import { FC, useEffect, useState } from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { Grid } from '@mui/material'

import { setLocation } from '../../../store/slices/menuSlice'
import { userSelector } from '../../../store/slices/config/configSelectors'
import { SVGImage } from '../../../assets/images'

import { FooterMobileMenu } from './FooterMobileMenu'

const FooterMobile: FC = () => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const validPathname = FooterMobileMenu.some((menu) => menu.path.includes(pathname))
  const [tabValue, setTabValue] = useState(validPathname ? pathname : '')
  const onTabChange = (_event: React.SyntheticEvent, newValue: string) => setTabValue(newValue)
  const user = useSelector(userSelector)
  const dispatch = useDispatch()
  const [checkOneDOriginal, setCheckOneDOriginal] = useState(false)
  // const [imageSrc, setImageSrc] = useState(SVGImage.OneDOriginal)
  const navigate = useNavigate()

  useEffect(() => {
    setTabValue(validPathname ? pathname : '')
    if (pathname === '/oned/original') setCheckOneDOriginal(true)
  }, [pathname])

  useEffect(() => {
    if (pathname === '/oned/original') setCheckOneDOriginal(true)
  }, [])

  useEffect(() => {
    // checkOneDOriginal === true
    //   ? setImageSrc(SVGImage.OneDOriginalSelected)
    //   : setImageSrc(SVGImage.OneDOriginal)
  }, [checkOneDOriginal])

  const tagManagerClick = (menuTitle) => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'footer_menu',
        event_category: 'footer_menu',
        event_action: 'click_menu',
        event_label: menuTitle?.toLowerCase().replace(/\s/g, '')
      }
    }
    TagManager.dataLayer(tagManagerArgs)
  }

  return (
    <>
      <Grid sx={{ mt: 2 }}>
        <div>
          <img
            style={{
              marginBottom: '-60px',
              position: 'relative',
              zIndex: 9999,
              marginLeft: window.innerWidth / 2.5
            }}
            src={
              pathname?.startsWith('/oned/original')
                ? SVGImage.OneDOriginalSelected
                : SVGImage.OneDOriginal
            }
            onClick={() => {
              setCheckOneDOriginal(true)
              dispatch(setLocation('/oned/original'))
              navigate('/oned/original')
            }}
          />
        </div>
        <Tabs
          value={tabValue}
          onChange={onTabChange}
          variant='scrollable'
          TabIndicatorProps={{
            sx: {
              backgroundColor:
                validPathname && pathname !== '/oned/original' ? '#f20b7e' : 'rgba(0,0,0,0)',
              top: 0,
              p: 0.2
            }
          }}
          style={{ maxWidth: '100%' }}
        >
          {FooterMobileMenu.map((menu) => (
            <Tab
              key={menu.id}
              value={menu.path}
              sx={{ color: validPathname ? '#909290' : '#fff' }}
              label={<div className='menu-title'>{t(menu.title)}</div>}
              icon={menu.icon}
              component={NavLink}
              onClick={() => {
                tagManagerClick(t(menu.title))
                dispatch(setLocation(menu.path))
                setCheckOneDOriginal(false)
              }}
              to={menu.path === '/my-list' && !user ? '/login' : menu.path}
              style={{ minWidth: window.innerWidth / 5, width: window.innerWidth / 5 }}
            />
          ))}
        </Tabs>
      </Grid>
    </>
  )
}

export default FooterMobile
