/* eslint-disable import/order */
import React, { FC, useEffect, useState } from 'react'
import './EPGSchedule.scss'
//import { useTranslation } from 'react-i18next'
import { Box, styled } from '@mui/system'
import { Grid, Paper } from '@mui/material'
import { Flex } from '@brightcove/studio-components'
import { useTranslation } from 'react-i18next'

interface EPGScheduleProps {
  channelData?: any
}

const EPGSchedule: FC<EPGScheduleProps> = ({ channelData }) => {
  // const { t } = useTranslation()
  const Item = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
  }))
  const [currentTime, setCurrentTime] = useState(new Date())
  const [formattedCurrentTime, setFormattedCurrentTime] = useState(
    currentTime.getHours().toString().padStart(2, '0') +
      ':' +
      currentTime.getMinutes().toString().padStart(2, '0') +
      ':' +
      currentTime.getSeconds().toString().padStart(2, '0')
  )
  const data = channelData?.epg?.schedules
  const [filteredData, setFiltereData] = useState(
    data?.filter((item) => item?.endTime >= formattedCurrentTime)
  )
  const [count, setCount] = useState(1)
  const { t } = useTranslation()
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date())
      setFormattedCurrentTime(
        currentTime.getHours().toString().padStart(2, '0') +
          ':' +
          currentTime.getMinutes().toString().padStart(2, '0') +
          ':' +
          currentTime.getSeconds().toString().padStart(2, '0')
      )
      const fileterd = data?.filter((item) => item?.endTime >= formattedCurrentTime)
      setFiltereData(fileterd)
      setCount(count + 1)
    }, 15000)

    return () => clearInterval(interval)
  }, [count, data])
  useEffect(() => {
    setCurrentTime(new Date())
    setFormattedCurrentTime(
      currentTime.getHours().toString().padStart(2, '0') +
        ':' +
        currentTime.getMinutes().toString().padStart(2, '0') +
        ':' +
        currentTime.getSeconds().toString().padStart(2, '0')
    )
    const fileterd = data?.filter((item) => item?.endTime >= formattedCurrentTime)
    setFiltereData(fileterd)
  }, [data])
  return (
    <div className='epg-schedule'>
      <div className='title'>{t('liveTV.next_program')}</div>
      <Box sx={{ width: '100%', minWidth: '100%', maxWidth: '100%' }}>
        <Grid
          container
          direction='column'
          justifyContent='flex-start'
          alignItems='flex-start'
          rowSpacing={1}
        >
          {filteredData?.map((channel: any, index) => (
            <Grid item xs={8} key={index}>
              <Item>
                <p className='time'>{channel?.startTime.slice(0, -3)}</p>
                <Flex flexDirection='row' className='channelName'>
                  {formattedCurrentTime >= channel?.startTime &&
                    formattedCurrentTime <= channel?.endTime && <p className='live'>LIVE&nbsp;</p>}
                  <p className='name'>{channel?.name}</p>
                </Flex>
              </Item>
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  )
}

export default EPGSchedule
