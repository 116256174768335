import { FieldValues } from 'react-hook-form'
import React from 'react'

import { InputProps } from '../../SignUp/types'

import { InputStyles, OutlinedInputStyles } from './Input.style'

function InputField<T extends FieldValues>({
  label,
  type = 'text',
  name,
  register,
  errors,
  clearErrors,
  setValue,
  ...rest
}: InputProps<T>): any {
  const onHandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (errors && errors[name]) {
      if (clearErrors) clearErrors()
    }
    if (setValue) setValue(name, e.target.value)
  }
  return (
    <InputStyles>
      <label htmlFor={name}>{label}</label>
      <OutlinedInputStyles
        id={name}
        size='small'
        type={type}
        {...register(name as any)}
        {...rest}
        onChange={onHandleChange}
        // dataValue={rest?.value}
      />
      {errors && errors[name] && (
        <span className='input-error'>{(errors[name] as any)?.message}</span>
      )}
    </InputStyles>
  )
}

export default InputField
