import { useTranslation } from 'react-i18next'
import React, { ChangeEvent, useState, KeyboardEvent, FC, useEffect } from 'react'
import classnames from 'classnames'

import './SearchInput.scss'
import { useBreakPoints } from '../../hooks/useBreakPoints'
import { SVGImage } from '../../assets/images'

interface SearchInputProps {
  onSearch: (value: string) => void
  className?: string
  value?: string
}

const SearchInput: FC<SearchInputProps> = ({ onSearch, className, value = '' }) => {
  const [inputValue, setInputValue] = useState(value)
  const { t } = useTranslation()
  const { isMobile } = useBreakPoints()

  const changeOpacity = (isPlaceHolder) => {
    if (isPlaceHolder) {
      document.getElementsByClassName('Search-input')[0].classList.add('placeholder_opacity')
      document.getElementsByClassName('Search-icon')[0].classList.add('placeholder_opacity')
      document.getElementsByClassName('Search-input')[0].classList.remove('text_opacity')
      document.getElementsByClassName('Search-icon')[0].classList.remove('text_opacity')
    } else {
      document.getElementsByClassName('Search-input')[0].classList.add('text_opacity')
      document.getElementsByClassName('Search-icon')[0].classList.add('text_opacity')
      document.getElementsByClassName('Search-input')[0].classList.remove('placeholder_opacity')
      document.getElementsByClassName('Search-icon')[0].classList.remove('placeholder_opacity')
    }
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setInputValue(event.target.value)
  }

  const onSearchValue = (inputValue): void => {
    setInputValue(inputValue)
    onSearch(inputValue)
  }

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      onSearchValue(inputValue)
    }
  }

  const clearSearch = () => {
    setInputValue('')
    onSearch('')
  }

  useEffect(() => {
    if (inputValue.length) {
      changeOpacity(false)
    } else {
      changeOpacity(true)
    }
  }, [inputValue])

  return (
    <div className='main-content-search'>
      <div className={classnames('Search-wrapper', className)}>
        <div onClick={() => onSearchValue(inputValue)} className='Search-icon placeholder_opacity'>
          <img className='search_icon' src={SVGImage.SearchIcon} />
        </div>
        <input
          className='Search-input placeholder_opacity'
          value={inputValue}
          type={`${isMobile ? 'search' : 'text'}`}
          placeholder={t('search.header_search_placeholder') || ''}
          onChange={handleChange}
          onKeyDown={handleKeyPress}
          onKeyUp={() => onSearchValue(inputValue)}
        />
      </div>
      {!isMobile && inputValue.length > 0 && (
        <label className='clear-button' onClick={clearSearch}>
          {t('search.clear')}
        </label>
      )}
    </div>
  )
}

export default SearchInput
