import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface NotificationState {
  notificationLength: number | 0
  isSmartBannerClosed: boolean
}

const initialState: NotificationState = {
  notificationLength: 0,
  isSmartBannerClosed: false
}

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    clearNotificationCount: (state) => ({
      ...state,
      notificationLength: 0
    }),
    updateNotificationLength: (state, action: PayloadAction<number>) => ({
      ...state,
      notificationLength: action.payload
    }),
    smartBannerClose: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isSmartBannerClosed: action.payload
    })
  }
})

export const { updateNotificationLength, clearNotificationCount, smartBannerClose } =
  notificationSlice.actions

export default notificationSlice.reducer
