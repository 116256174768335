import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import React, { FC, useEffect, useState } from 'react'
import { Grid, LinearProgress } from '@mui/material'
import { Button } from '@brightcove/studio-components'

import { isEmptyObject } from '../../utils/utils'
import { useLazyGetUserProfileQuery } from '../../store/services/user/userProfileApi'
import { RootState } from '../../store'
import { Image } from '../../assets/images'
import './SubscriptionSuccess.scss'

const SubscriptionSuccess: FC = () => {
  const { t } = useTranslation()
  const { currentLocation } = useSelector((state: RootState) => state.menu)
  const [getProfileData] = useLazyGetUserProfileQuery()
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true)

  const navigate = useNavigate()
  const routeChange = () => {
    // setIsGTMSignUpSuccessful(true)
    // const path = `/`
    setTimeout(() => {
      navigate(currentLocation as string)
      // dispatch(setStep(1))
      // sessionStorage.setItem('shouldNavigateBack', 'true')
    }, 0)
  }

  const gotToSubscriptionPricing = () => {
    setTimeout(() => {
      navigate('/oned/premium')
    }, 0)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    checkSubscription()
  }, [])

  const handleLoadingState = (routeChange?: () => void) => {
    setTimeout(() => {
      setIsPageLoading(false)
      if (routeChange) routeChange()
    }, 100)
  }

  const checkSubscription = () => {
    getProfileData().then((profileResponse) => {
      const subscriptionStatus =
        profileResponse?.data?.subscription?.subscription_status?.toLowerCase()
      if (subscriptionStatus === 'active') {
        handleLoadingState(routeChange)
      } else if (
        subscriptionStatus === 'inactive' ||
        isEmptyObject(profileResponse?.data?.subscription)
      ) {
        handleLoadingState()
        return false
      }
    })
    return false
  }

  return (
    <>
      {isPageLoading && (
        <div className='page-loader'>
          <LinearProgress />
        </div>
      )}

      <Grid
        container
        direction='column'
        justifyContent='center'
        style={{ alignItems: 'center', marginBlockStart: '130px' }}
      >
        <Grid item>
          <img src={Image.OneDLogo} alt='OneD Logo' />
        </Grid>
        <Grid item>
          <img src={Image.SuccessTick} alt='OneD Logo' />
        </Grid>
        <Grid item>
          <h3 className='confirm-header'>{t('subscription.success') || ''}</h3>
        </Grid>
        <Grid item>
          <p className='description'>{t('subscription.subscription_text') || ''}</p>
        </Grid>
        <Grid item container direction='row'>
          {/* <div> */}
          <Grid
            item
            xs={6}
            style={{ justifyContent: 'flex-end', display: 'flex', paddingRight: '10px' }}
          >
            <Button className='later-button margin-top-2rem' onClick={routeChange}>
              {t('subscription.later') || ''}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button className='signup-button margin-top-2rem' onClick={gotToSubscriptionPricing}>
              {t('subscription.subscribe_now') || ''}
            </Button>
            {/* </div> */}
          </Grid>
          <Grid item xs={6} />
        </Grid>
        <br />
        {/* <SignUpGTMEvents isSignUpSuccessful={isGTMSignUpSuccessful} /> */}
      </Grid>
    </>
  )
}

export default SubscriptionSuccess
