import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import TagManager from 'react-gtm-module'
import React, { FC, useEffect, useState } from 'react'
import { styled } from '@mui/system'
import { Grid, Typography } from '@mui/material'

import { getStorage } from '../../utils/localStorageSupport'
import { UserProfile } from '../../types/userProfile'
import { myListFailure, myListStart, myListSuccess } from '../../store/slices/myListSlice'
import { useLazyGetMyListApiQuery } from '../../store/services/user/myListApi'
import { RootState } from '../../store'
import ThumbnailTile from '../../components/Tile/ThumbnailTile'
import Loading from '../../components/Loading/Loading'
// import './MyList.scss'

export const MyListStyle = styled(Grid)(({ theme }) => ({
  transform: 'translateY(100px)',
  color: '#fff',
  paddingBottom: 'clamp(40vw, 40vw, 0px)',
  backgroundColor: theme.palette.colors.chineseBlack,
  '& .my-list-content': {
    transform: 'translateY(50px)',
    width: '60%',
    margin: 'auto'
  },
  '& .header-text': {
    fontSize: 30,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    paddingBottom: 20
  },
  '@media (max-width: 600px)': {
    transform: 'translateY(0px)'
  }
}))
const MyList: FC = () => {
  const { t } = useTranslation()
  const [response, setResponse] = useState<null | UserProfile>(null)
  const dispatch = useDispatch()
  const [myList]: any = useLazyGetMyListApiQuery()
  const { myListLoading } = useSelector((state: RootState) => state.myList)
  const storage = getStorage()
  const uid = storage.getItem('uid')

  useEffect(() => {
    dispatch(myListStart('my_list'))
    const getMyList = async () => {
      try {
        setResponse(await myList())
        dispatch(myListSuccess())
      } catch (err) {
        dispatch(myListFailure('' + err))
      }
    }

    getMyList()
    const tagManagerArgs = {
      dataLayer: {
        event: 'page_view',
        page_title: 'mylist',
        page_location: window.location.href,
        member_id: uid,
        user_id: uid
      }
    }
    TagManager.dataLayer(tagManagerArgs)
  }, [])

  useEffect(() => {
    document.title = 'oneD: ' + t('nav.my-list')
  }, [response])

  if (myListLoading) {
    return <Loading />
  }

  return (
    <>
      <MyListStyle container className='my-list'>
        <Grid className='my-list-content'>
          <Grid item>
            <Typography variant='h5' className='header-text'>
              {t('nav.my-list')}
            </Typography>
          </Grid>
          <Grid item container spacing={3}>
            {response?.['data']?.favorites?.map((item, index) => (
              <Grid item key={index}>
                <ThumbnailTile key={index} item={item} page={'mylist'} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </MyListStyle>
    </>
  )
}

export default MyList
