import React from 'react'
import SearchIcon from '@mui/icons-material/Search'
import PlayCircleIcon from '@mui/icons-material/PlayCircle'
import HomeIcon from '@mui/icons-material/Home'
import AddIcon from '@mui/icons-material/Add'

interface FooterMobileMenuI {
  id: number
  title: string
  path: string
  icon?: React.ReactNode | any
}
export const FooterMobileMenu: FooterMobileMenuI[] = [
  { id: 1, title: 'nav.home', path: '/', icon: <HomeIcon /> },
  { id: 2, title: 'nav.live-tv', path: '/live-tv', icon: <PlayCircleIcon /> },
  {
    id: 3,
    title: '',
    path: '/oned/original'
    // icon: ''
  },
  { id: 4, title: 'search.header_search_placeholder', path: '/search', icon: <SearchIcon /> },
  { id: 5, title: 'nav.my-list', path: '/my-list', icon: <AddIcon /> }
]
