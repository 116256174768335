import { Link, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import React, { FC, useEffect, useState } from 'react'
import { Button, Grid } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { hideToActivationPage } from '../../../utils/utils'
import { smartBannerClose } from '../../../store/slices/notificationSlice'

import { SmartBannerStyle } from './SmartBanner.style'

type metaProps = {
  title: string | null
  author: string | null
  price: string | null
  image: string | null
  appUrl: string | null
}

const SmartBannerComponent: FC = () => {
  const [meta, setMeta] = useState<metaProps>({
    title: '',
    author: '',
    price: '',
    image: '',
    appUrl: ''
  })
  const dispatch = useDispatch()
  const { pathname } = useLocation()

  const isIOs = navigator.userAgent.includes('Mac')

  useEffect(() => {
    const title = document.querySelector('meta[name="smartbanner:title"]') as HTMLMetaElement
    const author = document.querySelector('meta[name="smartbanner:author"]') as HTMLMetaElement
    const price = document.querySelector(
      'meta[name="smartbanner:price-suffix-apple"]'
    ) as HTMLMetaElement
    const image = isIOs
      ? (document.querySelector('meta[name="smartbanner:icon-apple"]') as HTMLMetaElement)
      : (document.querySelector('meta[name="smartbanner:icon-google"]') as HTMLMetaElement)
    const appUrl = isIOs
      ? (document.querySelector('meta[name="smartbanner:button-url-apple"]') as HTMLMetaElement)
      : (document.querySelector('meta[name="smartbanner:button-url-google"]') as HTMLMetaElement)
    if (title) setMeta((meta) => ({ ...meta, title: title.getAttribute('content') }))
    if (author) setMeta((meta) => ({ ...meta, author: author.getAttribute('content') }))
    if (price) setMeta((meta) => ({ ...meta, price: price.getAttribute('content') }))
    if (image) setMeta((meta) => ({ ...meta, image: image.getAttribute('content') }))
    if (appUrl) setMeta((meta) => ({ ...meta, appUrl: appUrl.getAttribute('content') }))
  }, [])

  const blockedUrls = ['/activation']
  if (hideToActivationPage(blockedUrls, pathname)) return null

  return (
    <SmartBannerStyle isIOs={isIOs} className='smart-banner' container>
      <Grid item className='sm-close-button'>
        <CloseIcon onClick={() => dispatch(smartBannerClose(true))} />
      </Grid>
      <Grid item container alignSelf='flex-end'>
        <Grid item>
          <img src={meta.image as string} alt={meta.title as string} loading='lazy' />
        </Grid>
        <Grid item className='sm-content-text'>
          <p className='smart-banner-label title'>{meta.title}</p>
          <p className='smart-banner-label author'>{meta.author}</p>
          <p className='smart-banner-label price'>{meta.price}</p>
        </Grid>
      </Grid>
      <Grid item>
        <Button className={`${isIOs ? 'sm-mac-button' : 'sm-android-button'}`}>
          <Link target='_blank' rel='noopener noreferrer' to={meta.appUrl as string}>
            Download
          </Link>
        </Button>
      </Grid>
    </SmartBannerStyle>
  )
}

export default SmartBannerComponent
