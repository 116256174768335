import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { VideoContentItem } from '../../types/item'

interface PlayerState {
  relatedContent: VideoContentItem[] | []
}

const initialState: PlayerState = {
  relatedContent: []
}

const playerSlice = createSlice({
  name: 'player',
  initialState,
  reducers: {
    relatedContents: (state, action: PayloadAction<VideoContentItem[] | []>) => ({
      ...state,
      relatedContent: action.payload
    })
  }
})

export const { relatedContents } = playerSlice.actions

export default playerSlice.reducer
