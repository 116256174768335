import { useDraggable } from 'react-use-draggable-scroll'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import TagManager from 'react-gtm-module'
import React, { FC, MutableRefObject, useEffect, useRef, useState } from 'react'
import { styled } from '@mui/system'
import { Grid, Menu, MenuItem, Typography } from '@mui/material'
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import './ContinueWatchingSkeleton.scss'
import CancelIcon from '@mui/icons-material/Cancel'

import ThumbnailTile from '../Tile/ThumbnailTile'
import Loading from '../Loading/Loading'
import { objectToQueryString } from '../../utils/utils'
import { ContinueWatchingObject } from '../../types/userProfile'
import { VideoContentItem } from '../../types/item'
import {
  continueWatchingFailure,
  continueWatchingStart,
  continueWatchingSuccess,
  saveSelectedItem
} from '../../store/slices/continueWatchingSlice'
import { userSelector } from '../../store/slices/config/configSelectors'
import {
  useLazyGetContinueWatchingListQuery,
  useUpdateContinueWatchingMutation
} from '../../store/services/user/continueWatchingApi'
import { RootState } from '../../store'
import { useBreakPoints } from '../../hooks/useBreakPoints'

interface ContinueWatchingSkeletonProps {
  requireAsList: boolean
  onListUpdate: { (value: number): void } | null
}

export const MenuStyle = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    '& .MuiList-root': {
      backgroundColor: theme.palette.colors.chineseBlack,
      width: 213,
      height: 145,
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      '& .MuiButtonBase-root': {
        background: 'rgba(0,0,0,0)',
        padding: '20px 0px 10px 20px',
        color: '#FFF',
        '& svg': {
          // fontSize: 'medium',
          color: 'inherit',
          fontSize: 20
        },
        '& .MuiTypography-root': {
          fontSize: 16,
          fontWeight: 500,
          marginLeft: 10
        }
      }
    }
  },
  [theme.breakpoints.down('sm')]: {
    '& .MuiPaper-root': {
      backgroundColor: 'rgba(0,0,0,0)',
      display: 'contents',
      position: 'relative',
      zIndex: 10000,
      '& .MuiList-root': {
        padding: 10,
        position: 'fixed',
        bottom: 0,
        right: 0,
        left: 0,
        width: 'auto',
        '& .MuiTypography-root': {
          fontSize: 13
        }
      }
    }
  }
}))
const ContinueWatchingSkeleton: FC<ContinueWatchingSkeletonProps> = ({
  requireAsList,
  onListUpdate
}) => {
  const user = useSelector(userSelector)
  const { t } = useTranslation()
  const [response, setResponse] = useState<null | VideoContentItem[]>(null)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [continueWatching]: any = useLazyGetContinueWatchingListQuery()
  const [updateContinueWatching]: any = useUpdateContinueWatchingMutation()
  const { watchLoading } = useSelector((state: RootState) => state.continueWatching)
  const { isMobile } = useBreakPoints()
  const [listMounted, setListMounted] = useState<boolean>(false)

  const scrollRef = useRef() as MutableRefObject<any>
  const { events } = useDraggable(scrollRef, { isMounted: listMounted })

  useEffect(() => {
    dispatch(continueWatchingStart())
    const getContinueWatchingList = async () => {
      try {
        const apiResponse = await continueWatching()
        const result = apiResponse?.['data']?.watching
        const sortedDates = result
          .slice()
          ?.sort((p1, p2) =>
            p1.watched_at < p2.watched_at ? 1 : p1.watched_at > p2.watched_at ? -1 : 0
          )
        setResponse(sortedDates)
        dispatch(continueWatchingSuccess())
        if (requireAsList) setListMounted(true)
      } catch (err) {
        dispatch(continueWatchingFailure('' + err))
      }
    }
    getContinueWatchingList()
  }, [])

  useEffect(() => {
    if (onListUpdate) {
      if (response && response.length > 0) {
        onListUpdate(response.length || 0)
      } else {
        onListUpdate(0)
      }
    }
  }, [response])

  // useEffect(() => {
  //   const listener = (event) => {
  //     if (selectedItem && !event.target.classList.contains(selectedItem?.id)) {
  //       const element = document.getElementsByClassName(`more-sub-menu ${selectedItem?.id}`)[0]
  //       if (element) {
  //         element['style'].display = 'none'
  //       }
  //     }
  //   }
  //   document.addEventListener('click', listener)
  //   return () => {
  //     document.removeEventListener('click', listener)
  //   }
  // }, [selectedItem])

  useEffect(() => {
    const el: any = scrollRef.current
    if (el) {
      const onWheel = (e) => {
        if (e.deltaY === 0 || Math.abs(e.deltaY) > Math.abs(e.deltaX)) return
        e.preventDefault()
      }
      el.addEventListener('wheel', onWheel)
      return () => el.removeEventListener('wheel', onWheel)
    }
  }, [])

  if (watchLoading) {
    return <Loading />
  }

  const onClickMoreIcon = (item) => {
    dispatch(saveSelectedItem(item))
    // const element = document.getElementById('more-menu' + index)
    // if (element && window.getComputedStyle(element).getPropertyValue('display') === 'none') {
    //   element.style.display = 'flex'
    // } else if (element && window.getComputedStyle(element).getPropertyValue('display') === 'flex') {
    //   element.style.display = 'none'
    // }
  }

  // const _checkIfAnotherMenuItemIsOpen = () => {
  //   const elements = document.getElementsByClassName(`more-sub-menu`)
  //   for (let i = 0; i < elements?.length; i++) {
  //     if (window.getComputedStyle(elements[i]).getPropertyValue('display') === 'flex') {
  //       elements[i]['style'].display = 'none'
  //     }
  //   }
  // }

  const showContentDetailsRoute = (id, display_name) => {
    const videoDetails = {
      seriesName: '',
      next_up_id: '',
      seriesDescription: '',
      displayName: display_name
    }
    navigate(`/video/${id}?data=${btoa(objectToQueryString(videoDetails))}`)
  }

  const tagManagerInformationClick = (item) => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'card_information',
        event_action: 'click_card',
        event_label: item?.name,
        content_id: item?.video_id,
        content_name: item?.name,
        content_type: item?.custom_fields?.content_type,
        studio: item?.custom_fields?.studio
      }
    }
    TagManager.dataLayer(tagManagerArgs)
  }

  const tagManagerRemoveClick = (item) => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'card_remove',
        event_action: 'remove_content',
        event_label: item?.name,
        content_id: item?.video_id,
        content_name: item?.name,
        content_type: item?.custom_fields?.content_type,
        studio: item?.custom_fields?.studio
      }
    }
    TagManager.dataLayer(tagManagerArgs)
  }

  const removeFromContinueWatching = async (id) => {
    const content: ContinueWatchingObject = {
      user_id: user?.uid,
      content_id: id,
      progress: 0
    }
    try {
      dispatch(continueWatchingStart())
      await updateContinueWatching(content)
      const apiResponse = await continueWatching()
      setResponse(apiResponse?.['data']?.watching)
      dispatch(saveSelectedItem(null))
      dispatch(continueWatchingSuccess())
    } catch (err) {
      dispatch(continueWatchingFailure('' + err))
    }
  }

  const GetContentDiv = ({ item, index }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const isMenuOpen = Boolean(anchorEl)
    const onMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget)
    }
    const onMenuClose = () => setAnchorEl(null)

    return (
      <div key={index} className='continue_watching_item_holder'>
        <div className='img_holder'>
          {requireAsList && (
            <ThumbnailTile
              item={item}
              onClickMore={(val) => {
                // checkIfAnotherMenuItemIsOpen()
                onClickMoreIcon(val)
              }}
              onClickPopover={(e) => {
                onMenuClick(e)
              }}
            />
          )}
          {!requireAsList && <ThumbnailTile item={item} />}
        </div>

        <MenuStyle
          anchorEl={anchorEl}
          open={isMenuOpen}
          onClose={onMenuClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button'
          }}
          // disableScrollLock={true}
          // disableAutoFocusItem={true}
          // disableAutoFocus={true}
          // disableEnforceFocus={true}
          keepMounted={true}
        >
          {isMobile && (
            <MenuItem
              onClick={() => {
                onMenuClose()
              }}
            >
              <Grid container justifyContent={'space-between'} padding={1}>
                <Grid xs={8}>
                  <Typography className='continue-tittle'>{item.display_name}</Typography>
                </Grid>
                <CancelIcon />
              </Grid>
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              onMenuClose()
              tagManagerInformationClick(item)
              showContentDetailsRoute(item?.id, item?.display_name)
            }}
          >
            <InfoOutlinedIcon />
            <Typography>{t('continue-watching.information')}</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              onMenuClose()
              tagManagerRemoveClick(item)
              removeFromContinueWatching(item.id)
            }}
          >
            <RemoveCircleOutlineOutlinedIcon />
            <Typography>{t('continue-watching.remove_row')}</Typography>
          </MenuItem>
        </MenuStyle>
      </div>
    )
  }

  return (
    <>
      {requireAsList && (
        <ul className='ul_continue_watching' {...events} ref={scrollRef}>
          {response?.map((item, index) => (
            <li className='continue_watching_li' key={index + 'li'}>
              <GetContentDiv item={item} index={index} />
            </li>
          ))}
        </ul>
      )}
      {!requireAsList &&
        response?.map((item, index) => <GetContentDiv key={index} item={item} index={index} />)}
    </>
  )
}

export default ContinueWatchingSkeleton
