import Slider from 'react-slick'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import TagManager from 'react-gtm-module'
import React, { FC, MutableRefObject, useEffect, useRef } from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './Hero.scss'
import { Button, Grid } from '@mui/material'
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded'

import Share from '../Share/Share'
import AddToMyList from '../AddToMyList/AddToMyList'
import {
  clearPrevTrailerIds,
  gaCharsLimitation,
  getItemDetailScreen,
  isVideoItem,
  objectToQueryString
} from '../../utils/utils'
import { HeroSection } from '../../types/section'
import { useBreakPoints } from '../../hooks/useBreakPoints'

interface HeroProps {
  section: HeroSection
}

const Hero: FC<HeroProps> = ({ section }) => {
  const { isMobile } = useBreakPoints()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const scrollRef = useRef() as MutableRefObject<any>
  const linearGradient = !isMobile
    ? 'linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 30%, rgba(0,0,0,0) 70%, rgba(0,0,0,0) 100%)'
    : 'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 3.65%, rgba(0, 0, 0, 1) 75%, rgba(0,0,0,1) 100%)'

  const settings = {
    dots: false,
    dotsClass: 'Hero-slider-dots',
    infinite: true,
    arrows: false,
    speed: 500,
    className: 'Hero-slider',
    draggable: true,
    autoplay: true,
    autoplaySpeed: 10000,
    slidesToShow: 1,
    slidesToScroll: 1,
    touchThreshold: 100,
    swipeToSlide: true,
    swipe: true
  }

  useEffect(() => {
    // fake dependency execution
  }, [settings])

  const showContentDetailsRoute = (itemToCheck) => {
    const videoContent: any = getItemDetailScreen(itemToCheck)
    const casts = videoContent?.custom_fields?.cast
    const castsLimitation = gaCharsLimitation(casts, 3)

    const genres = videoContent?.custom_fields?.genre
    const genreLimitation = gaCharsLimitation(genres, 3)

    const tagManagerArgs = {
      dataLayer: {
        event: 'banner_click',
        event_category: 'homepage',
        event_action: 'click_banner',
        event_label: 'play_button',
        collection_id: section?.id,
        collection_position: section?.position,
        collection_title: videoContent?.name,
        content_id: videoContent?.video_id,
        content_position: section?.items?.findIndex(
          (obj) => obj?.video_id === itemToCheck?.video_id
        ),
        content_name: videoContent?.display_name,
        content_type: videoContent?.content_type,
        content_genre: genreLimitation,
        maturity_rating: videoContent?.custom_fields?.rating,
        studio: videoContent?.custom_fields?.studio,
        main_actor: castsLimitation
      }
    }
    TagManager.dataLayer(tagManagerArgs)
    clearPrevTrailerIds()
    let videoDetails = {
      seriesName: '',
      next_up_id: '',
      seriesDescription: '',
      displayName: ''
    }

    if (
      videoContent?.series_id !== '' &&
      videoContent?.['seriesTrailerId'] !== '' &&
      itemToCheck?.['collection_type'] === 'Series'
    ) {
      videoDetails = {
        seriesName: itemToCheck?.name,
        next_up_id: '',
        seriesDescription: itemToCheck?.description ? itemToCheck?.description : '',
        displayName: videoContent?.display_name ? videoContent?.display_name : ''
      }
      setTimeout(() => {
        navigate(
          `/video/${videoContent?.video_id}?trailer=${videoContent?.[
            `seriesTrailerId`
          ]}&data=${btoa(objectToQueryString(videoDetails))}`
        )
      }, 0)
    } else if (
      videoContent?.series_id !== '' &&
      videoContent?.['seasonTrailerId'] !== '' &&
      itemToCheck?.['collection_type'] === 'Season'
    ) {
      videoDetails = {
        seriesName: itemToCheck?.name,
        next_up_id: itemToCheck?.id,
        seriesDescription: itemToCheck?.description ? itemToCheck?.description : '',
        displayName: videoContent?.display_name ? videoContent?.display_name : ''
      }
      setTimeout(() => {
        navigate(
          `/video/${videoContent?.video_id}?trailer=${videoContent?.[
            `seasonTrailerId`
          ]}&data=${btoa(objectToQueryString(videoDetails))}`
        )
      }, 0)
    } else if (videoContent?.custom_fields?.['original_page_navigation'] === 'true') {
      setTimeout(() => {
        navigate(`/oned/original?id=${videoContent?.video_id}`)
      }, 0)
    } else {
      videoDetails = {
        seriesName: '',
        next_up_id: '',
        seriesDescription: '',
        displayName: videoContent?.display_name ? videoContent?.display_name : ''
      }
      setTimeout(() => {
        navigate(`/video/${videoContent?.video_id}?data=${btoa(objectToQueryString(videoDetails))}`)
      }, 0)
    }
  }

  const getImageSource = (itemToCheck) => {
    if (isVideoItem(itemToCheck)) {
      return itemToCheck?.images?.['thumbnail']?.src
    } else {
      return itemToCheck.image
    }
  }

  return (
    <Slider {...settings} ref={scrollRef} className='hero-content'>
      {section?.items?.map((item) => {
        const backgroundImage = `${linearGradient}, url(${getImageSource(item)})`

        return (
          <div className='hero-slide-wrapper' key={item.id}>
            <div className={`Hero-slide`} style={{ backgroundImage }} />
            <div className='slide-footer'>
              <div className='Hero-Tile-Info'>
                <Grid
                  sx={{ color: '#ffffff', display: 'flex' }}
                  gap={3}
                  alignItems='center'
                  justifyContent='center'
                >
                  {item?.custom_fields?.['rating'] && (
                    <Grid className='Hero-rating-icon'>{item?.custom_fields?.['rating']}</Grid>
                  )}
                  <Grid color={'#ffffff'}>{item?.custom_fields?.['year']}</Grid>
                </Grid>
              </div>

              <Grid
                container
                sx={{ display: 'flex' }}
                alignItems={'center'}
                justifyContent={'space-between'}
                width={'fit-content'}
              >
                <Grid item>
                  <Button
                    className='Hero-Tile-wrapper-button button pink-button'
                    onClick={() => showContentDetailsRoute(item)}
                    startIcon={<PlayArrowRoundedIcon className='Hero-Tile-icon' />}
                  >
                    {t('home.play')}
                  </Button>
                </Grid>
                {isVideoItem(item) &&
                  item?.custom_fields?.['original_page_navigation'] !== 'true' && (
                    <Grid style={{ margin: '0 0 -9px 16px ' }}>
                      <AddToMyList item={item} section={section} page={'homepage'}></AddToMyList>
                    </Grid>
                  )}
                <Grid style={{ margin: '0 0 0px 10px' }}>
                  <Share item={item}></Share>
                </Grid>
              </Grid>
            </div>
          </div>
        )
      })}
    </Slider>
  )
}

export default Hero
