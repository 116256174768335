import { useBeforeUnload } from 'react-router-dom'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import TagManager from 'react-gtm-module'
import React, { FC, MutableRefObject, useEffect, useRef, useState } from 'react'
import { createTheme } from '@mui/system'
import { Grid, useMediaQuery } from '@mui/material'
import ReactPlayerLoader from '@brightcove/react-player-loader'

import { EntitlementStyles } from '../Sections/Sections.style'
import Loading from '../Loading/Loading'
import { exitFullScreen, getPalSDKParams, isFullScreen } from '../../utils/utils'
import { getStorage } from '../../utils/localStorageSupport'
import { PlayerSection } from '../../types/section'
import { contentSuccess } from '../../store/slices/livetvSlice'
import { updateEntitlement, updateSuccessEntitlement } from '../../store/slices/entitlementSlice'
import { useLazyCheckEntitlementApiQuery } from '../../store/services/entitlement/entitlementApi'
import { useLazyGetContentDetailsQuery } from '../../store/services/content/contentApi'
import { RootState } from '../../store'
import { NEILSON_APP_ID, entitlementErrorCodes, entitlementPayload } from '../../constants'
import config from '../../config'
import DialogSubscribe from '../../components/Common/DialogSubscribe/DialogSubscribe'
import './LivePlayer.scss'

const { REACT_APP_VIDEO_POLICY_KEY, REACT_APP_VIDEO_LIVE_POLICY_KEY } = process.env

interface ReactPlayerProps {
  section: PlayerSection
}

interface DataI {
  type: any
  name: any
  nielson?: any
  adConfigId?: string
  asset_id?: string
  ad_config_id?: string
}

const LivePlayer: FC<ReactPlayerProps> = () => {
  const playerRef = useRef(null) as MutableRefObject<ReactPlayerLoader>
  const getQuery = new URLSearchParams(window.location?.search)
  const id = getQuery.get('id')
  // console.log('objParam' + objParam)
  // const { id } = useParams()
  const [contentId, setContentId] = useState(id)
  const [isPageLoading, setIsPageLoading] = useState(true)

  const { isLogged, data: userData } = useSelector((state: RootState) => state.userProfile)
  const [availableAfterValue, setAvailableAfterValue] = useState(null)

  const [isSubscribeDialogOpen, setIsSubscribeDialogOpen] = useState(false)
  const navigate = useNavigate()
  const { successEntitlement } = useSelector((state: RootState) => state.entitlement)
  const [checkEntitlementApi] = useLazyCheckEntitlementApiQuery()
  const [originalPlaybackRate, setOriginalPlaybackRate] = useState(1)
  const { menuItems } = useSelector((state: RootState) => state.menu)
  const [contentSection]: any = useLazyGetContentDetailsQuery()
  const storage = getStorage()

  const uid = storage?.getItem('uid')
  const { isNonValidEntitlement } = useSelector((state: RootState) => state.entitlement)
  const [isAdsDisable, setIsAdsDisable] = useState(false)

  // code will return the play head position
  const getPlayHeadPosition = () => Math.floor(new Date().getTime() / 1000)

  // Get the content metadata for the analytics
  const getContentMetaData = () => ({
    type: 'content',
    assetid: 'OneD-live',
    program: 'Livestream',
    title: 'OneD-Livestream',
    length: 86400,
    segB: 'Live',
    segC: '',
    isfullepisode: 'y',
    vcid: 'c07'
  })

  const dispatch = useDispatch()
  const [data, setData] = useState<DataI>({
    adConfigId: '',
    asset_id: '',
    name: '',
    type: ''
  })
  const [adConfigId, setAdConfigId] = useState<any>()
  const path = window.location.href
  let contentPageId: any = ''

  let nSdkInstance: any = null
  const [isVideoPlaying, setIsVideoPlaying] = useState<boolean>(false)

  // code will load the content section data
  const getContent = async () => {
    try {
      if (menuItems?.headerItems) {
        for (const item of menuItems.headerItems) {
          if (item?.name?.toLowerCase() == 'oned original') {
            contentPageId = item?.id
          }
        }
      }
      if (contentPageId) {
        const { data } = await contentSection({ id: contentPageId })
        setData(data)
        const id = getQuery.get('id')
        if (id) navigate(`/oned/original?id=${id}`)
        else navigate(`/oned/original?id=${data?.['asset_id']}`)
        dispatch(data)
        dispatch(contentSuccess())
      }
    } catch (err) {
      //
    }
  }

  // Initializing the Nielson SDK
  nSdkInstance = window.NOLBUNDLE.nlsQ(NEILSON_APP_ID, '<ONE31-SAMPLE>', {
    nol_sdkDebug: 'debug'
  })

  let intervalId: any = ''

  // called when the video playing status changed
  useEffect(() => {
    if (isVideoPlaying) {
      // start the interval when video playing status is true
      intervalId = setInterval(() => {
        nSdkInstance?.ggPM('setPlayheadPosition', getPlayHeadPosition())
      }, 1000)
    }
    return () => {
      clearInterval(intervalId)
    }
  }, [isVideoPlaying])

  // Entitlement api request payload
  const payload = entitlementPayload(uid as string, id as string)

  // get the user entitlement data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response: any = await checkEntitlementApi(payload)
        setIsPageLoading(false)
        if (response.status === 'rejected') {
          const errorCode = response?.error?.data?.code
          dispatch(updateEntitlement(true))
          if (entitlementErrorCodes.includes(errorCode)) {
            if (response?.error?.data?.message?.includes('availableAfter')) {
              const pattern = /availableAfter: (\d+)/
              setAvailableAfterValue(response?.error?.data?.message.match(pattern)[1])
            }
            setIsSubscribeDialogOpen(true)
          }
        } else if (response.status === 'fulfilled') {
          dispatch(updateEntitlement(false))
          dispatch(updateSuccessEntitlement(response.data))
        }
      } catch (error: any) {
        if (entitlementErrorCodes.includes(error.code)) {
          dispatch(updateSuccessEntitlement(true))
        }
      }
    }

    if (isLogged) {
      fetchData()
    }
  }, [isLogged])

  // requests to get the content details
  useEffect(() => {
    getContent()
  }, [menuItems?.headerItems, path])

  useEffect(() => {
    setContentId(id)
  }, [id])

  // handle the close subscription dialog
  const handleSubscribeDialogClose = () => {
    setIsSubscribeDialogOpen(false)
  }

  // handle the subscription dialog and navigate to subscribe page
  const handleSubscribeDialogToSubscribe = () => {
    setIsSubscribeDialogOpen(false)
    navigate('/oned/premium')
  }

  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'original_menu',
        event_category: 'original_menu',
        event_action: 'see_menu',
        event_label: 'oned_original'
      }
    }
    TagManager.dataLayer(tagManagerArgs)
  }, [])

  const theme = createTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    setAdConfigId((data?.adConfigId as string) || '')
  }, [data, data?.adConfigId])

  // called when before leaving the page, and stop (refresh the page)
  useBeforeUnload(
    React.useCallback(() => {
      nSdkInstance?.ggPM('end', getPlayHeadPosition())
      nSdkInstance?.ggPM('stop', getPlayHeadPosition())
    }, [])
  )

  // called when unmount the component (When navigate to different page)
  useEffect(
    () => () => {
      nSdkInstance?.ggPM('end', getPlayHeadPosition())
      nSdkInstance?.ggPM('stop', getPlayHeadPosition())
    },
    []
  )

  useEffect(() => {
    if (uid) {
      checkEntitlementApi(payload).then((entitlement: any) => {
        if (entitlement.data.features?.isAds === false) {
          setIsAdsDisable(true)
        } else {
          setIsAdsDisable(false)
        }
      })
    } else {
      setIsAdsDisable(false)
    }
  }, [uid])
  // called when player initialized success
  const onSuccessPlayer = (success) => {
    const mPlayer = success.ref

    mPlayer.ssai().googlePal.setStorageConsent(true)
    mPlayer.ssai().googlePal.setParams(getPalSDKParams(userData))

    // disabling the ads if the feature not true
    mPlayer.on('ima3-ads-manager-loaded', () => {
      if (isAdsDisable) {
        mPlayer.ima3.adsManager.destroy()
      }
    })
    // Send the content meta data when load started
    mPlayer.on('loadstart', function () {
      nSdkInstance?.ggPM('loadMetadata', getContentMetaData())
    })

    mPlayer.ready(function () {
      setOriginalPlaybackRate(playerRef?.current?.playbackRate())
      playerRef.current = mPlayer
      mPlayer
        ?.getChild('controlBar')
        ?.el()
        ?.insertBefore(
          mPlayer.addChild('pictureInPictureToggle', {}).el(),
          document.getElementsByClassName('vjs-fullscreen-control')[0]
        )
      const catalogParams = {}
      catalogParams['type'] = 'video'
      catalogParams['id'] = contentId
      catalogParams['adConfigId'] = adConfigId
      catalogParams['assetId'] = data?.asset_id !== undefined ? data?.asset_id : ''
      catalogParams['policyKey'] =
        data.type === 'OriginalPage'
          ? (REACT_APP_VIDEO_LIVE_POLICY_KEY as string)
          : (REACT_APP_VIDEO_POLICY_KEY as string)

      // registering the play event
      mPlayer.on('play', () => {
        setIsVideoPlaying(true)
        if (mPlayer.paused()) {
          setIsVideoPlaying(false)
        }
      })

      // registering the playing event
      mPlayer.on('playing', () => {
        setIsVideoPlaying(true)
      })

      // registering the pause event
      mPlayer.on('pause', () => {
        setIsVideoPlaying(false)
      })

      // registering the content end event
      mPlayer.on('end', () => {
        setIsVideoPlaying(false)
        nSdkInstance?.ggPM('stop', getPlayHeadPosition())
        nSdkInstance?.ggPM('end', getPlayHeadPosition())
      })

      // registering the ad started event
      mPlayer.on('ads-ad-started', () => {
        // send the ad metadata to the analytics
        nSdkInstance?.ggPM('loadMetadata', {
          type: mPlayer.ads.adType || '',
          assetId: mPlayer.ads.ad.id || ''
        })
        setIsVideoPlaying(true)
      })

      // registering the ad pause event
      mPlayer.on('ads-pause', () => {
        setIsVideoPlaying(false)
      })

      // registering the ad resume event
      mPlayer.on('ads-play', () => {
        setIsVideoPlaying(true)
      })

      // registering the ad ended event
      mPlayer.on('ads-ad-ended', () => {
        setIsVideoPlaying(false)
      })

      // registering the ad ended event
      mPlayer.on('adend', () => {
        setIsVideoPlaying(false)
        nSdkInstance?.ggPM('stop', getPlayHeadPosition())
        nSdkInstance?.ggPM('loadMetadata', getContentMetaData())
      })

      mPlayer.catalog.getVideo(catalogParams, function (error, video) {
        if (error) {
          throw error
        }
        mPlayer.catalog.load(video)
        mPlayer.on('loadedmetadata', function () {
          const closeButton: any = document.querySelector('.vjs-errors-ok-button')
          closeButton?.click()
          mPlayer.muted(true)
          mPlayer.play()
          //setIsPlaying(true)
          setIsVideoPlaying(true)
          // Select all the quality menu items
          const qualityMenuHtmlItems = document.querySelectorAll(
            '.vjs-control-bar .vjs-quality-menu-wrapper .vjs-menu-content .vjs-menu-item'
          )
          const allMenuItem = playerRef.current.controlBar.getChild('QualityMenuButton').items

          const indexesWithHDSubLabel: number[] = []
          let autoIndex
          const maxResolutionLabel = successEntitlement?.features?.maxResolutionSupported || 720
          const preventResolution: number[] | any = []
          allMenuItem.forEach((item, i) => {
            const resolutionLabelWithoutP = item.options_.label.replace('p', '')
            if (parseInt(resolutionLabelWithoutP) > maxResolutionLabel) {
              indexesWithHDSubLabel.push(item.options_.levels[0])
              preventResolution.push(item.options_.label)
            }

            if (item.options_.controlText === 'Auto') autoIndex = i
          })

          playerRef.current.controlBar.getChild('QualityMenuButton').items[autoIndex].levels_ =
            allMenuItem[autoIndex].levels_.filter((item) => !indexesWithHDSubLabel.includes(item))

          playerRef.current.controlBar.getChild('QualityMenuButton').items[
            autoIndex
          ].options_.levels = allMenuItem[autoIndex].options_.levels.filter(
            (item) => !indexesWithHDSubLabel.includes(item)
          )

          // Iterate over each quality menu item
          qualityMenuHtmlItems.forEach((item) => {
            if (
              preventResolution?.includes(item?.querySelector('.vjs-menu-item-text')?.textContent)
            ) {
              item.addEventListener('click', () => {
                if (isFullScreen()) {
                  exitFullScreen()
                }
                let isSelected
                allMenuItem.forEach((item, i) => {
                  if (item.isSelected_) isSelected = i
                })
                playerRef.current.controlBar
                  .getChild('QualityMenuButton')
                  .items[isSelected].handleClick_()
                setIsSubscribeDialogOpen(true)
              })
            }
            if (item?.textContent?.includes('Auto')) {
              item.addEventListener('click', () => {
                playerRef.current.controlBar.getChild('QualityMenuButton').items[
                  autoIndex
                ].levels_ = allMenuItem[autoIndex].levels_.filter(
                  (item) => !indexesWithHDSubLabel.includes(item)
                )

                playerRef.current.controlBar.getChild('QualityMenuButton').items[
                  autoIndex
                ].options_.levels = allMenuItem[autoIndex].options_.levels.filter(
                  (item) => !indexesWithHDSubLabel.includes(item)
                )
              })
            }
          })
        })

        //>>====SUBSCRIBE POPUP IF USER IS NOT SUBSCRIBED--------->
        mPlayer.on('enterpictureinpicture', (e) => {
          if (!successEntitlement?.features?.isPIPEnabled) {
            if (isFullScreen()) {
              exitFullScreen()
            }
            e.preventDefault()
            document.exitPictureInPicture()
            setIsSubscribeDialogOpen(true)
          }
        })
        playerRef.current.on('ratechange', (e) => {
          if (!successEntitlement?.features?.isPlaybackSpeed) {
            if (isFullScreen()) {
              exitFullScreen()
            }
            e.preventDefault()
            const currentPlaybackRate = playerRef.current.playbackRate()
            // Check if the playback rate has changed
            if (currentPlaybackRate !== originalPlaybackRate) {
              // Adjust the playback rate back to the original value
              playerRef.current.playbackRate(originalPlaybackRate)
            }
            setIsSubscribeDialogOpen(true)
          }
        })
        const controlBar = mPlayer.controlBar

        // Get the Chromecast button component
        const chromeCastButton = controlBar.getChild('ChromecastButton')
        // event listener to the Chromecast button
        chromeCastButton?.on('click', (event) => {
          if (!successEntitlement?.features?.isCastingEnabled) {
            if (isFullScreen()) {
              exitFullScreen()
            }
            event.preventDefault()
            event.stopImmediatePropagation()
            setIsSubscribeDialogOpen(true)
          }
        })
      })
    })
  }

  return (
    <div className='live-player'>
      {isPageLoading ? (
        <Loading />
      ) : (
        <>
          {isNonValidEntitlement ? (
            <EntitlementStyles />
          ) : (
            <Grid
              width={`${isMobile ? '100%' : '60%'}`}
              margin='auto'
              key={contentId + 'root_player'}
              className='ReactPlayer-container'
              style={{ opacity: 1 }}
            >
              {[
                <ReactPlayerLoader
                  key={contentId}
                  attrs={{
                    className: 'ReactPlayer'
                  }}
                  accountId={config.accountId}
                  playerId={
                    /**
                     * Delaying player initialization until user type check (premium/non-premium) to avoid parallel processing issues of JS engine.
                     */
                    !isAdsDisable ? config.livePlayerId : isAdsDisable && config.livePlayerId
                  }
                  videoId={contentId !== undefined ? contentId : ''}
                  adConfigId={data?.adConfigId !== undefined ? data?.adConfigId : ''}
                  assetId={data?.asset_id !== undefined && data?.asset_id}
                  onFailure={(e) => {
                    throw e
                  }}
                  onSuccess={onSuccessPlayer}
                  options={{
                    autoplay: 'any'
                  }}
                />
              ].filter((div) => {
                if (div.props.accountId && div.props.assetId) {
                  return div
                }
              })}
            </Grid>
          )}
          <DialogSubscribe
            isDialogOpen={isSubscribeDialogOpen}
            message={availableAfterValue ? 'dialog.sub_title_error' : 'dialog.sub_title_success'}
            availableAfter={availableAfterValue}
            onDialogClose={handleSubscribeDialogClose}
            onSubscribeNow={handleSubscribeDialogToSubscribe}
          />
          <Grid></Grid>
        </>
      )}
    </div>
  )
}
export default LivePlayer
