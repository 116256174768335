import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { getStorage } from '../../../utils/localStorageSupport'

import { ConfigState, User } from './types'

export const initialState: ConfigState = {
  displayLogIn: false,
  maintenanceMessage: null,
  language: 'en',
  user: null,
  userInfoFetching: true,
  token: null,
  isAuthenticated: false,
  viewMarketing: true
}
const storage = getStorage()

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    showLogIn: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.displayLogIn = true
    },
    hideLogIn: (state) => {
      state.displayLogIn = false
    },
    setMaintenanceMessage: (state, action: PayloadAction<string | null>) => {
      state.maintenanceMessage = action.payload
    },
    setSiteLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload
    },
    setUser: (state, action: PayloadAction<User | null>) => {
      if (action.payload) {
        state.user = action.payload
        state.displayLogIn = false
        // state.userInfoFetching = false;
      } else {
        state.user = null
        state.displayLogIn = false
        // state.userInfoFetching = false;
      }
    },
    setToken: (state, action: PayloadAction<string | null>) => {
      state.token = action.payload
      state.isAuthenticated = !!action.payload
      storage?.setItem('token', action.payload as string)
    },
    clearToken: (state) => {
      state.token = null
      state.isAuthenticated = false
      storage?.removeItem('token')
      storage?.removeItem('uid')
    },
    setMarketing: (state, action: PayloadAction<boolean>) => {
      state.viewMarketing = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const {
  showLogIn,
  hideLogIn,
  setMaintenanceMessage,
  setSiteLanguage,
  setUser,
  setToken,
  setMarketing,
  clearToken
} = configSlice.actions

export default configSlice.reducer
