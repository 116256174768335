import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { getApiHeader } from '../../../utils/utils'
import { ContentSeasonData } from '../../../types/season'
import { SectionData } from '../../../types/page'
import config from '../../../config'

export const contentApi = createApi({
  reducerPath: 'content',
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.apiUrl}`,
    prepareHeaders: (headers, { getState }) => getApiHeader(headers, { getState })
  }),
  endpoints: (builder) => ({
    getContentSeasonDetails: builder.query<ContentSeasonData[], { id: string }>({
      query: (args) => {
        const { id } = args
        ;('GET')
        return {
          url: `/collections/${id}/items`
        }
      }
    }),
    getContentDetails: builder.query<SectionData, { id: string }>({
      query: (args) => {
        const { id } = args
        ;('GET')
        return {
          url: `page/${id}`
        }
      }
    }),
    getTrailerDetails: builder.query<SectionData, { id: string }>({
      query: (args) => {
        const { id } = args
        ;('GET')
        return {
          url: `/cms/${id}`
        }
      }
    })
  })
})

export const {
  useLazyGetContentDetailsQuery,
  useLazyGetContentSeasonDetailsQuery,
  useLazyGetTrailerDetailsQuery
} = contentApi
