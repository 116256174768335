import ReactDOM from 'react-dom/client'
import React from 'react'

import reportWebVitals from './reportWebVitals'
import { register } from './registerServiceWorker'
import App from './App'
import './i18n'
import './styles/base.scss'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(<App />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
register()
