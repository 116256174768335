import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import React, { FC, useEffect, useState } from 'react'

import { contentSuccess } from '../../store/slices/contentSlice'
import { useLazyGetContentDetailsQuery } from '../../store/services/content/contentApi'
import { RootState } from '../../store'
import Sections from '../../components/Sections/Sections'
import PageHead from '../../components/PageHead/PageHead'

const OneDOriginalDetail: FC = () => {
  const { menuItems } = useSelector((state: RootState) => state.menu)
  const dispatch = useDispatch()

  const navigate = useNavigate()

  const [data, setData] = useState()
  const [contentSection]: any = useLazyGetContentDetailsQuery()
  const getQuery = new URLSearchParams(window.location?.search)
  let contentPageId: any = ''
  const path = window.location.href
  const getContent = async () => {
    try {
      if (menuItems?.headerItems) {
        for (const item of menuItems.headerItems) {
          if (item?.name?.toLowerCase() == 'oned original') {
            contentPageId = item?.id
          }
        }
      }
      if (contentPageId) {
        const { data } = await contentSection({ id: contentPageId })
        setData(data)
        const id = getQuery.get('id')
        if (id) navigate(`/oned/original?id=${id}`)
        else navigate(`/oned/original?id=${data?.['asset_id']}`)
        dispatch(data)
        dispatch(contentSuccess())
      }
    } catch (err) {
      // dispatch(contentFailure('' + err))
    }
  }
  useEffect(() => {
    getContent()
  }, [menuItems?.headerItems, path])

  return (
    <div>
      <PageHead data={data?.['head']} />
      <div className='main-content-detail'>
        <div className='main-container'>
          <Sections sections={data?.['sections']} type={data?.['type']} page={'oned_original'} />
        </div>
      </div>
    </div>
  )
}

export default OneDOriginalDetail
