import { styled } from '@mui/system'

export const TvActivationStyle = styled('div')(() => ({
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  height: '100vh',
  '> *': {
    padding: 0,
    margin: 0,
    transition: 'all .5s'
  }
}))
