import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { MarketingCampaignData } from '../../types/marketingCampaign'

interface MarketingState {
  campaignLoading: boolean
  error: string | null
  success: boolean
  items: MarketingCampaignData[] | undefined
}

const initialState: MarketingState = {
  campaignLoading: false,
  error: null,
  success: false,
  items: undefined
}

const marketingSlice = createSlice({
  name: 'marketing',
  initialState,
  reducers: {
    marketingStart: (state) => ({
      ...state,
      campaignLoading: true,
      error: null,
      success: false,
      items: undefined
    }),
    marketingSuccess: (state, action: PayloadAction<MarketingCampaignData[] | undefined>) => ({
      ...state,
      campaignLoading: false,
      error: null,
      success: true,
      items: action.payload
    }),
    marketingFailure: (state, action: PayloadAction<string>) => ({
      ...state,
      campaignLoading: false,
      error: action.payload,
      success: false
    })
  }
})

export const { marketingFailure, marketingStart, marketingSuccess } = marketingSlice.actions

export default marketingSlice.reducer
