import { useDispatch, useSelector } from 'react-redux'
import React, { FC } from 'react'
import { Flex } from '@brightcove/studio-components'

import SearchInput from '../SearchInput/SearchInput'
import './SearchBar.scss'
import { clearQueryResponse, searchStart } from '../../store/slices/searchSlice'
import { RootState } from '../../store/index'

interface SearchBarProps {
  input: string
}

const SearchBar: FC<SearchBarProps> = () => {
  const dispatch = useDispatch()
  const query = useSelector((state: RootState) => state.search.query)

  const getSearchResult = async (val) => {
    if (val.length >= 3) {
      dispatch(clearQueryResponse(''))
      dispatch(searchStart(val))
    } else if (val.length < 3) {
      dispatch(clearQueryResponse(''))
    }
  }

  return (
    <Flex className='SearchBar' justifyContent='center'>
      <SearchInput
        value={query}
        key='search'
        onSearch={(val) => {
          getSearchResult(val)
        }}
        className='SearchBar-search'
      />
    </Flex>
  )
}

export default SearchBar
