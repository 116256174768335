import { useTranslation } from 'react-i18next'
import React, { FC } from 'react'
import { Grid, Typography } from '@mui/material'

import './ContinueWatching.scss'
import ContinueWatchingSkeleton from '../../components/ContinueWatching/ContinueWatchingSkeleton'

const ContinueWatching: FC = () => {
  const { t } = useTranslation()
  return (
    <>
      <Grid container className='Continue-Watching-Grid' flexDirection='column' gap={4}>
        <Typography
          variant='h5'
          className='Continue-Watching-Grid-header'
          sx={{ marginLeft: { xs: 4, sm: 6, md: 7, lg: 8 } }}
          marginTop={5}
        >
          {t('continue-watching.continue-watching')}
        </Typography>
        <Grid sx={{ marginLeft: { xs: 4, sm: 6, md: 7, lg: 8 } }}>
          <Grid container gap={6} columns={{ xs: 2, sm: 8, md: 12, lg: 12 }}>
            <ContinueWatchingSkeleton requireAsList={false} onListUpdate={null} />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default ContinueWatching
