import { NavLink, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { AppStoreButton, ButtonsContainer, GooglePlayButton } from 'react-mobile-app-button'
import { Trans, useTranslation } from 'react-i18next'
import TagManager from 'react-gtm-module'
import React, { FC } from 'react'
import { Grid, Typography, createTheme, useMediaQuery } from '@mui/material'
import { Flex } from '@brightcove/studio-components'

import { LinkText } from '../LinkText/LinkText'
import { LinkSocial } from '../LinkSocial/LinkSocial'
import useSubscriptionStatus from '../Common/SubscriptionStatus/SubscriptionStatus'
import { hideToActivationPage } from '../../utils/utils'
import { setLocation } from '../../store/slices/menuSlice'
import { userSelector } from '../../store/slices/config/configSelectors'
import { RootState } from '../../store'
import { LINKS } from '../../constants'
import { Image } from '../../assets/images'
import Version from '../../../package.json'

import './Footer.scss'

import FooterMobile from './Components/FooterMobile'

const Footer: FC = () => {
  const theme = createTheme()
  const user = useSelector(userSelector)
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const dispatch = useDispatch()

  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const { footerItems } = useSelector((state: RootState) => state.menu)
  let updatedFooterItems = [...footerItems]

  const isSubscriptionUser = useSubscriptionStatus()

  const getNavigationPath = (item: { path: string }) => {
    if (!user) {
      if (item.path === '/my-list' && pathname !== '/login') {
        return '/login'
      } else {
        return item.path
      }
    } else {
      return item.path
    }
  }
  const blockedUrls = ['/activation']
  if (hideToActivationPage(blockedUrls, pathname)) return null

  if (footerItems) {
    const homeIndex = footerItems?.findIndex((item) => item.title === 'Home')
    if (homeIndex !== -1) {
      updatedFooterItems = footerItems.filter((_, index) => index !== homeIndex)
      const homeItem = footerItems[homeIndex]
      updatedFooterItems.unshift(homeItem)
    }
  }

  const tagManagerClick = (menuTitle) => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'footer_menu',
        event_category: 'footer_menu',
        event_action: 'click_menu',
        event_label: menuTitle?.toLowerCase().replace(/\s/g, '')
      }
    }
    TagManager.dataLayer(tagManagerArgs)
  }

  return (
    <>
      {!isMobileScreen ? (
        <div className='Footer Footer-section-top'>
          <div className='Footer-section-nav'>
            {updatedFooterItems?.map((item) => {
              if (item.title === 'ONED PREMIUM' || item.title === 'วันดี พรีเมี่ยม') {
                if (isSubscriptionUser) return null
              }
              if (!item?.isParent) {
                return (
                  <div key={item.title}>
                    <NavLink
                      className='footer-link'
                      onClick={() => {
                        tagManagerClick(t(item.title))
                        dispatch(setLocation(item.path))
                      }}
                      // to={item.path}
                      to={getNavigationPath(item) as string}
                    >
                      {t(item.title)}
                    </NavLink>
                  </div>
                )
              }
            })}
          </div>
          <Grid
            container
            justifyContent={'space-between'}
            className='Footer-section Footer-section-top'
            gap={2}
            flexWrap={'nowrap'}
          >
            <Grid item container sm={5} md={5} lg={5}>
              <Grid item container flexWrap={'nowrap'} alignItems={'center'}>
                <NavLink to='/'>
                  <img className='Footer-main-logo' src={Image.One31Logo} alt='One31' />
                </NavLink>
                <Grid
                  sm={8}
                  md={8}
                  sx={{ fontSize: { sm: 12, md: 12, lg: 20 } }}
                  textAlign={'justify'}
                >
                  <Trans i18nKey='footer.motto' />
                </Grid>
              </Grid>
              <Grid
                item
                direction={'column'}
                sx={{ fontSize: { sm: 12 }, marginLeft: '1rem' }}
                textAlign={'justify'}
              >
                <Typography
                  sx={{ fontSize: { sm: 'x-small', md: 14, lg: 16 } }}
                  className='Footer-address-name'
                >
                  <Trans i18nKey='footer.name' />
                </Typography>
                <Typography
                  sx={{ fontSize: { sm: 'x-small', md: 14, lg: 16 }, letterSpacing: 0 }}
                  className='Footer-address-first'
                >
                  <Trans i18nKey='footer.address1' />
                </Typography>
                <Typography
                  sx={{ fontSize: { sm: 'x-small', md: 14, lg: 16 } }}
                  className='Footer-address-second'
                >
                  <Trans i18nKey='footer.address2' />
                </Typography>
              </Grid>
            </Grid>
            {/* right side */}
            <Flex>
              <Grid
                item
                container
                flexWrap={'nowrap'}
                className='footer-right-container'
                sx={{ gap: { sm: 0, md: 0, lg: 1 }, marginTop: { sm: 4, md: 4, lg: 5 } }}
              >
                <img className='Footer-second-logo' src={Image.One31FooterLogo} alt='One31' />
                <Grid item justifyContent={'center'}>
                  <Grid sx={{ height: { sm: 14, md: 18, lg: 40 } }}></Grid>
                  <Grid
                    xs={3}
                    sm={12}
                    md={12}
                    lg={12}
                    sx={{
                      fontSize: { sm: 'x-small', md: 10, lg: 13 },
                      marginBottom: { sm: 0.5, md: 1, lg: 2 }
                    }}
                  >
                    <Trans i18nKey='footer.mobile_download' />
                  </Grid>
                  <Grid justifyContent={'center'}>
                    <ButtonsContainer>
                      <AppStoreButton
                        url={LINKS.stores.apple}
                        theme={'dark'}
                        className={'Footer-store-link app-button'}
                      />

                      <GooglePlayButton
                        url={LINKS.stores.google}
                        theme={'dark'}
                        className={'Footer-store-link app-button'}
                      />
                    </ButtonsContainer>
                  </Grid>
                </Grid>
              </Grid>
            </Flex>
          </Grid>
          <Flex
            className='Footer-section Footer-section-bottom'
            flexDirection='row'
            justifyContent='space-between'
          >
            <Grid className='Footer-section-bottom-left'>
              <LinkText
                defaultText='ข้อกำหนดและเงื่อนไข'
                linkKey='terms'
                textKey='legal.terms'
                className='Footer-link-text'
              />
              <LinkText
                defaultText='นโยบายความเป็นส่วนตัว'
                linkKey='privacy'
                textKey='legal.privacy'
                className='Footer-link-text'
              />
            </Grid>
            <Grid className='Footer-section-bottom-right'>
              <div className='footer-parent'>
                <LinkSocial to={LINKS.social.tiktok} title='TikTok' type='tiktok' />
                <LinkSocial to={LINKS.social.facebook} title='Facebook' type='facebook' />
                <LinkSocial to={LINKS.social.instagram} title='Instagram' type='instagram' />
                <LinkSocial to={LINKS.social.twitter} title='Twitter' type='twitter' />
                <LinkSocial to={LINKS.social.youtube} title='Youtube' type='youtube' />
              </div>
              <span className='version_text_web'>Version {`${Version.version}`}</span>
            </Grid>
          </Flex>
        </div>
      ) : pathname.includes('/password-reset') ||
        pathname.startsWith('/profile') ||
        pathname.startsWith('/signup') ? null : (
        <FooterMobile />
      )}
    </>
  )
}
export default Footer
