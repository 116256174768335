import { Dialog, styled } from '@mui/material'

export const DialogStyle = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: 746,
    height: 'auto'
  },
  '& .MuiDialogContent-root': {
    background: 'rgba(0,0,0,0)',
    overflow: 'hidden',
    padding: 0
  },
  '& .upgrade_button': {
    textAlign: 'center',
    width: '100%',
    backgroundColor: '#000',
    padding: '20px 0',
    boxShadow: '0 0 40px 20px #000',
    ' button': {
      background: theme.palette.primary.main,
      width: 290
    }
  },
  '& .action-button-wrapper': {
    width: '100%',
    backgroundColor: '#404040',
    padding: '0 10px'
  },
  '& .MuiDialogActions-root': {
    width: '100%',
    padding: 0,
    margin: 0
  },
  '& .marketing-img-thumbnail': {
    width: '100%',
    height: 'auto',
    objectFit: 'contain'
  },

  '& .campaign_holder img': {
    width: 746,
    height: 'auto',
    cursor: 'pointer'
  }
}))
