import { NavLink } from 'react-router-dom'
import React, { FC } from 'react'
import { Button } from '@mui/material'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'

import { Image } from '../../../assets/images'

interface ProfileEditMobileHeaderI {
  setIsEditable: (value: boolean) => void
  t: (value: string) => string
}
const ProfileEditMobileHeader: FC<ProfileEditMobileHeaderI> = ({ setIsEditable, t }) => (
  <div className='profile-mobile-edit'>
    <header>
      <NavLink className='header-logo' to='/'>
        <img src={Image.One31Logo} alt='OneD Logo' />
      </NavLink>
      <Button
        onClick={() => setIsEditable(false)}
        className='profile-close-button'
        startIcon={<CloseOutlinedIcon />}
      />
    </header>
    <main>
      <h1 className='profile-header'>{t('profile.update_profile')}</h1>
      <div className='pink-slider' />
      <p>{t('signup.user_information')}</p>
    </main>
  </div>
)
export default ProfileEditMobileHeader
