import { useDraggable } from 'react-use-draggable-scroll'
import { useTranslation } from 'react-i18next'
import React, { FC, MutableRefObject, useEffect, useRef } from 'react'

import ThumbnailTile from '../../Tile/ThumbnailTile'
import { VideoContentItem } from '../../../types/item'
import './More.scss'

interface MoreProps {
  items: VideoContentItem[] | undefined
}

const More: FC<MoreProps> = ({ items }) => {
  const { t } = useTranslation()

  const scrollRef = useRef() as MutableRefObject<any>
  const { events } = useDraggable(scrollRef)

  useEffect(() => {
    const el: any = scrollRef.current
    if (el) {
      const onWheel = (e) => {
        if (e.deltaY === 0 || Math.abs(e.deltaY) > Math.abs(e.deltaX)) return
        e.preventDefault()
      }
      el.addEventListener('wheel', onWheel)
      return () => el.removeEventListener('wheel', onWheel)
    }
  }, [])

  return (
    <>
      {items && items.length && (
        <div className='more_container'>
          <div className='More'>
            {items && items?.length > 0 && (
              <div className='More-tab'>
                <div>{t('content-detail.more')} </div>
                <div className='More-content'>
                  <ul ref={scrollRef} {...events}>
                    {items &&
                      items.length &&
                      items?.map((item, index) => (
                        <li key={index}>
                          <ThumbnailTile
                            key={index}
                            item={item}
                            useThumbnail={true}
                            page={'vod_player'}
                          />
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default More
