import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import TagManager from 'react-gtm-module'
import React, { FC, useEffect } from 'react'

import './Search.scss'
import eventBus from '../../utils/EventBus'
import {
  searchSuccess,
  searchFailure,
  setSectionResponse,
  setQueryResponse
} from '../../store/slices/searchSlice'
import { userSelector } from '../../store/slices/config/configSelectors'
import { useLazyGetSearchPageQuery } from '../../store/services/page/pageApi'
import { RootState } from '../../store'
import Sections from '../../components/Sections/Sections'
import PageHead from '../../components/PageHead/PageHead'
import Loading from '../../components/Loading/Loading'

const SearchPage: FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [searchPageResult]: any = useLazyGetSearchPageQuery()
  const { query, searchResultLoading, sectionResult } = useSelector(
    (state: RootState) => state.search
  )
  const user = useSelector(userSelector)

  useEffect(() => {
    document.title = 'oneD: ' + t('search.header_search_placeholder')
    const tagManagerArgs = {
      dataLayer: {
        event: 'page_view',
        page_name: 'search',
        page_location: location?.href,
        user_id: user?.uid
      }
    }
    TagManager.dataLayer(tagManagerArgs)
  }, [])

  const getSearchResult = async () => {
    // dispatch(searchStart(''))
    try {
      const searchResponse = await searchPageResult()
      dispatch(setSectionResponse(searchResponse))
      dispatch(searchSuccess(0))
    } catch (err) {
      dispatch(searchFailure('' + err))
    }
  }

  useEffect(() => {
    // if (query === '' && reload) {
    dispatch(setQueryResponse(null))
    getSearchResult()
    // }

    // eventBus.on('searchQuery', (_) => {
    //dispatch(searchStart(data.detail.message))
    // })
  }, [query])

  useEffect(
    () => () => {
      eventBus.remove('searchQuery', null!)
    },
    []
  )

  if (searchResultLoading && query === '') {
    return <Loading />
  }

  return (
    <>
      <div className='search-content'>
        <PageHead data={sectionResult?.['data']?.head} />
        <div className='main-content-in-search'>
          <Sections sections={sectionResult?.['data']?.sections} />
        </div>
      </div>
    </>
  )
}

export default SearchPage
