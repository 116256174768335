import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface UserCookieState {
  userCookieLoading: boolean
  error: string | null
  success: boolean
}

const initialState: UserCookieState = {
  userCookieLoading: false,
  error: null,
  success: false
}

const userCookieSlice = createSlice({
  name: 'userCookie',
  initialState,
  reducers: {
    userCookieStart: (state) => ({
      ...state,
      userCookieLoading: true,
      error: null,
      success: false
    }),
    userCookieSuccess: (state) => ({
      ...state,
      userCookieLoading: false,
      error: null,
      success: true
    }),
    userCookieFailure: (state, action: PayloadAction<string>) => ({
      ...state,
      userCookieLoading: false,
      error: action.payload,
      success: false
    })
  }
})

export const { userCookieFailure, userCookieStart, userCookieSuccess } = userCookieSlice.actions

export default userCookieSlice.reducer
