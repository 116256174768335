import { styled } from '@mui/system'
import { Grid } from '@mui/material'

export const ActivationStyles = styled(Grid)(({ theme }) => ({
  ' .activation-label': {
    color: '#FFF',
    marginBlock: 20,
    marginTop: 75,
    fontSize: 18
  },
  ' .ott-wrapper': {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    marginBlock: 30,
    ' .opt-input': {
      width: '45px !important',
      textAlign: 'center',
      border: 'none',
      height: 70,
      margin: 3,
      backgroundColor: '#2d2d2d',
      outline: 'none',
      color: 'rgb(255, 255, 255)',
      borderRadius: 5,
      fontSize: 35,
      textTransform: 'uppercase',
      textShadow: '0 1px #000',
      '&.error': {
        boxShadow: `0 0 1px 0.5px inset ${theme.palette.primary.main}`
      }
    },
    ' .separator': {
      color: '#FFF',
      fontSize: 30,
      margin: 5,
      display: 'none',
      '&:nth-child(8)': {
        display: 'inline-block'
      }
    }
  },
  ' .error-otp': {
    color: theme.palette.primary.main,
    fontSize: 30,
    margin: '10px 0'
  },

  [theme.breakpoints.down('sm')]: {
    transform: 'scale(.8)',
    ' .opt-input': {
      padding: 0
    },
    ' .activation-label': {
      fontSize: '30px'
    },
    '.activation-button': {
      transform: 'scale(1.3) translateY(10px)'
    }
  }
}))
