import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '../../index'

import { ConfigState, User } from './types'

// Select the config slice state
const selectConfig = (state: RootState) => state.config

// Select specific properties from the config state
// With this approach we just get the config instead of doing state.config.displayLogIn
export const displayLogInSelector = createSelector(
  selectConfig,
  (config: ConfigState): boolean => config.displayLogIn
)

export const maintenanceMessageSelector = createSelector(
  selectConfig,
  (config: ConfigState): string | null => config.maintenanceMessage
)

export const languageSelector = createSelector(
  selectConfig,
  (config: ConfigState): string => config.language
)

export const userSelector = createSelector(
  selectConfig,
  (config: ConfigState): User | null => config.user
)

export const userInfoFetchingSelector = createSelector(
  selectConfig,
  (config: ConfigState): boolean => config.userInfoFetching
)

export const tokenSelector = createSelector(
  selectConfig,
  (config: ConfigState): string | null => config.token
)

export const marketingSelector = createSelector(
  selectConfig,
  (config: ConfigState): boolean => config.viewMarketing
)
