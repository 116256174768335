import { BrowserRouter as Router } from 'react-router-dom'
import { Provider as ReduxProvider } from 'react-redux'
import { HelmetProvider } from 'react-helmet-async'
import TagManager from 'react-gtm-module'
import React, { FC } from 'react'
import { ThemeProvider } from '@mui/material'
import { initialize } from '@brightcove/studio-components'

import { getStorage } from './utils/localStorageSupport'
import { theme } from './styles/customMUITheme'
import { store } from './store'
import HelmetComponent from './components/Common/HelmetComponent/HelmetComponent'
import AppBody from './components/AppBody/AppBody'

const storage = getStorage()
const uid = storage.getItem('uid')

initialize()

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GA_ID || '',
  dataLayer: {
    user_id: uid || ''
  }
}

tagManagerArgs && TagManager.initialize(tagManagerArgs)

const App: FC = () => (
  <Router>
    <ReduxProvider store={store}>
      <ThemeProvider theme={theme}>
        <HelmetProvider>
          <HelmetComponent />
          <AppBody />
        </HelmetProvider>
      </ThemeProvider>
    </ReduxProvider>
  </Router>
)

export default App
