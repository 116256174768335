import { styled } from '@mui/system'
import { Dialog } from '@mui/material'

export const DialogStyle = styled(Dialog)(({ theme }) => ({
  ' .MuiPaper-root': {
    minWidth: 500,
    textAlign: 'center',
    padding: 10,
    height: 150,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  ' .MuiTypography-root': {
    fontWeight: 'bold'
  },
  ' .MuiButtonBase-root': {
    background: theme.palette.colors.primary,
    width: 200,
    height: 50,
    marginTop: 20
  },
  [theme.breakpoints.down('sm')]: {
    minWidth: 350,
    padding: '30px 10px'
  }
}))
