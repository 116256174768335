import { useTranslation } from 'react-i18next'
import React, { FC } from 'react'
import { Button, Typography } from '@mui/material'

import { DialogStyle } from './DialogMaxChars.style'

interface DialogMaxCharsProps {
  isDialogOpen: boolean
  onDialogClose?: () => void
}

const DialogMaxChars: FC<DialogMaxCharsProps> = ({ isDialogOpen, onDialogClose }) => {
  const { t } = useTranslation()

  return (
    <DialogStyle className='max-char-dialog' autoFocus open={isDialogOpen}>
      <Typography variant='body1'>{t('profile.max_name_characters')}</Typography>
      <Button onClick={onDialogClose}>{t('profile.dismiss')}</Button>
    </DialogStyle>
  )
}

export default DialogMaxChars
