import { styled } from '@mui/system'

export const LogoStyles = styled('div')(() => ({
  color: '#FFF',
  margin: '70px 0',
  cursor: 'pointer',
  '> img': {
    width: 250
  },
  '> .logo_label': {
    marginTop: -30
  }
}))
