import { useDraggable } from 'react-use-draggable-scroll'
import React, { FC, MutableRefObject, useEffect, useRef } from 'react'

import ThumbnailTile from '../Tile/ThumbnailTile'
import { CarouselSection } from '../../types/section'
import './Carousel.scss'
import { useLazyGetDynamicStringsQuery } from '../../store/services/dynamicStrings/dynamicStringsApi'
import { googlePublishBannerAds } from '../../hooks/useGooglePublisherTag'

interface CarouselProps {
  section: CarouselSection
  page?: string
}

const Carousel: FC<CarouselProps> = ({ section, page }) => {
  const [getAdSlotId]: any = useLazyGetDynamicStringsQuery()
  const scrollRef = useRef() as MutableRefObject<any>
  const { events } = useDraggable(scrollRef)
  const getAdSlotIdFromMW = async () => {
    const response = await getAdSlotId({
      q: 'web_banner_ad_slot_id'
    })
    return response
  }

  useEffect(() => {
    const el: any = scrollRef.current
    if (el) {
      const onWheel = (e) => {
        if (e.deltaY === 0 || Math.abs(e.deltaY) > Math.abs(e.deltaX)) return
        e.preventDefault()
      }
      el.addEventListener('wheel', onWheel)
      return () => el.removeEventListener('wheel', onWheel)
    }
  }, [])

  useEffect(() => {
    const adSlotId = async () => {
      const response = await getAdSlotIdFromMW()
      const adIds = document.getElementsByClassName('banner_div_carousel')
      if (adIds != null && adIds.length > 0) {
        const ids: string[] = []
        for (let i = 0; i < adIds.length; i++) {
          const adDiv = adIds[i]
          ids.push(adDiv.id)
        }
        if (response && response.data && response.data.results && response.data.results.items) {
          if (response.data.results.items.length > 0) {
            googlePublishBannerAds(response.data.results.items[0].value, ids)
          }
        }
      }
    }
    adSlotId()
  }, [section])

  return (
    <div className='Carousel-main-content'>
      {section.name && <h2 className='Carousel-header'>{section.name}</h2>}
      <div className='Carousel-content'>
        <ul ref={scrollRef} {...events}>
          {section.items?.map((item, index) => (
            <li key={index}>
              <ThumbnailTile
                key={index}
                item={item}
                showTrailer={true}
                page={page || 'homepage'}
                onMouseUpIndex={() => index}
                collectionPosition={section.position}
                section={section}
              />
            </li>
          ))}
        </ul>
      </div>
      {section.showAds && <div className='banner_div_carousel' id={'bannerDiv' + section.id}></div>}
    </div>
  )
}

export default Carousel
