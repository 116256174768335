import { useNavigate } from 'react-router'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import React, { FC } from 'react'
import { Typography } from '@mui/material'
import DoneRoundedIcon from '@mui/icons-material/DoneRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'

import ActivationButton from '../../Components/ActivationButton/ActivationButton'
import { useLazyGetUserProfileQuery } from '../../../../store/services/user/userProfileApi'
import { RootState } from '../../../../store'

import { ActivationConfirmationStyles } from './ActivationConfirmation.style'

const ActivationConfirmation: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { isSuccess } = useSelector((state: RootState) => state.activationApiReducer)
  const [getProfileData] = useLazyGetUserProfileQuery()

  // Activation button click listener
  const onActivationClickListener = async () => {
    const profileResponse = await getProfileData()
    const entitlement = profileResponse?.['data']?.entitlement
    if (entitlement?.subscription_status === 'inactive') {
      navigate('/oned/premium')
    } else {
      navigate(-1)
    }
  }

  return (
    <ActivationConfirmationStyles>
      <div className='confirmation-wrapper'>
        <div className={`icon-wrapper ${isSuccess ? 'success' : 'failed'} `}>
          {isSuccess ? (
            <DoneRoundedIcon className='confirmation-icon' />
          ) : (
            <CloseRoundedIcon className='confirmation-icon' />
          )}
        </div>
        <div className='confirmation-message'>
          {isSuccess ? (
            <>
              <Typography variant='h4'>{t('activation.success_label')}</Typography>
              <Typography variant='body1'>{t('activation.success_text')}</Typography>
            </>
          ) : (
            <>
              <Typography variant='h4'>{t('activation.failed_label')}</Typography>
              <Typography variant='body1'>{t('activation.failed_text')}</Typography>
              <ActivationButton
                label={t('activation.ok')}
                type='button'
                onActivationClick={onActivationClickListener}
              />
            </>
          )}
        </div>
      </div>
    </ActivationConfirmationStyles>
  )
}

export default ActivationConfirmation
