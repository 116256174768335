import { Route, Routes } from 'react-router'
import React, { FC } from 'react'

import { TvActivationStyle } from './TvActivation.style'
import ActivationConfirmation from './Pages/ActivationConfirmation/ActivationConfirmation'
import Activation from './Pages/Activation/Activation'
import ActivationLogo from './Components/ActivationLogo/Logo'

const TvActivation: FC = () => (
  <TvActivationStyle className='tv-activation'>
    <ActivationLogo />
    <Routes>
      <Route path='/' element={<Activation />} />
      <Route path='/confirm' element={<ActivationConfirmation />} />
    </Routes>
  </TvActivationStyle>
)

export default TvActivation
