import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { getApiHeader } from '../../../utils/utils'
import { SectionData } from '../../../types/page'
import config from '../../../config'

interface Args {
  id?: string
  params?: Record<string, string>
}

export const pageApi = createApi({
  reducerPath: 'page',
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.apiUrl}`,
    prepareHeaders: (headers, { getState }) => getApiHeader(headers, { getState })
  }),
  endpoints: (builder) => ({
    getHomePage: builder.query<SectionData, Args>({
      query: () => ({
        url: `/home`,
        method: 'GET'
      })
    }),
    getVideoDetailPage: builder.query<SectionData, { id: string | undefined }>({
      query: (args) => {
        const { id } = args
        ;('GET')
        return {
          url: `/detail/${id}`
        }
      }
    }),
    getSearchPage: builder.query<SectionData, Args>({
      query: () => ({
        url: `/search`,
        method: 'GET'
      })
    })
  })
})

export const { useGetHomePageQuery, useGetVideoDetailPageQuery, useLazyGetSearchPageQuery } =
  pageApi
