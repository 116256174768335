import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface MyListState {
  myListLoading: string | undefined
  error: string | null
  success: boolean
}

const initialState: MyListState = {
  myListLoading: '',
  error: null,
  success: false
}

const myListSlice = createSlice({
  name: 'myList',
  initialState,
  reducers: {
    myListStart: (state, action: PayloadAction<string | undefined>) => ({
      ...state,
      myListLoading: action.payload,
      error: null,
      success: false
    }),
    myListSuccess: (state) => ({
      ...state,
      myListLoading: '',
      error: null,
      success: true
    }),
    myListFailure: (state, action: PayloadAction<string>) => ({
      ...state,
      myListLoading: '',
      error: action.payload,
      success: false
    })
  }
})

export const { myListFailure, myListStart, myListSuccess } = myListSlice.actions

export default myListSlice.reducer
