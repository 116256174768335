import { z } from 'zod'
import { useDispatch, useSelector } from 'react-redux'
import { Trans, useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import React, { FC, useEffect, useState } from 'react'
import { styled } from '@mui/system'
import CircularProgress from '@mui/material/CircularProgress'
import { Button, Grid, IconButton, InputAdornment } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { zodResolver } from '@hookform/resolvers/zod'
import { Checkbox, Form } from '@brightcove/studio-components'

import ErrorDialog from '../Login/Components/ErrorDialog'
import { LinkText } from '../LinkText/LinkText'
import InputField from '../Common/Input/Input'
import SignUpGTMEvents from '../Common/GTM/SignUpGTMEvents'
import { setEmailOrPhone, setPasswordOrOtp, setStep } from '../../store/slices/signupSlice'
import { RootState } from '../../store'
import { useCheckEmailExists } from '../../hooks/UIHooks'

import { FormFieldsStepOne } from './types'

import './Signup.scss'

const registerFormSchema = ({ ...rest }) =>
  z.object({
    emailOrPhone: z.string().email(rest.emailError),
    passwordOrOtp: z.string().min(8, rest.passwordError)
  })

interface errorState {
  isError: boolean
  message?: string
  errorMessage?: string
}

const StepOneStyle = styled(Grid)(({ theme }) => ({
  ' .MuiInputBase-root': {
    width: 405
  },
  [theme.breakpoints.down('sm')]: {
    ' .MuiInputBase-root': {
      width: 348
    }
  }
}))

const StepOne: FC = () => {
  const [showPassword, setShowPassword] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [errorDialog, setErrorDialog] = useState<errorState>({ isError: false, message: '' })
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean | undefined>(false)

  const { t } = useTranslation()
  const emailOrPhoneVal = useSelector((state: RootState) => state.signup.emailOrPhone)
  const passwordOrOtpVal = useSelector((state: RootState) => state.signup.passwordOrOtp)
  const [isGTMError, setIsGTMError] = useState<boolean>(false)
  const [GTMErrorMessage, setGTMErrorMessage] = useState<any>()
  const [isGTMSignUpConfirmStep1, setIsGTMSignUpConfirmStep1] = useState<boolean>(false)

  useState<boolean>(false)

  const validationSchema = registerFormSchema({
    emailError: t('signup.enter_valid_email_phone'),
    passwordError: t('signup.passwords_8_characters'),
    isSubmitted
  })

  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
    watch,
    clearErrors,
    setValue
  } = useForm<FormFieldsStepOne>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit'
  })
  const dispatch = useDispatch()
  const emailOrPhone = watch('emailOrPhone', '')
  const passwordOrOtp = watch('passwordOrOtp', '')

  useEffect(() => {
    document.body.className = 'body-white'
    return () => {
      document.body.className = ''
    }
  })

  // Event handler for checkbox change
  const handleCheckboxChange = (event): void => setIsChecked(event)

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const { checkEmail } = useCheckEmailExists()

  const onSubmit = async (data: FormFieldsStepOne) => {
    setIsButtonDisabled(true)
    const { emailOrPhone, passwordOrOtp } = data

    const isEmailExist = await checkEmail(emailOrPhone)
    if (isEmailExist) {
      setIsGTMError(true)
      setGTMErrorMessage(
        isEmailExist
          ? { emailOrPhone: { message: t('signup.email_exist') as string } }
          : { emailOrPhone: { message: t('signup.phone_exist') as string } }
      )
      setError('emailOrPhone', {
        type: 'manual',
        message: isEmailExist
          ? (t('signup.email_exist') as string)
          : (t('signup.phone_exist') as string)
      })
      setIsButtonDisabled(false)
      setIsSubmitted(false)
      return
    } else {
      setIsGTMError(false)
    }

    try {
      dispatch(setEmailOrPhone(emailOrPhone))
      dispatch(setPasswordOrOtp(passwordOrOtp))
      setIsGTMSignUpConfirmStep1(true)
      setIsGTMSignUpConfirmStep1(false)
      setTimeout(() => {
        dispatch(setStep(2))
      }, 0)
    } catch (error: any) {
      setIsButtonDisabled(false)
      if (error?.code === 'auth/too-many-requests') {
        setErrorDialog({
          isError: true,
          message: error?.message
        })
      }
      if (error?.message === 'INVALID_IDENTIFIER') {
        setErrorDialog({
          isError: false
        })
      }
    }
  }

  const isSubmitDisabled = emailOrPhone.length && passwordOrOtp.length && isChecked

  useEffect(() => {
    window.scrollTo(0, 0)
    if (JSON.stringify(!errors) !== '{}') {
      setIsGTMError(true)
      setGTMErrorMessage(errors)
    }
  }, [errors, setIsGTMError, setGTMErrorMessage])

  return (
    <div className='signup'>
      <SignUpGTMEvents
        isGTMError={isGTMError}
        errorMessage={GTMErrorMessage}
        isSignUpConfirmStep1={isGTMSignUpConfirmStep1}
      />

      <h1 className='info-header'>{t('signup.account_information') || ''}</h1>

      <Form onSubmit={handleSubmit(onSubmit)}>
        {/* <Flex flexDirection='column'> */}
        <StepOneStyle container spacing={3} justifyContent='flex-end' className='signup-stepOne'>
          <Grid item xs={12}>
            <InputField
              label={t('signup.email_tel') || ''}
              name='emailOrPhone'
              register={register}
              errors={errors}
              defaultValue={emailOrPhoneVal || ''}
              clearErrors={clearErrors}
              setValue={setValue}
            />
          </Grid>
          <Grid item xs={12}>
            <InputField
              label={t('signup.create_password') || ''}
              name='passwordOrOtp'
              type={showPassword ? 'text' : 'password'}
              register={register}
              errors={errors}
              defaultValue={passwordOrOtpVal || ''}
              clearErrors={clearErrors}
              setValue={setValue}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    edge='end'
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Checkbox
              className='signup-terms-conditions'
              key='terms_and_conditions'
              id='terms_and_conditions'
              onChange={handleCheckboxChange}
              checked={isChecked}
            >
              <Trans
                i18nKey='login.policy_links'
                components={{
                  link_terms: (
                    <LinkText
                      defaultText='ข้อกำหนดและเงื่อนไข'
                      linkKey='terms'
                      textKey='legal.terms'
                    />
                  ),
                  link_privacy: (
                    <LinkText
                      defaultText='นโยบายความเป็นส่วนตัว'
                      linkKey='privacy'
                      textKey='legal.privacy'
                    />
                  )
                }}
              />
            </Checkbox>
          </Grid>
          <Grid item xs={12} container justifyContent='space-between' spacing={2}>
            <Grid item xs={6} />
            <Grid item xs={6}>
              <Button
                className={`button_font_family signup-button ${
                  !isSubmitDisabled ? 'disabled' : ''
                }`}
                type='submit'
                endIcon={isButtonDisabled && <CircularProgress sx={{ color: '#fff' }} size={20} />}
                disabled={!isSubmitDisabled}
              >
                {t('signup.next') || ''}
              </Button>
            </Grid>
          </Grid>
        </StepOneStyle>
      </Form>
      {errorDialog.isError && (
        <ErrorDialog
          message={errorDialog.message}
          setClosed={() => {
            setIsButtonDisabled(false)
            setErrorDialog({ isError: false, message: '' })
          }}
        />
      )}
    </div>
  )
}

export default StepOne
