/* eslint-disable @typescript-eslint/no-unused-vars */
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { FieldErrors, FieldValues, UseFormRegister } from 'react-hook-form'
import React, { useEffect, useState } from 'react'
import { format, isValid } from 'date-fns'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers'
import { createTheme, styled } from '@mui/system'
import { Grid, IconButton, useMediaQuery } from '@mui/material'
import { CalendarTodayOutlined } from '@mui/icons-material'
import { Label } from '@brightcove/studio-components'

import DateDropdown from '../../SignUp/Components/DateDropdown/DateDropdown'
import InputField from '../../Common/Input/Input'
import GenderGroup from '../../Common/GenderGroup/GenderGroup'
import { setDayDisable, setLastDatOfMonth } from '../../../store/slices/userProfileSlice'
import { RootState } from '../../../store'
import { auth } from '../../../firebase'
import { YEAR_OF_INTERVAL } from '../../../constants'

interface ProfileFieldItemsI {
  id: number
  label?: JSX.Element
  value?: string | undefined
  component?: JSX.Element
  nonEditable?: JSX.Element
  editable?: JSX.Element
}

interface ProfileFieldItemsProps<T extends FieldValues> {
  data:
    | {
        firstName: string
        lastName: string
        email: string
        phone: string
        date_of_birth: string
        gender: string
      }
    | any
  register: UseFormRegister<T>
  errors: FieldErrors<T>
  formData: object
  setFormData: any
  setCheckDisabled: any
  onSetInputs?: any
  clearErrors: any
}

const InputFieldStyles: any = styled(InputField)(({ theme }) => ({
  backgroundColor: '#ffffff',
  flex: 1,
  '&.input-disabled': {
    pointerEvents: 'none',
    color: 'rgba(0, 0, 0, 0.38)'
  },
  [theme.breakpoints.down('sm')]: {
    width: 220
  }
}))

function ProfileFieldItems<T extends FieldValues>({
  data,
  register,
  errors,
  formData,
  setFormData,
  setCheckDisabled,
  onSetInputs,
  clearErrors
}: ProfileFieldItemsProps<T>): ProfileFieldItemsI[] {
  const { t } = useTranslation()
  const theme = createTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const dispatch = useDispatch()
  const { isMonthChange } = useSelector((state: RootState) => state.userProfile)

  const [isEmailDisabled, setIsEmailDisabled] = useState(false)
  const [isPasswordDisabled, setIsPasswordDisabled] = useState(false)
  const [dateOfBirth, setDateOfBirth] = useState<Date | null>(null)
  const [inputValues, setInputValues] = useState<object>()
  const [isInvalidDate, setIsInvalidDate] = useState<boolean>(false)

  const onGenderChange = (gender) => {
    setFormData({
      ...formData,
      gender: gender.toLowerCase()
    })
    setInputValues({
      ...inputValues,
      gender
    })
  }
  const onDateChange = (day: number | null, month: number | null, year: number | null) => {
    if (day && month && year) {
      const dob: any = new Date(year, month - 1, day)
      setDateOfBirth(dob)

      const lastDayOfMonth: any = new Date(year, month, 0).getDate()
      dispatch(setLastDatOfMonth(lastDayOfMonth))
      if (isMonthChange && day === 31) {
        if (lastDayOfMonth !== day) {
          dispatch(setDayDisable(true))
          dob.setDate(0)
        } else {
          dispatch(setDayDisable(false))
          dob.setDate(day)
        }
      }

      setFormData({
        ...formData,
        date_of_birth: format(dob, 'yyyy/MM/dd')
      })
      setInputValues({
        ...inputValues,
        date_of_birth: dob
      })
    } else {
      setDateOfBirth(null)
    }
  }

  useEffect(() => {
    const currentUser = auth.onAuthStateChanged((user) => {
      if (user) {
        const initialProviderData = user.providerData.slice(-1)[0]
        if (initialProviderData.providerId === 'password') {
          setIsEmailDisabled(true)
          setCheckDisabled('email')
        } else if (initialProviderData.providerId === 'phone') {
          setIsPasswordDisabled(true)
          setCheckDisabled('phone')
        }
      } else {
        setIsEmailDisabled(false)
        setIsPasswordDisabled(false)
        setCheckDisabled('')
      }
    })
    return () => currentUser()
  }, [])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }))
  }
  useEffect(() => {
    onSetInputs(inputValues)
  }, [inputValues, onSetInputs])

  useEffect(() => {
    const isValidDate = isValid(new Date(data?.date_of_birth))
    if (isValidDate) {
      setIsInvalidDate(true)
    } else {
      setIsInvalidDate(false)
    }
  }, [data])

  return [
    {
      id: 1,
      label: <Label className='profile-detail-label'>{t('profile.first_name') || ''}</Label>,
      nonEditable: <p className='label-inactive'>{data?.firstName}</p>,
      editable: (
        <InputFieldStyles
          defaultValue={data ? data?.firstName : ''}
          label={isMobile ? t('signup.first_name') : ''}
          name='firstName'
          register={register}
          errors={errors}
          className='profile'
          onInput={(e) => handleInputChange(e)}
          onChange={(e) => {
            setFormData({
              ...formData,
              firstName: e.target.value
            })
          }}
        />
      )
    },
    {
      id: 2,
      label: <Label className='profile-detail-label'>{t('profile.last_name') || ''}</Label>,
      nonEditable: <p className='label-inactive'>{data?.lastName}</p>,
      editable: (
        <InputFieldStyles
          defaultValue={data?.lastName}
          label={isMobile ? t('signup.last_name') : ''}
          name='lastName'
          register={register}
          errors={errors}
          className='profile'
          onInput={(e) => handleInputChange(e)}
          onChange={(e) =>
            setFormData({
              ...formData,
              lastName: e.target.value
            })
          }
        />
      )
    },
    {
      id: 3,
      label: <Label className='profile-detail-label'>{t('profile.email') || ''}</Label>,
      nonEditable: <p className='label-inactive'>{data?.email}</p>,
      editable: (
        <InputFieldStyles
          defaultValue={data?.email}
          label={isMobile ? t('signup.email') : ''}
          name='email'
          register={register}
          errors={errors}
          className={`${isEmailDisabled ? 'input-disabled' : ''}`}
          readOnly={isEmailDisabled}
          onInput={(e) => {
            clearErrors()
            handleInputChange(e)
          }}
          onChange={(e) =>
            setFormData({
              ...formData,
              email: isEmailDisabled ? data?.email : e.target.value
            })
          }
        />
      )
    },
    {
      id: 4,
      label: <Label className='profile-detail-label'>{t('profile.tel') || ''}</Label>,
      nonEditable: <p className='label-inactive'>{data?.phone}</p>,
      editable: (
        <InputFieldStyles
          defaultValue={data?.phone}
          label={isMobile ? t('signup.tel') : ''}
          name='phone'
          register={register}
          errors={errors}
          className={`${isPasswordDisabled ? 'input-disabled' : ''}`}
          readOnly={isPasswordDisabled}
          onInput={(e) => {
            clearErrors()
            handleInputChange(e)
          }}
          onChange={(e) =>
            setFormData({
              ...formData,
              phone: e.target.value || null
            })
          }
        />
      )
    },
    {
      id: 5,
      label: <Label className='profile-detail-label'>{t('profile.birthdate') || ''}</Label>,
      nonEditable: (
        <>
          <p className='label-inactive'>
            {isInvalidDate && data?.date_of_birth
              ? format(new Date(data?.date_of_birth), 'dd/MM/yyyy')
              : data?.date_of_birth}
          </p>
        </>
      ),
      editable: (
        <Grid container alignItems='center' justifyContent='space-between'>
          <Grid item xs={11} sm={12}>
            <DateDropdown
              onDateChange={onDateChange}
              selectedCalendar={
                new Date(data?.date_of_birth)
                // !dateOfBirth
                //   ? data?.date_of_birth
                //     ? new Date(data?.date_of_birth)
                //     : null
                //   : dateOfBirth
              }
              isLabel={isMobile}
            />
          </Grid>
          {isMobile && (
            <div className='datePicker-icon'>
              <Grid item>
                <CalendarTodayOutlined
                  style={{
                    fill: '#f20b7e',
                    transform: 'scale(1.2, 1) translate(5px, 20px)'
                  }}
                />
              </Grid>
            </div>
          )}
        </Grid>
      )
    },
    {
      id: 6,
      label: <Label className='profile-detail-label'>{t('profile.gender') || ''}</Label>,
      nonEditable: <p className='label-inactive'>{data?.gender}</p>,
      editable: (
        <Grid
          container
          alignItems='center'
          justifyContent='space-between'
          style={{ margin: '20px 0' }}
        >
          <Grid item xs={11} sm={12}>
            <GenderGroup
              onGenderChange={onGenderChange}
              initialValue={data ? data?.gender : ''}
              // genderValue={data ? data?.gender : ''}
              isLabel={isMobile}
            />
          </Grid>
        </Grid>
      )
    }
  ]
}

export default ProfileFieldItems
