import { styled } from '@mui/system'

export const EntitlementStyles = styled('div')(({ theme }) => ({
  height: '100%',
  marginBottom: '100px',
  minHeight: '50vh',
  overflowY: 'scroll',
  transform: 'translateY(100px)',
  width: '100%',
  [theme.breakpoints.down('sm')]: {}
}))
