import { useNavigate, useLocation } from 'react-router'
import React, { FC, useEffect } from 'react'

import { useLazyGetUserProfileQuery } from '../../store/services/user/userProfileApi'

const { REACT_APP_AIS_REDIRECT_URL } = process.env

const AISActivation: FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [getProfileData] = useLazyGetUserProfileQuery()

  // Check if the user is logged in to redirect them to the vendor portal.
  useEffect(() => {
    getProfileData()
      .then((response) => {
        if (response.status === 'rejected') {
          throw new Error('You are not authorized to perform this action')
        } else {
          window.location.replace(
            `${REACT_APP_AIS_REDIRECT_URL}?spAccountId=${response.data?.id}&packageId=${
              location.state?.packageId ?? ''
            }`
          )
        }
      })
      .catch(() => {
        navigate('/login', { state: { type: 'ACTIVATE', packageId: '' } })
      })
  }, [])

  return <></>
}

export default AISActivation
