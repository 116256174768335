import { useTranslation } from 'react-i18next'
import TagManager from 'react-gtm-module'
import React, { FC } from 'react'
import { styled } from '@mui/system'
import { Button, List, ListItem, Popover } from '@mui/material'
import ShareRoundedIcon from '@mui/icons-material/ShareRounded'
import InsertLinkIcon from '@mui/icons-material/InsertLink'

import { getItemDetailScreen, objectToQueryString } from '../../utils/utils'
import { VideoContentItem } from '../../types/item'
import config from '../../config'

import './Share.scss'

import FbShare from './FbShare'

interface ShareProps {
  item?: VideoContentItem
  checkShare?: boolean
  page?: string
}

const PopoverStyle = styled(Popover)(() => ({
  '& .MuiPopover-paper': {
    background: '#101010',
    padding: 0,
    margin: 0,
    width: 241,
    '& ul': {
      height: 145,
      display: 'flex',
      padding: 0,
      margin: 0,
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'center',
      '& li': {
        padding: 0,
        margin: 0,
        '& button': {
          width: '100%',
          borderRadius: 0,
          display: 'flex',
          justifyContent: 'start',
          fontSize: '1rem',
          margin: 'auto 10px'
        }
      }
    }
  }
}))

const ButtonStyle = styled(Button)(() => ({
  background: 'rgba(0,0,0,0)',
  display: 'flex',
  fontSize: 12,
  flexDirection: 'column',
  alignItems: 'center',
  color: '#aaa',
  ':hover': {
    background: 'rgba(0,0,0,0)',
    transition: 'none',
    boxShadow: 'none'
  },
  svg: {
    color: '#fff',
    fontSize: '25px !important',
    marginRight: '-8px !important'
  },
  transition: 'none',
  boxShadow: 'none'
}))

const ShareSocial: FC<ShareProps> = ({ item, checkShare, page }) => {
  const { t } = useTranslation()

  const getShareDetails = () => {
    let videoDetails = {}
    let shareData = ''
    const id = getItemDetailScreen(item!)
    if (
      id?.series_id !== '' &&
      id?.['seriesTrailerId'] !== '' &&
      item?.['collection_type'] === 'Series'
    ) {
      videoDetails = {
        seriesName: item?.name,
        next_up_id: '',
        seriesDescription: item?.description ? item?.description : '',
        displayName: id?.display_name ? id?.display_name : ''
      }
      shareData = `/video/${id?.video_id}?trailer=${id?.[`seriesTrailerId`]}&data=${btoa(
        objectToQueryString(videoDetails)
      )}`
    } else if (
      id?.series_id !== '' &&
      id?.['seasonTrailerId'] !== '' &&
      item?.['collection_type'] === 'Season'
    ) {
      videoDetails = {
        seriesName: item?.name,
        next_up_id: item?.id,
        seriesDescription: item?.description ? item?.description : '',
        displayName: id?.display_name ? id?.display_name : ''
      }
      shareData = `/video/${id?.video_id}?trailer=${id?.[`seasonTrailerId`]}&data=${btoa(
        objectToQueryString(videoDetails)
      )}`
    } else {
      videoDetails = {
        seriesName: '',
        next_up_id: '',
        seriesDescription: '',
        displayName: id?.display_name ? id?.display_name : ''
      }
      shareData = `/video/${id?.video_id}?data=${btoa(objectToQueryString(videoDetails))}`
    }
    return shareData
  }

  const copyToClip = async () => {
    const shareData = getShareDetails()
    const isOriginal = item?.custom_fields?.['original_page_navigation'] === 'true'

    let link
    if (item?.asset_id) {
      link = `${config.deepLink}?link=${config.appUrl}/live-tv`
    } else if (item?.id) {
      const itemLink = isOriginal ? `/oned/original?id=${item.id}` : shareData
      link = `${config.deepLink}?link=${config.appUrl}${itemLink}`
    } else {
      link = `${config.deepLink}?link=${config.appUrl}${shareData}`
    }

    await navigator.clipboard.writeText(link)
  }

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    sendGTMEvents()
    setAnchorEl(event.currentTarget)
  }

  const sendGTMEvents = () => {
    if (page === 'oned_original') {
      const tagManagerArgs = {
        dataLayer: {
          event: 'original_content_share',
          event_category: 'original_content_share',
          event_action: 'content_share',
          event_label: 'share_button'
        }
      }
      TagManager.dataLayer(tagManagerArgs)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const sendEvent = () => {
    if (checkShare) {
      if (page == 'live_player') {
        const tagManagerArgs = {
          dataLayer: {
            event: 'content_share',
            event_category: 'video',
            event_action: 'content_share',
            event_label: 'share_button',
            content_name: item?.name,
            content_type: item?.content_type,
            content_genre: item?.custom_fields?.genre || '',
            maturity_rating: item?.custom_fields?.rating || '',
            studio: item?.custom_fields?.studio || '',
            main_actor: item?.custom_fields?.cast || '',
            share_channel: 'facebook'
          }
        }
        TagManager.dataLayer(tagManagerArgs)
      } else if (page == 'vod_player') {
        const tagManagerArgs = {
          dataLayer: {
            event: 'content_share',
            event_category: 'video',
            event_action: 'content_share',
            event_label: 'share_button',
            content_name: item?.name,
            content_type: item?.content_type,
            content_genre: item?.custom_fields?.genre || '',
            maturity_rating: item?.custom_fields?.rating || '',
            studio: item?.custom_fields?.studio || '',
            main_actor: item?.custom_fields?.cast || '',
            share_channel: 'facebook'
          }
        }
        TagManager.dataLayer(tagManagerArgs)
      }
    }
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <div>
      <div
        className={
          item?.video_id
            ? `share-label-icon-text ${item?.video_id}`
            : `share-label-icon-text ${item?.asset_id}`
        }
      >
        <ButtonStyle
          aria-describedby={id}
          variant='contained'
          onClick={handleClick}
          startIcon={<ShareRoundedIcon />}
        >
          <div className='share-label-icon-text'>{t('content-detail.share')}</div>
        </ButtonStyle>
        <PopoverStyle
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
        >
          <List>
            <ListItem>
              <Button
                startIcon={
                  <FbShare
                    onClickFb={() => {
                      handleClose()
                    }}
                    item={item}
                  />
                }
                onClick={() => {
                  sendEvent()
                  setAnchorEl(null)
                }}
              ></Button>
            </ListItem>
            <ListItem
              onClick={() => {
                copyToClip()
                handleClose()
              }}
            >
              <Button startIcon={<InsertLinkIcon sx={{ rotate: '-45deg', marginLeft: '10px' }} />}>
                <div className='share-label-text'>{t('content-detail.copy_link')}</div>
              </Button>
            </ListItem>
          </List>
        </PopoverStyle>
      </div>
    </div>
  )
}

export default ShareSocial
