import { useTranslation } from 'react-i18next'
import React, { FC } from 'react'
import { Button, Typography } from '@mui/material'

import { DialogStyle } from './DialogSubscribe.style'

interface DialogSubscribeProps {
  isDialogOpen: boolean
  message: string
  availableAfter?: string | number | null | undefined
  onDialogClose?: () => void
  onSubscribeNow?: () => void
}

const DialogSubscribe: FC<DialogSubscribeProps> = ({
  isDialogOpen,
  message,
  availableAfter,
  onDialogClose,
  onSubscribeNow
}) => {
  const { t } = useTranslation()

  return (
    <DialogStyle className='subscribe-dialog' autoFocus open={isDialogOpen}>
      <Typography className='subscribe-title' variant='body1'>
        {t('dialog.title')}
      </Typography>
      <Typography className='dialog-message' variant='body2'>
        {availableAfter
          ? t(message)?.replace('<AVAILABLE_AFTER>', availableAfter?.toString())
          : t(message)}
      </Typography>

      <div className='button-container'>
        <Button className='subscribe-cancel-button' onClick={onDialogClose}>
          {t('dialog.later')}
        </Button>
        <Button className='subscribe-upgrade-button' onClick={onSubscribeNow}>
          {t('dialog.subscribe_now')}
        </Button>
      </div>
    </DialogStyle>
  )
}

export default DialogSubscribe
