import React, { FC } from 'react'
import classnames from 'classnames'

import { SVGImage } from '../../assets/images'
import './LinkSocial.scss'

interface LinkSocialProps {
  to: string
  title: string
  type: string
  className?: string
}

export const LinkSocial: FC<LinkSocialProps> = ({ to, title, type, className }) => (
  <a
    href={to || '#'}
    target='_blank'
    title={title || ''}
    rel='noreferrer'
    className={classnames('link-social', className)}
  >
    {type.toLowerCase() === 'facebook' && <img src={SVGImage.FacebookLogo} alt='Facebook' />}
    {type.toLowerCase() === 'twitter' && (
      <img style={{ paddingTop: '10px' }} src={SVGImage.TwitterIcon} alt='Twitter' />
    )}
    {type.toLowerCase() === 'instagram' && <img src={SVGImage.InstagramLogo} alt='Instagram' />}
    {type.toLowerCase() === 'youtube' && (
      <img
        src={SVGImage.YoutubeLogo}
        alt='Youtube'
        style={{ marginTop: '12px', paddingLeft: '7px' }}
      />
    )}
    {type.toLowerCase() === 'tiktok' && <img src={SVGImage.TikTokIcon} alt='TikTok' />}
  </a>
)
