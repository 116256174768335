import { useTranslation } from 'react-i18next'
import React, { FC, useState } from 'react'
import { Grid } from '@mui/material'

import { ButtonGroupElStyle, ButtonGroupStyle } from './GenderGroup.style'

interface GenderButtonGroupProps {
  onGenderChange?: (value: Gender) => void
  initialValue?: Gender
  selected?: string
  register?: () => void
  isLabel?: boolean
}

enum Gender {
  Male = 'Male',
  Female = 'Female',
  Others = 'Others'
}

const GenderGroup: FC<GenderButtonGroupProps> = ({
  onGenderChange,
  initialValue,
  isLabel = false
  // selected,
}) => {
  const { t } = useTranslation()
  const [selectedGender, setSelectedGender] = useState<Gender | null>(
    (initialValue as Gender) || null
  )

  const onGenderClick = (gender: Gender | null) => {
    const buttonGroup = document.querySelectorAll('.button-group') as NodeListOf<Element>
    buttonGroup.forEach((item) => item.classList.remove('active'))
    if (initialValue === gender?.toLowerCase()) {
      buttonGroup.forEach((item) => {
        if ((item as HTMLElement)?.innerText.toLocaleLowerCase() === initialValue?.toLowerCase()) {
          item.classList.add('active')
        }
      })
    }
    setSelectedGender(gender)
    if (gender && onGenderChange) {
      onGenderChange(gender)
    }
  }

  return (
    <ButtonGroupElStyle container spacing={{ xs: 1, lg: 2, sm: 1 }} style={{ flex: 1 }}>
      <Grid item xs={12} sm={12}>
        {isLabel ? <label>{t('signup.gender')}</label> : null}
      </Grid>
      {Object.values(Gender).map((gender) => (
        <Grid item xs={4} key={gender}>
          <ButtonGroupStyle
            fullWidth
            key={gender}
            onClick={() => onGenderClick(gender)}
            className={`button-group ${
              selectedGender?.toLowerCase() === gender.toLowerCase() ? 'active' : ''
              // selected === gender || selectedGender === gender ? 'active' : ''
            }`}
          >
            {t(`signup.${gender.toLowerCase()}`)}
          </ButtonGroupStyle>
        </Grid>
      ))}
    </ButtonGroupElStyle>
  )
}

export default GenderGroup
