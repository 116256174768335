import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { SearchQueryData, SectionData } from '../../types/page'

interface SearchState {
  searchResultLoading: boolean
  error: string | null
  success: boolean
  query: string | ''
  pageCount: number | 0
  reload: boolean
  sectionResult: SectionData | null
  queryResult: SearchQueryData | null
}

const initialState: SearchState = {
  searchResultLoading: false,
  error: null,
  success: false,
  query: '',
  pageCount: 0,
  reload: true,
  sectionResult: null,
  queryResult: null
}

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    searchStart: (state, action: PayloadAction<string>) => ({
      ...state,
      searchResultLoading: true,
      error: null,
      success: false,
      pageCount: 0,
      query: action.payload
    }),
    searchSuccess: (state, action: PayloadAction<number>) => ({
      ...state,
      searchResultLoading: false,
      error: null,
      pageCount: action.payload,
      success: true
    }),
    searchFailure: (state, action: PayloadAction<string>) => ({
      ...state,
      searchResultLoading: false,
      error: action.payload,
      success: false
    }),
    clearSearch: (state) => ({
      ...state,
      searchResultLoading: false,
      error: null,
      success: false,
      query: '',
      pageCount: 0,
      reload: true,
      sectionResult: null,
      queryResult: null
    }),
    setSectionResponse: (state, action: PayloadAction<SectionData | null>) => ({
      ...state,
      sectionResult: action.payload
    }),
    setQueryResponse: (state, action: PayloadAction<SearchQueryData | null>) => ({
      ...state,
      queryResult: action.payload
    }),
    clearQueryResponse: (state, action: PayloadAction<string>) => ({
      ...state,
      queryResult: null,
      reload: false,
      query: action.payload,
      pageCount: 0
    })
  }
})

export const {
  searchStart,
  searchSuccess,
  searchFailure,
  clearSearch,
  setSectionResponse,
  setQueryResponse,
  clearQueryResponse
} = searchSlice.actions

export default searchSlice.reducer
