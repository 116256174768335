import { useNavigate } from 'react-router'
import { useSelector } from 'react-redux'
import TagManager from 'react-gtm-module'
import React, { FC } from 'react'
import { Grid, Typography } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'

import {
  clearPrevTrailerIds,
  gaCharsLimitation,
  getItemDetailScreen,
  isVideoItem,
  objectToQueryString
} from '../../utils/utils'
import { ContentSection } from '../../types/section'
import { VideoContentItem } from '../../types/item'
import { userSelector } from '../../store/slices/config/configSelectors'

import { PaperStyles } from './ThumbnailTile.style'

interface ThumbnailTileProps {
  onClickMore?: (value: VideoContentItem) => void
  onClickPopover?: (event) => void
  item: VideoContentItem | ContentSection
  showTrailer?: boolean
  useThumbnail?: boolean
  page?: string
  onMouseUpIndex?: (() => void) | undefined
  collectionPosition?: string
  section?: any
}

const ThumbnailTile: FC<ThumbnailTileProps> = ({
  onClickMore,
  item,
  showTrailer = false,
  useThumbnail = false,
  onClickPopover,
  page,
  onMouseUpIndex = () => 0,
  collectionPosition,
  section
}) => {
  const navigate = useNavigate()
  const user = useSelector(userSelector)

  const createTagParam = (item) => {
    const id = getItemDetailScreen(item)
    const casts = id?.custom_fields?.['cast']
    const castsLimitation = gaCharsLimitation(casts, 3)

    const genres = id?.custom_fields?.['genre']
    const genreLimitation = gaCharsLimitation(genres, 3)

    if (page == 'search' || page == 'catalog') {
      return {
        dataLayer: {
          event: 'card_click',
          event_category: 'search',
          event_action: 'click_card',
          event_label: item?.name,
          user_id: user?.uid,
          content_id: id?.video_id,
          content_position: onMouseUpIndex(),
          content_name: item?.name,
          content_type: item?.content_type,
          content_genre: genreLimitation,
          maturity_rating: id?.custom_fields?.['rating'],
          studio: id?.custom_fields?.['studio'],
          main_actor: castsLimitation
        }
      }
    } else if (page === 'vod_player') {
      return {
        dataLayer: {
          event: 'click_related_content',
          event_category: 'video',
          event_action: 'click_related_content',
          event_label: id?.display_name,
          content_name: id?.display_name,
          content_type: id?.custom_fields?.['content_type'],
          content_genre: genreLimitation,
          maturity_rating: id?.custom_fields?.['rating'],
          studio: id?.custom_fields?.['studio'],
          main_actor: castsLimitation
        }
      }
    } else if (page === 'homepage') {
      return {
        dataLayer: {
          event: 'card_click',
          event_category: page,
          event_action: 'click_card',
          event_label: id?.display_name,
          collection_id: item?.id,
          collection_position: collectionPosition,
          collection_title: section?.name,
          content_id: id?.video_id,
          content_position: onMouseUpIndex(),
          content_name: id?.display_name,
          content_type: id?.custom_fields?.['content_type'],
          content_genre: genreLimitation,
          maturity_rating: id?.custom_fields?.['rating'],
          studio: id?.custom_fields?.['studio'],
          main_actor: castsLimitation
        }
      }
    } else if (page === 'oned_original') {
      return {
        dataLayer: {
          event: 'original_card_click',
          event_category: 'original_card_click',
          event_action: 'card_click',
          event_label: id?.display_name
        }
      }
    }
  }

  const showContentDetailsRoute = () => {
    clearPrevTrailerIds()
    let videoDetails = {
      seriesName: '',
      next_up_id: '',
      seriesDescription: '',
      displayName: ''
    }
    const id = getItemDetailScreen(item)
    const tagManagerArgs = createTagParam(item)

    tagManagerArgs && TagManager.dataLayer(tagManagerArgs)
    if (!showTrailer) {
      videoDetails = {
        seriesName: '',
        next_up_id: '',
        seriesDescription: '',
        displayName: id?.display_name ? id?.display_name : ''
      }
      navigate(`/video/${id?.video_id}?data=${btoa(objectToQueryString(videoDetails))}`)
    } else {
      if (
        id?.series_id !== '' &&
        id?.['seriesTrailerId'] !== '' &&
        item?.['collection_type'] === 'Series'
      ) {
        videoDetails = {
          seriesName: item?.name,
          next_up_id: '',
          seriesDescription: item?.description ? item?.description : '',
          displayName: id?.display_name ? id?.display_name : ''
        }
        navigate(
          `/video/${id?.video_id}?trailer=${id?.[`seriesTrailerId`]}&data=${btoa(
            objectToQueryString(videoDetails)
          )}`
        )
      } else if (
        id?.series_id !== '' &&
        id?.['seasonTrailerId'] !== '' &&
        item?.['collection_type'] === 'Season'
      ) {
        videoDetails = {
          seriesName: item?.name,
          next_up_id: item?.id,
          seriesDescription: item?.description ? item?.description : '',
          displayName: id?.display_name ? id?.display_name : ''
        }
        navigate(
          `/video/${id?.video_id}?trailer=${id?.[`seasonTrailerId`]}&data=${btoa(
            objectToQueryString(videoDetails)
          )}`
        )
      } else if (id?.custom_fields?.['original_page_navigation'] === 'true') {
        setTimeout(() => {
          navigate(`/oned/original?id=${id?.video_id}`)
        }, 0)
      } else {
        videoDetails = {
          seriesName: '',
          next_up_id: '',
          seriesDescription: '',
          displayName: id?.display_name ? id?.display_name : ''
        }
        navigate(`/video/${id?.video_id}?data=${btoa(objectToQueryString(videoDetails))}`)
      }
    }
  }

  const getImageSource = (itemToCheck) => {
    if (isVideoItem(itemToCheck)) {
      return useThumbnail
        ? itemToCheck?.images?.['thumbnail']?.src
        : itemToCheck?.images?.['poster']?.src
    } else {
      return itemToCheck.image
    }
  }

  return (
    <>
      <Grid
        item
        xs={4}
        md={2}
        lg={2}
        xl={2}
        onClick={() => {
          showContentDetailsRoute()
        }}
        onMouseUp={() => {
          onMouseUpIndex()
        }}
      >
        <PaperStyles>
          <div className='thumbnail' style={{ backgroundImage: `url(${getImageSource(item)})` }} />
          {item && isVideoItem(item) && item.custom_fields['label'] && (
            <Typography className='label'>{item?.custom_fields['label']}</Typography>
          )}
          {item && isVideoItem(item) && onClickMore && (
            <div
              className={`more-bg ${item.id}`}
              onClick={(e) => {
                e.stopPropagation()
                onClickMore ? onClickMore(item) : null
              }}
              onMouseDown={(e) => (onClickPopover ? onClickPopover(e) : null)}
            >
              <MoreVertIcon
                id='more_icon'
                fontSize='medium'
                className={`More-icon ${item.id}`}
                style={{
                  fill: 'white'
                }}
              />
            </div>
          )}
          {item && isVideoItem(item) && item.progress && (
            <Grid className='bottom-bar' item container>
              <label className='bottom-label-text' style={{ visibility: 'hidden' }}>
                {item.name}
              </label>
              <progress
                className='bottom-pbar'
                id='watch_progress'
                value={item.progress}
                max='100'
              />
            </Grid>
          )}
        </PaperStyles>
      </Grid>
    </>
  )
}

export default ThumbnailTile
