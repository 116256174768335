import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { getApiHeader } from '../../../utils/utils'
import { DynamicStrings } from '../../../types/dynamicStrings'
import config from '../../../config'

export const dynamicStringsApi = createApi({
  reducerPath: 'dynamicStrings',
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.apiUrl}`,
    prepareHeaders: (headers, { getState }) => getApiHeader(headers, { getState })
  }),
  endpoints: (builder) => ({
    getDynamicStrings: builder.query<DynamicStrings[], { q: string }>({
      query: (args) => {
        const { q } = args
        ;('GET')
        return {
          url: `/strings`,
          params: { q }
        }
      }
    })
  })
})

export const { useLazyGetDynamicStringsQuery } = dynamicStringsApi
