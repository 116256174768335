/* eslint-disable @typescript-eslint/no-extra-semi */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { getApiHeader } from '../../../utils/utils'
import { LiveTvObject } from '../../../types/livetv'
// import { SectionData } from '../../../types/page'
import config from '../../../config'

export const livetvApi = createApi({
  reducerPath: 'livetv',
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.apiUrl}`,
    prepareHeaders: (headers, { getState }) => getApiHeader(headers, { getState })
  }),
  endpoints: (builder) => ({
    getChannelDetails: builder.query<LiveTvObject[], void>({
      query: () => {
        //const { id } = args;

        ;('GET')
        return {
          url: `/channels`
        }
      }
    })
  })
})

export const { useLazyGetChannelDetailsQuery } = livetvApi
