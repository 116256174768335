import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { getApiHeader } from '../../../utils/utils'
import { UserProfile, ContinueWatchingObject } from '../../../types/userProfile'
import config from '../../../config'

export const continueWatchingApi = createApi({
  reducerPath: 'continueWatchingApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.apiUrl}`,
    prepareHeaders: (headers, { getState }) => getApiHeader(headers, { getState }, true)
  }),
  endpoints: (builder) => ({
    getContinueWatchingList: builder.query<UserProfile, void>({
      query: () => ({
        url: `/user/profile`,
        method: 'GET',
        cache: 'no-cache'
      }),
      providesTags: (result): any => {
        if (!result) {
          return []
        }
        return [{ type: 'UserProfile', id: result.date_of_birth }]
      }
    }),
    updateContinueWatching: builder.mutation<ContinueWatchingObject, Partial<string>>({
      query: (data) => ({
        url: `/user/playhead`,
        method: 'POST',
        body: data,
        responseHandler: (response: { text: () => any }) => response.text(),
        cache: 'no-cache'
      })
    })
  })
})

export const { useLazyGetContinueWatchingListQuery, useUpdateContinueWatchingMutation } =
  continueWatchingApi
