import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import React, { FC, useEffect, useState } from 'react'
import { eachYearOfInterval, format, isValid, parse } from 'date-fns'
import { Grid, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import { Flex } from '@brightcove/studio-components'

import { setDayDisable, setIsMonthChange } from '../../../../store/slices/userProfileSlice'
import { useBreakPoints } from '../../../../hooks/useBreakPoints'
import { YEAR_OF_INTERVAL } from '../../../../constants'

import { DateElementStyle, SelectStyle } from './DateDropdown.style'

type DateDropdownProps = {
  onDateChange?: (day: number | null, month: number | null, year: number | null) => void
  selectedCalendar?: any
  isLabel?: boolean
}

const DateDropdown: FC<DateDropdownProps> = ({
  onDateChange,
  selectedCalendar,
  isLabel = false
}) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const [day, setDay] = useState<number | null | any>(
    selectedCalendar ? new Date(selectedCalendar).getDate() : null
  )
  const [month, setMonth] = useState<number | null | any>(
    selectedCalendar ? new Date(selectedCalendar).getMonth() : null
  )
  const [year, setYear] = useState<number | null | any>(
    selectedCalendar ? new Date(selectedCalendar).getFullYear() : null
  )
  const { startDate: start, endDate: end } = YEAR_OF_INTERVAL
  const { isMobile } = useBreakPoints()

  const onDayChange = (event: SelectChangeEvent<number>) => {
    // if (selectedCalendar === null) {
    //   selectedCalendar = new Date()
    // }
    // selectedCalendar?.setDate(event.target.value)
    dispatch(setDayDisable(false))
    dispatch(setIsMonthChange(false))

    setDay(event.target.value as number)
    setLastSelected('day')
  }
  const onMonthChange = (event: SelectChangeEvent<number>) => {
    dispatch(setIsMonthChange(true))
    setMonth(event.target.value as number)
    setLastSelected('month')
  }

  const onYearChange = (event: SelectChangeEvent<number>) => {
    // if (selectedCalendar === null) {
    //   selectedCalendar = new Date()
    // }
    // selectedCalendar?.setFullYear(event.target.value)
    setYear(event.target.value as number)
  }

  useEffect(() => {
    if (day !== null && month !== null && year !== null && onDateChange) {
      onDateChange(day, month + 1, year)
    }
  }, [day, month, year])

  const years = eachYearOfInterval({
    start,
    end
  })
  const [lastSelected, setLastSelected] = useState<string | null>(null)
  useEffect(() => {
    const dateString = `${year}-${month + 1}-${day}`
    const dateParse = parse(dateString, 'yyyy-M-d', new Date())
    // if year for newly registered
    if (year) {
      if (!isValid(dateParse)) {
        dispatch(setDayDisable(true))
        if (lastSelected === 'day') {
          setMonth(null)
        }
        if (lastSelected === 'month') {
          setDay(null)
        }
      }
    }
  }, [day, month, year])

  return (
    <DateElementStyle className='form-field button_font_family'>
      {isLabel ? <label>{t('signup.day_month_year') || ''}</label> : null}
      <Flex justifyContent='space-between' className='button_font_family date-dropdown'>
        <Grid item xs={12} sm={12} container spacing={isMobile ? 1 : 2}>
          <Grid item xs={4}>
            <SelectStyle variant='outlined' fullWidth>
              <Select
                className='button_font_family'
                value={day === null ? '' : day}
                // value={
                //   dayDisable
                //     ? ''
                //     : selectedCalendar
                //       ? new Date(selectedCalendar).getDate()
                //       : day ?? ''
                // }
                onChange={onDayChange}
                displayEmpty
                IconComponent={() => <KeyboardArrowDownRoundedIcon />}
              >
                <MenuItem selected disabled value='' className='button_font_family'>
                  {t('signup.day')}
                </MenuItem>
                {Array.from({ length: 31 }, (_, i) => (
                  <MenuItem key={i + 1} value={i + 1} className='button_font_family'>
                    {i + 1}
                  </MenuItem>
                ))}
              </Select>
            </SelectStyle>
          </Grid>

          <Grid item xs={4}>
            <SelectStyle variant='outlined' fullWidth>
              <Select
                className='button_font_family'
                value={
                  month === null ? '' : month
                  // selectedCalendar
                  //   ? new Date(selectedCalendar).getMonth()
                  //   : month !== null
                  //     ? month
                  //     : ''
                }
                onChange={onMonthChange}
                displayEmpty
                IconComponent={() => <KeyboardArrowDownRoundedIcon />}
              >
                <MenuItem value='' disabled className='button_font_family'>
                  {t('signup.month')}
                </MenuItem>
                {Array.from({ length: 12 }, (_, i) => (
                  <MenuItem key={i} value={i} defaultValue='Month' className='button_font_family'>
                    {format(new Date(new Date().getFullYear(), i, 1), 'MM')}
                    {/* {format(new Date(new Date().getFullYear(), i, 1), 'MMMM')} */}
                  </MenuItem>
                ))}
              </Select>
            </SelectStyle>
          </Grid>

          <Grid item xs={4}>
            <SelectStyle variant='outlined' fullWidth>
              <Select
                className='button_font_family'
                value={
                  year === null ? '' : year
                  // selectedCalendar
                  //   ? new Date(selectedCalendar).getFullYear()
                  //   : year !== null
                  //     ? year
                  //     : ''
                }
                onChange={onYearChange}
                displayEmpty
                IconComponent={() => <KeyboardArrowDownRoundedIcon />}
              >
                <MenuItem value='' disabled className='button_font_family'>
                  {t('signup.year')}
                </MenuItem>
                {years.map((year) => (
                  <MenuItem
                    key={year.getFullYear()}
                    value={year.getFullYear()}
                    className='button_font_family'
                  >
                    {year.getFullYear()}
                  </MenuItem>
                ))}
              </Select>
            </SelectStyle>
          </Grid>
        </Grid>
      </Flex>
    </DateElementStyle>
  )
}

export default DateDropdown
