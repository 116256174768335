import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { LiveTvObject } from '../../types/livetv'

interface LivetvState {
  contentLoading: boolean
  error: string | null
  success: boolean
  items: LiveTvObject[] | undefined
}

const initialState: LivetvState = {
  contentLoading: false,
  error: null,
  success: false,
  items: undefined
}

const livetvSlice = createSlice({
  name: 'content',
  initialState,
  reducers: {
    contentStart: (state) => ({
      ...state,
      contentLoading: true,
      error: null,
      success: false,
      items: undefined
    }),
    contentSuccess: (state, action: PayloadAction<LiveTvObject[] | undefined>) => ({
      ...state,
      contentLoading: false,
      error: null,
      success: true,
      items: action.payload
    }),
    contentFailure: (state, action: PayloadAction<string>) => ({
      ...state,
      contentLoading: false,
      error: action.payload,
      success: false
    })
  }
})

export const { contentFailure, contentStart, contentSuccess } = livetvSlice.actions

export default livetvSlice.reducer
