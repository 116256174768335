import { useEffect, useState, useCallback } from 'react'

import { getStorage } from '../../../utils/localStorageSupport'
import { useLazyGetUserProfileQuery } from '../../../store/services/user/userProfileApi'

const useSubscriptionStatus = (): boolean => {
  const [isSubscriptionUser, setIsSubscriptionUser] = useState<boolean>(false)
  const [getProfileData] = useLazyGetUserProfileQuery()
  const storage = getStorage()

  const token = storage?.getItem('token')

  const fetchSubscriptionStatus = useCallback(async () => {
    try {
      const profileResponse: any = await getProfileData()
      if (
        profileResponse &&
        profileResponse.data?.subscription &&
        Object.keys(profileResponse.data.subscription).length > 0
      ) {
        if (profileResponse.data.subscription.subscription_status?.toLowerCase() === 'active') {
          setIsSubscriptionUser(true)
        } else {
          setIsSubscriptionUser(false)
        }
      }
    } catch (error) {
      setIsSubscriptionUser(false)
    }
  }, [getProfileData, token])

  useEffect(() => {
    fetchSubscriptionStatus()
  }, [fetchSubscriptionStatus])

  return isSubscriptionUser
}

export default useSubscriptionStatus
