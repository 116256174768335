import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ConfigParams, MenuItem } from '../../types/config'

interface MenuList {
  menuApiLoading: boolean
  error: string | null
  success: boolean
  menuItems: ConfigParams | undefined
  headerMenuItems: MenuItem[]
  headerSubMenuItems: MenuItem[]
  footerItems: MenuItem[]
  currentLocation?: string | null
}

const initialState: MenuList = {
  menuApiLoading: false,
  error: null,
  success: false,
  menuItems: undefined,
  headerMenuItems: [],
  headerSubMenuItems: [],
  footerItems: [],
  currentLocation: '/'
}

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    menuStart: (state) => ({
      ...state,
      menuApiLoading: true,
      error: null,
      success: false,
      menuItems: undefined
    }),
    menuSuccess: (state, action: PayloadAction<ConfigParams | undefined>) => ({
      ...state,
      menuApiLoading: false,
      error: null,
      success: true,
      menuItems: action.payload
    }),
    menuFailure: (state, action: PayloadAction<string>) => ({
      ...state,
      menuApiLoading: false,
      error: action.payload,
      success: false
    }),
    headerMenu: (state, action: PayloadAction<MenuItem[]>) => ({
      ...state,
      headerMenuItems: action.payload
    }),
    headerSubMenu: (state, action: PayloadAction<MenuItem[]>) => ({
      ...state,
      headerSubMenuItems: action.payload
    }),
    footerMenu: (state, action: PayloadAction<MenuItem[]>) => ({
      ...state,
      footerItems: action.payload
    }),
    setLocation: (state, action: PayloadAction<string>) => ({
      ...state,
      currentLocation: action.payload
    })
  }
})

export const {
  menuFailure,
  menuStart,
  menuSuccess,
  headerMenu,
  headerSubMenu,
  footerMenu,
  setLocation
} = menuSlice.actions

export default menuSlice.reducer
