import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import React, { FC, useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import { Button } from '@brightcove/studio-components'

import SignUpGTMEvents from '../Common/GTM/SignUpGTMEvents'
import { isEmptyObject } from '../../utils/utils'
import { getStorage, isLocalStorageSupported } from '../../utils/localStorageSupport'
import { setStep } from '../../store/slices/signupSlice'
import { useLazyGetUserProfileQuery } from '../../store/services/user/userProfileApi'
import { RootState } from '../../store'

const StepThree: FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { currentLocation } = useSelector((state: RootState) => state.menu)
  const [isGTMSignUpSuccessful, setIsGTMSignUpSuccessful] = useState<boolean>(false)
  const [getProfileData] = useLazyGetUserProfileQuery()
  const storage = getStorage()

  useEffect(() => {
    document.body.className = 'body-white'
    return () => {
      document.body.className = ''
    }
  })

  const navigate = useNavigate()
  const routeChange = () => {
    setIsGTMSignUpSuccessful(true)
    // const path = `/`
    setTimeout(() => {
      const subscriptionStatus = checkSubscription()
      subscriptionStatus ? navigate(currentLocation as string) : navigate('/subscription')
      // navigate(currentLocation as string)
      dispatch(setStep(1))
      !isLocalStorageSupported()
        ? sessionStorage?.setItem('shouldNavigateBack', 'true')
        : storage.setItem('shouldNavigateBack', 'true')
    }, 0)
  }

  const checkSubscription = () => {
    getProfileData().then((profileResponse) => {
      if (profileResponse?.data?.subscription?.subscription_status === 'active') {
        return true
      } else if (
        profileResponse?.data?.subscription?.subscription_status === 'inactive' ||
        isEmptyObject(profileResponse?.data?.subscription)
      ) {
        return false
      }
    })
    return false
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Grid container direction='column' maxWidth={'680px'} className='step3_confirmation'>
      <Grid item>
        <CheckCircleOutlineOutlinedIcon
          style={{
            fontSize: '10rem',
            color: 'green',
            width: '109px',
            height: '109px',
            marginTop: '20px'
          }}
        />
      </Grid>
      <Grid item>
        <h3 className='confirm-header'>{t('signup.signUp_completed') || ''}</h3>
      </Grid>
      <Grid item>
        <p className='description'>{t('signup.happy_to_share') || ''}</p>
      </Grid>
      <Grid item xs={12} container justifyContent='space-between' spacing={2}>
        <Grid item xs={6}>
          <Button className='signup-button margin-top-2rem' onClick={routeChange}>
            {t('signup.start_oneD') || ''}
          </Button>
        </Grid>
        <Grid item xs={6} />
      </Grid>
      <SignUpGTMEvents isSignUpSuccessful={isGTMSignUpSuccessful} />
    </Grid>
  )
}

export default StepThree
