/* eslint-disable import/order */
import React, { FC } from 'react'
import './Description.scss'
import { useTranslation } from 'react-i18next'

import Share from '../Share/Share'
import EPGSchedule from './EPGSchedule'
import { Flex } from '@brightcove/studio-components'

interface DescriptionProps {
  channelData?: any
}

const Description: FC<DescriptionProps> = ({ channelData }) => {
  //   const loadingClassName = classnames('Loading-indicator', channelData);
  const { t } = useTranslation()

  return (
    <div className='channel-description'>
      <Flex flexDirection='column'>
        <div className='title'>
          {t(channelData?.name)}
          <div className='shareDiv'>
            <Share item={channelData} checkShare={true} page={'live_player'}></Share>
          </div>
        </div>
      </Flex>
      <div className='description'>{t(channelData?.metadata?.description)}</div>

      {/* <p className='description'>{JSON.stringify(channelData)}</p> */}
      <EPGSchedule channelData={channelData} />
    </div>
  )
}

export default Description
