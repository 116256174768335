import React, { FC, useEffect, useRef } from 'react'

import { useLazyGetDynamicStringsQuery } from '../../store/services/dynamicStrings/dynamicStringsApi'

interface FaqI {
  dynamic_string_key: string
}

const Faq: FC<FaqI> = ({ dynamic_string_key }) => {
  const [getFaqUrl]: any = useLazyGetDynamicStringsQuery()
  const openedNewTabRef = useRef(false)

  useEffect(() => {
    const resp = async () => {
      const response = await getFaqUrl({
        q: dynamic_string_key
      })
      if (!openedNewTabRef?.current) {
        window.location.replace(response.data.results.items[0].value)
        openedNewTabRef.current = true
      }
    }
    resp()
  }, [])

  return <div></div>
}

export default Faq
