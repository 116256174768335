import { z } from 'zod'
import { NavLink, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import React, { useEffect } from 'react'
import { Box, Button } from '@mui/material'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import { ErrorMessage } from '@hookform/error-message'
import { Input } from '@brightcove/studio-components'

import RedirectBack from '../Common/RedirectBack/RedirectBack'
import {
  forgotPasswordStart,
  forgotPasswordSuccess,
  resetForgotPasswordState
} from '../../store/slices/forgotPasswordSlice'
import { RootState } from '../../store/index'
import './ForgotPassword.scss'
import { useBreakPoints } from '../../hooks/useBreakPoints'
import useAuth from '../../hooks/useAuth'
import { auth } from '../../firebase'
import { Image } from '../../assets/images'

interface IFormInputs {
  email: string
}

const schemaForgotPassword = z.string().email()

const ForgotPassword: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { success } = useSelector((state: RootState) => state.forgotPassword)
  const { isMobile } = useBreakPoints()

  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { errors, isDirty }
  } = useForm<IFormInputs>()
  const { resetPassword } = useAuth()

  const onSubmit = async (data) => {
    if (!schemaForgotPassword.safeParse(data.email).success) {
      setError('email', {
        type: 'manual',
        message: t('forgot-password.invalid_email') as string
      })
      return
    }
    dispatch(forgotPasswordStart())
    try {
      // Replace with Firebase's reset password functionality
      await resetPassword(data.email)
      // Once the email is sent successfully, handle the success response
      dispatch(forgotPasswordSuccess())
      reset()
    } catch (error: any) {
      dispatch(forgotPasswordSuccess())
    }
  }

  const handleResetState = () => {
    dispatch(resetForgotPasswordState())
    navigate(-1)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    document.body.className = 'body-white'
    return () => {
      document.body.className = ''
    }
  })

  return (
    <>
      <RedirectBack auth={auth} />
      <Box sx={{ display: { xs: 'block', lg: 'none', xl: 'none', md: 'none', sm: 'none' } }}>
        <div className='profile-mobile-edit'>
          <header>
            <NavLink className='header-logo' to='/'>
              <img src={Image.One31Logo} alt='OneD Logo' />
            </NavLink>
            <Button
              onClick={() => navigate(-1)}
              className='profile-close-button'
              startIcon={<CloseOutlinedIcon />}
            />
          </header>
        </div>
      </Box>
      <div className='forgot-password'>
        {success ? (
          <div>
            <div className='forgot-password-success'>
              <h1 className='forgot-password-title'>
                {t('forgot-password.forgot_password') || ''}
              </h1>
              <div className='header-slider'>
                <div className='gray-slider'></div>
                <div className='pink-slider'></div>
              </div>
              <CheckCircleOutlineOutlinedIcon
                sx={{ height: '109px', width: '109px', color: '#2DA771' }}
              />
              <p className='email-sent-text'>{t('forgot-password.email_sent') || ''}</p>
              <p className='please-check-email'>{t('forgot-password.please_check_email') || ''}</p>
              <Button type='button' onClick={handleResetState} className='back-button'>
                {t('forgot-password.back') || ''}
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <h1 className='forgot-password-title'>{t('forgot-password.forgot_password') || ''}</h1>
            <div className='header-slider'>
              <div className='pink-slider'></div>
              <div className='gray-slider'></div>
            </div>
            <form className='forgot-password-form' onSubmit={handleSubmit(onSubmit)}>
              <div>
                <p className='input-label'>{t('forgot-password.email') || ''}</p>
                <Controller
                  name='email'
                  control={control}
                  render={({ field }) => (
                    <>
                      <Input
                        className={
                          Object.values(errors).length !== 0
                            ? 'error-password-input-field'
                            : 'password-input-field'
                        }
                        placeholder={t('forgot-password.email') || ''}
                        {...field}
                      />
                      <ErrorMessage
                        errors={errors}
                        name='email'
                        as={<span className='error-message' />}
                      />
                      <div className='profile-footer'>
                        {isMobile && (
                          <Button type='button' onClick={handleResetState} className='back-button'>
                            {t('forgot-password.back') || ''}
                          </Button>
                        )}
                        <Button
                          className={
                            !isDirty || field.value === ''
                              ? 'confirm-button disabled'
                              : 'confirm-button'
                          }
                          type='submit'
                          disabled={!isDirty || field.value === ''}
                        >
                          {t('forgot-password.confirm') || ''}
                        </Button>
                      </div>
                    </>
                  )}
                  defaultValue=''
                />
              </div>
            </form>
          </div>
        )}
      </div>
    </>
  )
}

export default ForgotPassword
