import { NavLink, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import React, { FC } from 'react'
import { Box, Button } from '@mui/material'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'

import { RootState } from '../../store/index'
import { useBreakPoints } from '../../hooks/useBreakPoints'
import { Image } from '../../assets/images'

import StepTwo from './StepTwo'
import StepThree from './StepThree'
import StepOne from './StepOne'

const SignUp: FC = () => {
  const { t } = useTranslation()
  const step = useSelector((state: RootState) => state.signup.step)
  const navigate = useNavigate()
  const { isMobile } = useBreakPoints()

  // if (sessionStorage.getItem('shouldNavigateBack')) {
  //   navigate(-1)
  // }

  return (
    <>
      {isMobile && (
        <Box>
          <div className='profile-mobile-edit'>
            <header>
              <NavLink className='header-logo' to='/'>
                <img src={Image.One31Logo} alt='OneD Logo' />
              </NavLink>
              <Button
                onClick={() => navigate(-1)}
                className='profile-close-button'
                startIcon={<CloseOutlinedIcon />}
              />
            </header>
          </div>
        </Box>
      )}
      <div className='signup-page'>
        <h1 className='signup-header'>{t('signup.signup') || ''}</h1>
        <div className='header-slider'>
          {Array.from({ length: 3 }, (_, i) => (
            <div key={i} className={`${i + 1 === step ? 'pink-slider' : 'gray-slider'}`} />
          ))}
        </div>
        {step === 1 && <StepOne />}
        {step === 2 && <StepTwo />}
        {step === 3 && <StepThree />}
      </div>
    </>
  )
}

export default SignUp
