export const register = (): void => {
  if ('serviceWorker' in navigator) {
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href)
    if (publicUrl.origin !== window.location.origin) return

    window.addEventListener('load', () => {
      const swUrl = `${process.env.PUBLIC_URL}/public/service-worker.js`
      if (window.location?.hostname === 'localhost' || window.location?.hostname === '127.0.0.1') {
        checkValidServiceWorker(swUrl)
      } else {
        registerValidSW(swUrl)
      }
    })
  }

  const registerValidSW = (swUrl) => {
    navigator.serviceWorker
      .register(swUrl)
      .then((registration) => {
        // eslint-disable-next-line no-console
        console.log('sw registration', registration)
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Error during service worker registration: ' + error)
      })
  }

  const checkValidServiceWorker = (swUrl): void => {
    fetch(swUrl)
      .then((response) => {
        if (
          response.status === 404 ||
          response?.headers?.get('content-type')?.indexOf('javascript') === -1
        ) {
          navigator.serviceWorker.ready.then((registration) => {
            registration.unregister().then(() => {
              window.location.reload()
            })
          })
        } else {
          registerValidSW(swUrl)
        }
      })
      .catch(() => {
        // eslint-disable-next-line no-console
        console.log('No internet connection found. App is running in offline mode.')
      })
  }
}

export const unregister = (): void => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then((registration) => {
      registration.unregister()
    })
  }
}
