import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { getApiHeader } from '../../../utils/utils'
import { MyListObject, UserProfile } from '../../../types/userProfile'
import config from '../../../config'

export const myListApi = createApi({
  reducerPath: 'myListApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.apiUrl}`,
    prepareHeaders: (headers, { getState }) => getApiHeader(headers, { getState }, true)
  }),
  endpoints: (builder) => ({
    getMyListApi: builder.query<UserProfile, void>({
      query: () => ({
        url: `/user/profile`,
        method: 'GET'
      })
    }),
    updateMyList: builder.mutation<MyListObject, Partial<Array<string>>>({
      query: (myListData) => ({
        url: `/user/profile/favorites`,
        method: 'POST',
        body: myListData
      })
    })
  })
})

export const { useLazyGetMyListApiQuery, useUpdateMyListMutation } = myListApi
