/* eslint-disable @typescript-eslint/no-unused-vars */
import { styled } from '@mui/material'

import { Image } from '../../assets/images'

const { REACT_APP_ENVIRONMENT } = process.env

const getQRCodeImage = (env: string | undefined) => {
  const lowercaseEnv = env?.toLowerCase()
  if (lowercaseEnv?.includes('development') || lowercaseEnv?.includes('qa')) {
    return Image.QRImageQA
  }
  if (lowercaseEnv?.includes('stage')) {
    return Image.QRImageStage
  }
  if (lowercaseEnv?.includes('production')) {
    return Image.QRImagePROD
  }
}

export const PricingStyle = styled('div')(({ theme }) => ({
  marginTop: 100,
  minHeight: '100vh',
  width: '100%',
  backgroundImage: `url(${Image.PricingBg})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'top center',
  boxShadow: '0px 100px 100px black inset',
  marginBottom: 100,
  ' .mobile_qr': {
    position: 'relative',
    backgroundColor: '#000',
    zIndex: '1',
    ' .mock_image': {
      width: '90%',
      margin: '30px 0',
      minHeight: 250
    },
    ' .choose_plan': {
      color: '#FFF',
      fontWeight: 700,
      fontSize: 18,
      paddingBottom: 30
    },
    ' .deep_link': {
      color: '#FFF',
      backgroundColor: theme.palette.primary.main,
      display: 'flex',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      width: '80%',
      margin: 'auto',
      padding: 15,
      borderRadius: 10,
      fontWeight: 800,
      marginBottom: 20
    }
  },
  ' .pricing_container': {
    width: '80%',
    margin: '0 auto',
    ' .pricing_header': {
      padding: 0,
      margin: 0,
      color: '#FFF',
      fontWeight: 700,
      fontSize: 20,
      textAlign: 'center',
      span: {
        color: theme.palette.primary.main
      }
    },
    ' .qr_container': {
      ' .qr_left': {
        ' .qr_image': {
          transform: 'scale(.8) translateY(-30px)',
          width: 'inherit',
          height: 386.05,
          backgroundImage: `url(${getQRCodeImage(REACT_APP_ENVIRONMENT)})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '90%',
          backgroundPosition: 'center',
          position: 'relative',
          ' >.corner': {
            background: 'rgba(0,0,0,0)',
            position: 'absolute',
            width: 100,
            border: '2px solid #FFF',
            height: 100,
            ' &.top_left': {
              top: 0,
              left: 0,
              borderRightWidth: 0,
              borderBottomWidth: 0
            },
            ' &.top_right': {
              top: 0,
              right: 0,
              borderLeftWidth: 0,
              borderBottomWidth: 0
            },
            ' &.bottom_left': {
              bottom: 0,
              left: 0,
              borderRightWidth: 0,
              borderTopWidth: 0
            },
            ' &.bottom_right': {
              bottom: 0,
              right: 0,
              borderLeftWidth: 0,
              borderTopWidth: 0
            }
          }
        }
      },
      ' .qr_right': {
        paddingLeft: 80,
        color: '#FFF',
        ' h3': {
          fontWeight: 800,
          ' >.title': {
            color: theme.palette.primary.main
          }
        }
      }
    },
    ' .pricing_terms': {
      color: '#ADADAD',
      fontWeight: 600,
      textAlign: 'center',
      a: {
        color: '#FFF',
        textDecoration: 'underline'
      }
    }
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: 0,
    marginBottom: 100,
    ' .deep_link': {
      transform: 'scale(.9)'
    }
  }
}))

export const PromoSection = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '30px',
  margin: '0 auto',
  borderRadius: '8px',
  width: '100%',
  maxWidth: '1200px',
  textAlign: 'center',
  color: '#fff',
  fontSize: '1.5rem',
  '& h1': {
    fontWeight: 'bold',
    margin: '0 0 10px 0'
  },
  '& p': {
    margin: '0'
  }
}))

export const ChoosePackageTitle = styled('h1')(({ theme }) => ({
  color: 'white',
  fontSize: '1.62rem',
  [theme.breakpoints.down('md')]: {
    fontSize: '1.12rem'
  },
  fontFamily: 'Prompt',
  fontWeight: 700,
  margin: '20px 0 10px 0'
}))

export const CustomerTabsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  alignItems: 'center',
  margin: '0 auto',
  marginBottom: '20px',
  backgroundColor: '#171717',
  width: '50%',
  [theme.breakpoints.down('md')]: {
    width: '100%'
  },

  maxWidth: '1200px',
  borderRadius: '100px',
  border: '2px solid #333',
  overflow: 'hidden'
}))

export const CustomerTab = styled('div')(({ theme }) => ({
  padding: '10px 20px',
  color: 'white',
  fontFamily: 'Prompt',
  cursor: 'pointer',
  fontWeight: 700,
  fontSize: '1.25rem',
  [theme.breakpoints.down('md')]: {
    fontSize: '0.62rem'
  },
  overflow: 'hidden',
  '&.active': {
    backgroundColor: '#111111',
    color: 'white',
    fontFamily: 'Prompt',
    border: '2px solid #333',
    borderRadius: '100px'
  },
  width: '50%'
}))

export const ActionButton = styled('button')(({ theme }) => ({
  position: 'absolute',
  bottom: '-15px',
  right: '0',
  padding: '10px 20px',
  backgroundColor: '#86BF50',
  fontFamily: 'Prompt',
  color: 'white',
  width: '187px',
  height: '58px',
  fontWeight: 600,
  border: 'none',
  borderRadius: '12px',
  fontSize: '1.1rem',
  cursor: 'pointer',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  '&:hover': {
    backgroundColor: '#75A746'
  }
}))

export const PricingCard = styled('div')(({ theme }) => ({
  position: 'relative',
  fontFamily: 'Prompt',
  border: '1px solid #444',
  borderRadius: '10px',
  padding: '20px 20px 40px',
  margin: '10px',
  width: 'auto',
  height: '100%',
  maxWidth: '400px',
  textAlign: 'left',
  color: '#fff',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)'
}))

export const PriceTitle = styled('h2')({
  fontSize: '26px',
  fontFamily: 'Prompt',
  fontWeight: 700,
  margin: '20px 0 10px 0'
})

export const PriceText = styled('p')({
  fontSize: '38px',
  fontFamily: 'Prompt',
  fontWeight: 700,
  margin: '5px 0',
  color: '#86BF50'
})

export const SmallText = styled('small')({
  fontSize: '16px',
  fontFamily: 'Prompt',
  fontWeight: 500,
  color: '#fff',
  margin: '-5px 0 10px 0'
})

export const CardDescription = styled('p')({
  fontSize: '16px',
  fontFamily: 'Prompt',
  fontWeight: 400,
  color: '#9F9F9F',
  margin: '10px 0'
})

export const CardTerms = styled('p')({
  position: 'absolute',
  fontSize: '16px',
  fontFamily: 'Prompt',
  fontWeight: 400,
  color: '#9F9F9F',
  bottom: '0',
  margin: '40px 0',
  textDecoration: 'underline',
  cursor: 'pointer'
})

export const BestValueLabel = styled('div')({
  position: 'absolute',
  fontFamily: 'Prompt',
  top: '0',
  right: '0',
  backgroundColor: '#86BF50',
  color: 'white',
  padding: '5px 10px',
  borderRadius: '8px 8px 0 8px',
  fontSize: '0.8rem',
  fontWeight: 600,
  textTransform: 'uppercase'
})

export const DividerLine = styled('div')(({ theme }) => ({
  flex: 1,
  borderBottom: `1px solid ${theme.palette.grey[700]}`,
  margin: '0 10px'
}))

// Container for the link section
export const LinkSection = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '20px 0px 40px 0px',
  fontFamily: theme.typography.fontFamily,
  fontSize: '26px',
  color: theme.palette.common.white,
  '& span': {
    marginRight: '10px'
  }
}))

// Button style for the "Click" button
export const LinkButton = styled('button')(({ theme }) => ({
  padding: '10px 20px',
  backgroundColor: '#86BF50',
  width: '189px',
  height: '59px',
  border: 'none',
  borderRadius: '12px',
  fontFamily: 'Prompt',
  fontWeight: 600,
  fontSize: '20px',
  color: 'white',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#75A746'
  }
}))
