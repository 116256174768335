import localforage from 'localforage'

export function isLocalStorageSupported(
  storageType: 'localStorage' | 'sessionStorage' = 'localStorage'
): boolean {
  try {
    const storage = window[storageType]
    if (!storage) return false

    return true
  } catch (e) {
    return false
  }
}

export function getStorage(): any {
  if (isLocalStorageSupported()) {
    return localStorage
  } else {
    return localforage
  }
}
