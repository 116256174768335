import React, { FC, useState } from 'react' //useEffect
import { Dialog, IconButton } from '@mui/material'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import '../../SignUp/OTPScreen/OTPDialog.scss'

interface ErrorDialogI {
  message?: string
  errorMessage?: string
  setClosed: (arg: boolean) => void
}

const ErrorDialog: FC<ErrorDialogI> = ({ message, errorMessage, setClosed }) => {
  const [open, setOpen] = useState(true)

  const handleClose = () => {
    setOpen(false)
    setClosed(true)
  }
  return (
    <Dialog
      open={open}
      PaperProps={{
        className: 'Login-OTPDialog-wrapper'
      }}
      onClose={handleClose}
    >
      <IconButton
        aria-label='close'
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500]
        }}
      >
        <CloseRoundedIcon />
      </IconButton>
      <div className='messages'>
        {message && <div className='warning'>{message}</div>}
        {errorMessage && <div className='error'>{errorMessage}</div>}
      </div>
    </Dialog>
  )
}

export default ErrorDialog
