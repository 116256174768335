import { styled } from '@mui/system'

export const ActivationConfirmationStyles = styled('div')(({ theme }) => ({
  marginBlock: 90,
  ' .icon-wrapper': {
    width: 180,
    height: 180,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 100,
    '&.success': {
      backgroundColor: '#22C93B'
    },
    '&.failed': {
      backgroundColor: '#EB583B'
    },
    ' .confirmation-icon': {
      display: 'block',
      fill: '#FFF',
      transform: 'scale(6)'
    }
  },
  ' .confirmation-message': {
    marginTop: 40,
    h4: {
      color: '#FFF',
      marginBottom: 10,
      fontSize: 30,
      fontWeight: 600
    },
    p: {
      color: '#ACACAC',
      fontSize: 18,
      fontWeight: 600
    },
    button: {
      marginTop: 30
    }
  },

  [theme.breakpoints.down('sm')]: {}
}))
