import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import React, { FC, useEffect, useState } from 'react'
import { format, parseISO } from 'date-fns'
import CircularProgress from '@mui/material/CircularProgress'
import { Button, Grid, Typography } from '@mui/material'
import { Flex } from '@brightcove/studio-components'

import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import { RootState } from '../../store'
import { useBreakPoints } from '../../hooks/useBreakPoints'

import ProfileEditMobileHeader from './Components/ProfileEditMobileHeader'

import './Subscription.scss'

const Subscription: FC = () => {
  const { t } = useTranslation()
  const [isEditable, setIsEditable] = useState(false)
  const { isMobile } = useBreakPoints()
  const { data: userData } = useSelector((state: RootState) => state.userProfile)

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const isProfileMobileEdit = isMobile && isEditable

  useEffect(() => {
    userData?.subscription && setIsLoading(false)
  }, [userData?.subscription])
  const dateFormatted = (date) => {
    const endDate = date ? parseISO(date) : new Date()
    return format(endDate, 'dd/MM/yyyy')
  }

  return (
    <Flex className={`profile-detail subscription ${isProfileMobileEdit ? 'mobile' : ''}`}>
      <div className='profile-detail-header'>
        <h2 className='profile-detail-header-text'>{t('profile.subscription')}</h2>
        {isLoading && (
          <CircularProgress
            sx={{
              color: '#fff',
              display: 'inline-block',
              marginInline: 2,
              opacity: 0.5
            }}
            size={15}
          />
        )}
      </div>
      {isProfileMobileEdit && <ProfileEditMobileHeader setIsEditable={setIsEditable} t={t} />}
      {!isProfileMobileEdit && <hr className='horizontal-line' />}

      <Grid container justifyContent='center' className='subscription_status' spacing={4}>
        <Grid container item justifyContent='space-between'>
          <Grid item>
            <Typography className='typo title'>{t('profile.account_status')}</Typography>
          </Grid>
          <Grid item>
            <Typography className={`typo summary ${userData?.subscription && 'display'}`}>
              {userData?.subscription?.subscription_status}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item justifyContent='space-between'>
          <Grid item>
            <Typography className='typo title'>{t('profile.renew_date')}</Typography>
          </Grid>
          <Grid item>
            <Typography className={`typo summary ${userData?.subscription && 'display'}`}>
              {dateFormatted(userData?.subscription?.subscription_end_date)}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item justifyContent='space-between'>
          <Grid item>
            <Typography className='typo title'>{t('profile.type_of_package')}</Typography>
          </Grid>
          <Grid item>
            <Typography className={`typo summary ${userData?.subscription && 'display'}`}>
              {userData?.subscription?.subscription_plan_name}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <div className='cancel_subscription'>
        <Button
          onClick={() =>
            window.open('https://assets.oned.net/doc/faq-th.html#unsubscribe', '_blank')
          }
        >
          {t('profile.cancel_subscription')}
        </Button>
      </div>
    </Flex>
  )
}

export default Subscription
