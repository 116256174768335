import { styled } from '@mui/system'
import { OutlinedInput } from '@mui/material'

export const InputStyles = styled('div')(({ theme }) => ({
  paddingBlock: 10,
  // flex: 1,
  height: 50,
  marginBottom: 50,
  '& label': {
    display: 'block',
    marginBottom: '5px'
  },
  '& .input-error': {
    display: 'block',
    color: theme.palette.colors.error,
    marginTop: 10
  },
  [theme.breakpoints.down('sm')]: {
    paddingBlock: 0
  }
}))

export const OutlinedInputStyles: any = styled(OutlinedInput)(({ theme }) => ({
  minWidth: 400,
  width: '100%',
  backgroundColor: theme.palette.colors.lightGray,
  input: {
    height: 'inherit',
    lineHeight: 'inherit'
  },
  '.MuiInputBase-root-MuiOutlinedInput-root': {
    lineHeight: 50
  },
  '& .MuiOutlinedInput-notchedOutline': {},
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderWidth: 1,
    borderColor: theme.palette.primary.main
  },
  '&.Mui-focused': {
    borderWidth: 1,
    borderColor: theme.palette.primary.main
  },
  [theme.breakpoints.down('sm')]: {
    // minWidth: 350
  }
}))
