import { styled } from '@mui/system'
import { Paper } from '@mui/material'

export const PaperStyles = styled(Paper)(({ theme }) => ({
  background: 'rgba(0,0,0,0)',
  padding: 0,
  cursor: 'pointer',
  position: 'relative',
  overflow: 'hidden',
  minWidth: '195px',
  minHeight: '270px',
  maxWidth: '195px',
  maxHeight: '270px',
  borderRadius: 12,
  [theme.breakpoints.down('sm')]: {
    minWidth: '100px',
    minHeight: '165px',
    maxWidth: '100px',
    maxHeight: '165px',
    borderRadius: 8
  },
  [`${theme.breakpoints.down('md')} @media (orientation: landscape)`]: {
    minWidth: '130px',
    minHeight: '165px',
    maxWidth: '130px',
    maxHeight: '165px'
  },
  '& .thumbnail': {
    backgroundColor: '#1f1f1f',
    width: '100%',
    height: 'auto',
    minWidth: 'inherit',
    minHeight: 'inherit',
    maxWidth: 'inherit',
    maxHeight: 'inherit ',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    transition: 'all .5s',
    filter: 'contrast(1)',
    [theme.breakpoints.down('sm')]: {
      minWidth: '100px',
      minHeight: '165px',
      maxWidth: '100px',
      maxHeight: '165px'
    },
    [`${theme.breakpoints.down('md')} @media (orientation: landscape)`]: {
      minWidth: '130px',
      minHeight: '165px',
      maxWidth: '130px',
      maxHeight: '165px'
    },
    ':hover': {
      filter: 'contrast(1.2)'
    }
  },
  '& .more-bg': {
    background: 'rgba(0,0,0,.6)',
    position: 'absolute',
    top: '5px',
    right: '4px',
    width: '40px',
    height: '40px',
    borderRadius: '70px',
    lineHeight: '55px',
    textAlign: 'center'
  },
  '& .label': {
    position: 'absolute',
    color: '#FFF',
    top: 0,
    left: 0,
    width: '80%',
    height: 30,
    lineHeight: '30px',
    background: theme.palette.colors.primary,
    borderRadius: '0 0 12px 0',
    textIndent: 15,
    fontSize: 12,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('sm')]: {
      fontSize: 10
    }
  },
  '& .bottom-bar': {
    height: 40,
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    background: 'rgba(0,0,0,.8)',
    '& > *': {
      width: '100%'
    },
    '& .bottom-label-text': {
      color: '#fff',
      width: '100%',
      fontFamily: 'Prompt',
      fontSize: '16px',
      marginLeft: '2px',
      marginRight: '2px',
      display: 'flex',
      flexWrap: 'wrap',
      whiteSpace: 'nowrap',
      alignSelf: 'start',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: 'normal'
    },
    '& .bottom-pbar': {
      accentColor: theme.palette.colors.primary,
      transform: 'translateY(-5px)'
    }
  },
  '& .MuiLinearProgress-root': {
    background: '#595858',
    width: '80%',
    height: 6,
    margin: 'auto',
    borderRadius: 10,
    '& > *': {
      borderRadius: 'inherit'
    }
  }
}))
