import { useNavigate } from 'react-router'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import TagManager from 'react-gtm-module'
import React, { FC, useEffect } from 'react'
import moment from 'moment'
import { Button } from '@mui/material'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import { Flex } from '@brightcove/studio-components'

import { getStorage } from '../../utils/localStorageSupport'
import { clearNotificationCount } from '../../store/slices/notificationSlice'
import { useBreakPoints } from '../../hooks/useBreakPoints'
import { useLocalStorage } from '../../hooks/UIHooks'
import { SVGImage } from '../../assets/images'
import './NotificationPage.scss'

const NotificationsPage: FC = () => {
  const [notficationItems, setNotificationItems] = useLocalStorage('notf_list')
  const dispatch = useDispatch()
  const { isMobile } = useBreakPoints()
  const [, setNotificationList] = useLocalStorage('notf_list')
  const { t } = useTranslation()
  const storage = getStorage()
  const uid = storage.getItem('uid')
  const navigate = useNavigate()

  useEffect(() => {
    const parentElement = document.getElementsByClassName('content')[0] as HTMLElement
    if (!isMobile) {
      parentElement.style.background = 'none'
    }
    parentElement.style.margin = '0'
  }, [])

  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'page_view',
        page_title: 'notification',
        page_location: location,
        member_id: uid,
        user_id: uid
      }
    }
    TagManager.dataLayer(tagManagerArgs)
  }, [])

  const deleteNotification = (index, title, body) => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'notification_dismiss',
        page_title: 'notification',
        event_action: 'click_dismiss',
        event_label: body,
        notification_label: title
      }
    }
    TagManager.dataLayer(tagManagerArgs)
    const notfItemsArr = JSON.parse(notficationItems)
    notfItemsArr.splice(index, 1)
    setNotificationItems(JSON.stringify(notfItemsArr))
  }

  const getDate = (ipDate) => moment(ipDate, 'DD/MM/YYYY').format('DD MMM YYYY')

  const getImageSource = (item) => item.image

  const onClickNotifications = (videoId, title, body) => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'notification_click',
        page_title: 'notification',
        event_action: 'click_card',
        event_label: body,
        notification_label: title
      }
    }
    TagManager.dataLayer(tagManagerArgs)
    dispatch(clearNotificationCount())
    if (videoId) {
      navigate(`/video/${videoId}`)
    }
  }

  return (
    <div className={`profile-notification ${isMobile ? 'isMobile' : ''}`}>
      {isMobile && (
        <Button
          className='mobile-header'
          startIcon={<KeyboardArrowLeftIcon />}
          onClick={() => navigate(-1)}
        >
          {t('profile.back') || ''}
        </Button>
      )}

      <ul className='notf-table'>
        {notficationItems &&
          JSON.parse(notficationItems).length > 0 &&
          JSON.parse(notficationItems).map((item, index) => (
            <li key={index}>
              <Flex flexDirection='row'>
                <div
                  className='img_title'
                  onClick={() => {
                    const notfList = JSON.parse(notficationItems).map((item) => ({
                      ...item,
                      isRead: true
                    }))
                    setNotificationList(JSON.stringify(notfList))
                    onClickNotifications(item.videoId, item.title, item.body)
                  }}
                >
                  <div
                    className='notf_img_holder'
                    style={{ backgroundImage: `url(${getImageSource(item)})` }}
                  ></div>
                  <div
                    className='notf_text_holder'
                    style={{ marginLeft: '10px', marginRight: '10px' }}
                  >
                    <p className='notf_title'>{item.title}</p>
                    <p className='notf_body'>{item.body}</p>
                    <p className='notf_date'>{getDate(item.timeStamp)}</p>
                  </div>
                </div>
                <div
                  className='notf_close'
                  onClick={() => {
                    deleteNotification(index, item.title, item.body)
                  }}
                >
                  <img src={SVGImage.NotfClose} />
                </div>
              </Flex>
            </li>
          ))}
      </ul>
    </div>
  )
}

export default NotificationsPage
