import { styled } from '@mui/system'
import { Paper } from '@mui/material'

export const PaperStyles = styled(Paper)(({ theme }) => ({
  width: 380,
  height: 270,
  minWidth: 380,
  minHeight: 270,
  maxWidth: 380,
  maxHeight: 270,
  background: 'rgba(0,0,0,0)',
  boxShadow: 'none',
  cursor: 'pointer',
  '& .thumbnail': {
    borderRadius: 12,
    width: '100%',
    height: 218,
    minWidth: 'inherit',
    minHeight: 218,
    maxWidth: 'inherit',
    maxHeight: 218,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    transition: 'all .5s',
    '& .play-icon': {
      fill: 'white',
      border: '2px solid #FFF',
      backgroundColor: 'rgba(0,0,0,.3)',
      width: 90,
      height: 90,
      borderRadius: 50
    }
  },
  '& .label': {
    fontFamily: 'Prompt',
    width: '99%',
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '50px',
    letterSpacing: 0,
    color: '#fff',
    whiteSpace: 'noWrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  [theme.breakpoints.between('sm', 'md')]: {
    width: 202,
    height: 116,
    minWidth: 202,
    minHeight: 116,
    maxWidth: 202,
    maxHeight: 116,
    '& .thumbnail': {
      width: 180,
      height: 116,
      minWidth: 180,
      minHeight: 116,
      maxWidth: 180,
      maxHeight: 116,
      '& .play-icon': {
        width: 50,
        height: 50
      }
    },
    '& .label': {
      width: '85%',
      fontSize: 15
    }
  },
  [theme.breakpoints.down('sm')]: {
    width: 380,
    height: 160,
    minWidth: 380,
    minHeight: 160,
    maxWidth: 380,
    maxHeight: 160,
    '& .thumbnail': {
      width: 180,
      height: 116,
      minWidth: 180,
      minHeight: 116,
      maxWidth: 180,
      maxHeight: 116,
      '& .play-icon': {
        width: 50,
        height: 50
      }
    },
    '& .label': {
      width: '40%',
      fontSize: 13
    }
  }
}))
export const PaperStylesHor = styled(Paper)(({ theme }) => ({
  background: 'rgba(0,0,0,0)',
  boxShadow: 'none',
  '& .play-icon': {
    fill: 'white',
    border: '2px solid #FFF',
    backgroundColor: 'rgba(0,0,0,.3)',
    width: 75.28,
    height: 75.28,
    borderRadius: 50,
    position: 'absolute',
    bottom: '30%',
    left: 120,
    [theme.breakpoints.down('sm')]: {
      width: 33.63,
      height: 33.63,
      left: 50
    },
    [theme.breakpoints.between('sm', 'md')]: {
      left: 50
    }
  },
  '& .Poster_item_title-hor': {
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      transform: 'translateY(-20px)'
    }
  }
}))
