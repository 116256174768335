import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { getApiHeader } from '../../../utils/utils'
import { MarketingCampaignData } from '../../../types/marketingCampaign'
import config from '../../../config'

interface Args {
  id?: string
  params?: Record<string, string>
}

export const marketingApi = createApi({
  reducerPath: 'marketingApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.apiUrl}`,
    prepareHeaders: (headers, { getState }) => getApiHeader(headers, { getState })
  }),
  endpoints: (builder) => ({
    getMarketingApi: builder.query<MarketingCampaignData[], Args>({
      query: () => ({
        url: `/marketing_campaigns`,
        method: 'GET'
      })
    })
  })
})

export const { useLazyGetMarketingApiQuery } = marketingApi
