import { useTranslation } from 'react-i18next'
import React, { FC, useEffect, useState } from 'react'

interface OTPTimerProps {
  onTimeout: () => any
}

const OTPTimer: FC<OTPTimerProps> = ({ onTimeout }) => {
  const [remainingSeconds, setRemainingSeconds] = useState(60)
  const { t } = useTranslation()

  useEffect(() => {
    let timer
    if (remainingSeconds > 0) {
      timer = setTimeout(() => setRemainingSeconds((prev) => prev - 1), 1000)
    } else {
      onTimeout() // Trigger the callback function when the timer reaches 0
    }

    return () => clearTimeout(timer) // Clean up the timer on unmount
  }, [remainingSeconds, onTimeout])

  return (
    <div>
      {remainingSeconds > 0
        ? t('signup.enter_OTP_seconds', { time: remainingSeconds })
        : t('signup.expired_OTP')}
    </div>
  )
}

export default OTPTimer
