import { getMessaging, getToken, MessagePayload, onMessage, isSupported } from 'firebase/messaging'
import {
  getAuth,
  signInWithPopup,
  FacebookAuthProvider,
  GoogleAuthProvider,
  OAuthProvider
} from 'firebase/auth'
import { initializeApp } from 'firebase/app'

const fireBaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
}
const app = initializeApp(fireBaseConfig)
const auth = getAuth(app)
const facebookAuthProvider = new FacebookAuthProvider()
facebookAuthProvider.addScope('email')
const googleAuthProvider = new GoogleAuthProvider()
googleAuthProvider.addScope('email')
const appleAuthProvider = new OAuthProvider('apple.com')
appleAuthProvider.addScope('email')

let messaging

isSupported().then((supported) => {
  if (supported) {
    messaging = getMessaging(app)
    requestNotificationPermission()
  } else {
    // eslint-disable-next-line no-console
    console.warn('This browser does not support Firebase Messaging.')
  }
})

const getNotificationToken = (): void => {
  getToken(messaging, {
    vapidKey:
      'BFi3QKnr_edTEJfi7HY_OFNJZBid3l3f7RjSA3X-FpR7D71pO5ZIppl2yLBOiO4jGJnHi7Tj6LHNaDpyZuCI6G8'
  })
    .then((currentToken) => {
      if (currentToken) {
        // eslint-disable-next-line no-console
        console.log('Client Token: ', currentToken)
      } else {
        // eslint-disable-next-line no-console
        console.log('Failed to generate the app registration token.')
      }
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.log('An error occurred when requesting to receive the token.', err)
    })
}

const requestNotificationPermission = (): void => {
  if ('Notification' in window) {
    // eslint-disable-next-line no-console
    console.log('Requesting User Permission...')
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        // eslint-disable-next-line no-console
        console.log('Notification User Permission Granted.')
        getNotificationToken()
      } else {
        // eslint-disable-next-line no-console
        console.log('User Permission Denied.')
      }
    })
  }
}

const onMessageListener = (): Promise<MessagePayload> =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload)
    })
  })

export {
  auth,
  signInWithPopup,
  facebookAuthProvider,
  googleAuthProvider,
  appleAuthProvider,
  getNotificationToken,
  requestNotificationPermission,
  onMessageListener
}
