import { useTranslation } from 'react-i18next'
import React from 'react'
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded'

interface PackageOption {
  text: string
  icon?: JSX.Element | null
}

interface PackageItemsI {
  title: string
  subtitle?: string
  free: PackageOption
  premium: PackageOption
}

export const PackageData = (): PackageItemsI[] => {
  const { t } = useTranslation()
  console
  return [
    // {
    //   title: `Exclusive Period`,
    //   free: {
    //     text: 'Delay 3 Days',
    //     icon: null
    //   },
    //   premium: {
    //     text: 'You can watch immediately, all episodes.',
    //     icon: null
    //   }
    // },
    {
      title: t('pricing.packages.ad_free') || '',
      subtitle: t('pricing.packages.ad_free_sub') || '',
      free: {
        text: '-',
        icon: null
      },
      premium: {
        text: '',
        icon: <VerifiedRoundedIcon />
      }
    },
    {
      title: t('pricing.packages.smart_tv_application') || '',
      free: {
        text: t('pricing.packages.live_only') || '',
        icon: null
      },
      premium: {
        text: '',
        icon: <VerifiedRoundedIcon />
      }
    },
    {
      title: t('pricing.packages.chrome_and_airplay') || '',
      free: {
        text: '-',
        icon: null
      },
      premium: {
        text: '',
        icon: <VerifiedRoundedIcon />
      }
    },
    {
      title: t('pricing.packages.video_quality') || '',
      free: {
        text: '-',
        icon: null
      },
      premium: {
        text: '',
        icon: <VerifiedRoundedIcon />
      }
    },
    {
      title: t('pricing.packages.special_content') || '',
      free: {
        text: '-',
        icon: null
      },
      premium: {
        text: '',
        icon: <VerifiedRoundedIcon />
      }
    },
    {
      title: t('pricing.packages.pip') || '',
      free: {
        text: '-',
        icon: null
      },
      premium: {
        text: '',
        icon: <VerifiedRoundedIcon />
      }
    },
    {
      title: t('pricing.packages.concurrent_stream') || '',
      free: {
        text: t('pricing.packages.free_devices') || '',
        icon: null
      },
      premium: {
        text: t('pricing.packages.premium_devices') || '',
        icon: null
      }
    },
    {
      title: t('pricing.packages.video_speed') || '',
      free: {
        text: '-',
        icon: null
      },
      premium: {
        text: '',
        icon: <VerifiedRoundedIcon />
      }
    },
    {
      title: t('pricing.packages.live_tv') || '',
      free: {
        text: '',
        icon: <VerifiedRoundedIcon />
      },
      premium: {
        text: '',
        icon: <VerifiedRoundedIcon />
      }
    }
    // {
    //   title: t('pricing.packages.director_cut') || '',
    //   free: {
    //     text: '-',
    //     icon: null
    //   },
    //   premium: {
    //     text: '',
    //     icon: <VerifiedRoundedIcon />
    //   }
    // },
    // {
    //   title: t('pricing.packages.cast_screening') || '',
    //   free: {
    //     text: '-',
    //     icon: null
    //   },
    //   premium: {
    //     text: '',
    //     icon: <VerifiedRoundedIcon />
    //   }
    // },
    // {
    //   title: t('pricing.packages.full_hd') || '',
    //   free: {
    //     text: '-',
    //     icon: null
    //   },
    //   premium: {
    //     text: '',
    //     icon: <VerifiedRoundedIcon />
    //   }
    // },
  ]
}
