import React, { FC, lazy, Suspense } from 'react'

import { CustomSectionType } from '../../types/section'

interface CustomSectionProps {
  section: CustomSectionType
  name?: string
}

const CustomSection: FC<CustomSectionProps> = ({ name, section: { templateRef } }) => {
  const DynamicComponent = (templateRef &&
    lazy(() => import(`../${templateRef}`))) as React.ElementType

  return (
    <>
      {templateRef?.length ? (
        <Suspense fallback={<></>}>
          <DynamicComponent {...{ name }} />
        </Suspense>
      ) : null}
    </>
  )
}

export default CustomSection
