import { useNavigate } from 'react-router'
import TagManager from 'react-gtm-module'
import React, { FC } from 'react'
import { Grid, Typography } from '@mui/material'
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded'

import {
  clearPrevTrailerIds,
  formatDuration,
  getItemDetailScreen,
  isVideoItem,
  objectToQueryString
} from '../../utils/utils'
import { ContentSection } from '../../types/section'
import { VideoContentItem } from '../../types/item'

import './PosterTile.scss'
import { PaperStyles } from './ThumbnailTile.style'
import { PaperStylesHor } from './PosterTile.style'

interface PosterTileProps {
  onClickMore?: (value: VideoContentItem) => void
  item: VideoContentItem | ContentSection
  direction: string
  useThumbnail?: boolean
  page?: string
  section?: any
  isDuration?: boolean
}

const PosterTile: FC<PosterTileProps> = ({
  item,
  direction,
  useThumbnail = false,
  page,
  section,
  isDuration = false
}) => {
  const navigate = useNavigate()

  const createTagParam = (item) => {
    const id = getItemDetailScreen(item)
    if (page == 'vod_player') {
      return {
        dataLayer: {
          event: 'card_click',
          page_name: page,
          collection_id: item?.series_id,
          collection_position: item?.position,
          collection_title: item?.name,
          content_id: id?.video_id,
          content_position: item?.items?.findIndex((obj) => obj?.video_id === id?.video_id),
          content_title: id?.display_name
        }
      }
    } else if (page == 'homepage') {
      return {
        dataLayer: {
          event: 'card_click',
          event_category: page,
          event_action: 'click_card',
          event_label: id?.display_name,
          collection_id: section?.id,
          collection_position: section?.position,
          collection_title: section?.name,
          content_id: id?.video_id,
          content_position: section?.items?.findIndex((obj) => obj?.id === id?.video_id),
          content_name: id?.display_name,
          content_type: id?.content_type,
          content_genre: id?.custom_fields?.['genre'],
          maturity_rating: id?.custom_fields?.['rating'],
          studio: id?.custom_fields?.['studio'],
          main_actor: id?.custom_fields?.['cast']
        }
      }
    } else if (page === 'oned_original') {
      return {
        dataLayer: {
          event: 'original_card_click',
          event_category: 'original_card_click',
          event_action: 'card_click',
          event_label: id?.display_name
        }
      }
    }
  }

  const showContentDetailsRoute = (direction) => {
    clearPrevTrailerIds()
    let videoDetails = {
      seriesName: '',
      next_up_id: '',
      seriesDescription: '',
      displayName: ''
    }
    const id = getItemDetailScreen(item)
    const tagManagerArgs = createTagParam(item)
    if (tagManagerArgs) TagManager.dataLayer(tagManagerArgs)
    if (direction === 'horizontal') {
      videoDetails = {
        seriesName: '',
        next_up_id: '',
        seriesDescription: '',
        displayName: id?.display_name ? id?.display_name : ''
      }
      navigate(`/video/${id?.video_id}?data=${btoa(objectToQueryString(videoDetails))}`)
    } else {
      if (
        id?.series_id !== '' &&
        id?.['seriesTrailerId'] !== '' &&
        item?.['collection_type'] === 'Series'
      ) {
        videoDetails = {
          seriesName: item?.name,
          next_up_id: '',
          seriesDescription: item?.description ? item?.description : '',
          displayName: id?.display_name ? id?.display_name : ''
        }
        navigate(
          `/video/${id?.video_id}?trailer=${id?.[`seriesTrailerId`]}&data=${btoa(
            objectToQueryString(videoDetails)
          )}`
        )
      } else if (
        id?.series_id !== '' &&
        id?.['seasonTrailerId'] !== '' &&
        item?.['collection_type'] === 'Season'
      ) {
        videoDetails = {
          seriesName: item?.name,
          next_up_id: item?.id,
          seriesDescription: item?.description ? item?.description : '',
          displayName: id?.display_name ? id?.display_name : ''
        }
        navigate(
          `/video/${id?.video_id}?trailer=${id?.[`seasonTrailerId`]}&data=${btoa(
            objectToQueryString(videoDetails)
          )}`
        )
      } else {
        videoDetails = {
          seriesName: '',
          next_up_id: '',
          seriesDescription: '',
          displayName: id?.display_name ? id?.display_name : ''
        }
        navigate(`/video/${id?.video_id}?data=${btoa(objectToQueryString(videoDetails))}`)
      }
    }
  }

  const getImageSource = (itemToCheck) => {
    if (isVideoItem(itemToCheck)) {
      return useThumbnail
        ? itemToCheck?.images?.['thumbnail']?.src
        : itemToCheck?.images?.['poster']?.src
    } else {
      return itemToCheck.image
    }
  }

  return (
    <>
      <Grid item>
        {direction === 'vertical' && (
          <PaperStyles>
            <Grid
              item
              container
              className='thumbnail'
              justifyContent='center'
              alignItems='center'
              onClick={() => {
                showContentDetailsRoute(direction)
              }}
              style={{ backgroundImage: `url(${getImageSource(item)})` }}
            >
              <PlayArrowRoundedIcon className='play-icon' fontSize='medium' />
            </Grid>
            <Grid>
              <Typography className='label' variant='subtitle1'>
                {item?.name}
              </Typography>
            </Grid>
          </PaperStyles>
        )}
        {direction === 'horizontal' && (
          <PaperStylesHor>
            <Grid
              container
              color={'#fff'}
              gap={3}
              flexWrap={'nowrap'}
              direction={isDuration ? 'row' : 'column'}
            >
              <Grid item>
                <Grid item container xs={2} sm={2} md={6}>
                  <Grid
                    onClick={() => {
                      showContentDetailsRoute(direction)
                    }}
                    xs={1}
                    sm={1}
                    md={2}
                    position='relative'
                  >
                    <Grid>
                      <img className='img-src-poster-hor' src={`${getImageSource(item)}`} />
                    </Grid>
                    <Grid className='play-icon-hor'>
                      <PlayArrowRoundedIcon className='play-icon' fontSize='medium' />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item gap={8} xs={10} sm={10}>
                <Typography className='Poster_item_title-hor'>{item?.['display_name']}</Typography>

                {isDuration && item && isVideoItem(item) && item.duration && (
                  <Typography className='Poster_item_sub_title-hor'>
                    {formatDuration(item?.duration)}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </PaperStylesHor>
        )}
      </Grid>
    </>
  )
}

export default PosterTile
