import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { getApiHeader } from '../../../utils/utils'
import { ConfigParams } from '../../../types/config'
import config from '../../../config'
import Package from '../../../../package.json'

interface Args {
  id?: string
  params?: Record<string, string>
}

export const menuApi = createApi({
  reducerPath: 'menuApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.apiUrl}`,
    prepareHeaders: (headers, { getState }) => {
      headers.set('appversionno', (Package?.version as string) || '')
      return getApiHeader(headers, { getState })
    }
  }),
  endpoints: (builder) => ({
    getMenuApi: builder.query<ConfigParams, Args>({
      query: () => ({
        url: `/config`,
        method: 'GET'
      })
    })
  })
})

export const { useLazyGetMenuApiQuery } = menuApi
