import { useTranslation } from 'react-i18next'
import React, { FC, useEffect, useState } from 'react'
import { styled } from '@mui/system'
import { Grid, Typography } from '@mui/material'

import About from '../VideoDetail/About/About'
import Share from '../Share/Share'
import { VideoDetailSection } from '../../types/section'
import { useGetVideoDetailPageQuery } from '../../store/services/page/pageApi'

interface VideoDetailProps {
  section: VideoDetailSection
}

export const VideoDetailStyle = styled(Grid)(({ theme }) => ({
  color: '#fff',

  '& .content-title': {
    fontFamily: 'Prompt',
    fontWeight: 300,
    fontSize: 40,
    maxWidth: 'fit-content',
    whiteSpace: 'noWrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  '& .rating': {
    background: '#7f7a7a',
    padding: '5px 16px',
    borderRadius: 5,
    maxHeight: 32,
    marginTop: 10
  },
  '& .year': {
    fontSize: 18,
    marginBlock: 5
  },
  '& .content': {
    width: '60%',
    margin: 'auto'
  },
  '& .more-content': {
    backgroundColor: '#1f1f1f',
    paddingBlock: 40,
    '& .more-content-item': {
      width: '100%',
      overflow: 'auto',
      marginLeft: '15%'
    }
  },
  [theme.breakpoints.between('sm', 'md')]: {},
  [theme.breakpoints.down('sm')]: {
    '& .content-header': {
      padding: '0 0px 40px 10px'
    },
    '& .content-title': {
      fontSize: 22
    },
    '& .year': {
      fontSize: 12
    },
    '& .share-icon': {
      flex: 1,
      marginTop: -50
    },
    '& .content': {
      width: '100%'
    },
    '& .About': {
      padding: 0
    },
    '& .About-tab': {
      marginLeft: 0
    },
    '& .more-content': {
      padding: 20,
      '& .more-content-item': {
        marginLeft: '0'
      }
    }
  }
}))
const OneDOriginalDetail: FC<VideoDetailProps> = ({ section, section: { content } }) => {
  const { t } = useTranslation()
  const getQuery = new URLSearchParams(window.location?.search)
  const id = getQuery.get('id')
  const [updatedContent, setUpdatedContent] = useState(content)

  useEffect(() => {
    const fetchDetails = () => {
      try {
        if (id !== null) {
          const { data } = useGetVideoDetailPageQuery({ id })
          setUpdatedContent(data?.sections[1]?.['content'])
        }
      } catch (error) {
        //console
      }
    }
    fetchDetails()
  }, [id])

  useEffect(() => {
    document.title = updatedContent?.display_name || t('app.title')
  }, [updatedContent])

  return (
    <VideoDetailStyle className='video-details'>
      <Grid container justifyContent='space-between' className='content content-header'>
        <Grid item xs={8} justifyContent={'center'}>
          <Grid item container gap={2} alignItems={'center'}>
            <Grid xs={8}>
              <Typography className='content-title' variant='h1'>
                {updatedContent?.display_name}
              </Typography>
            </Grid>
            {updatedContent?.custom_fields['rating'] && (
              <Grid justifyContent={'center'}>
                <Grid bgcolor={'#575757'} borderRadius={2} padding={1}>
                  {updatedContent?.custom_fields['rating']}
                </Grid>
              </Grid>
            )}
            <Grid xs={2}>{updatedContent?.custom_fields['year']}</Grid>
          </Grid>
        </Grid>

        <Grid item xs={4} style={{ flex: 'none' }}>
          <Grid item container>
            <Grid>
              <Share item={updatedContent} checkShare={true} page={'oned_original'}></Share>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item container>
        <Grid item className='content'>
          <About section={section} />
        </Grid>
      </Grid>
    </VideoDetailStyle>
  )
}

export default OneDOriginalDetail
