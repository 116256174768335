import React, { memo, ReactElement, useCallback, useState } from 'react'
import { Person as PersonIcon } from '@mui/icons-material'

interface ProfileImageProps {
  url: string
  description: string
  className?: string
}

const ProfileImage: React.FC<ProfileImageProps> = memo(
  ({ url, description, ...rest }): ReactElement => {
    const [imageError, setImageError] = useState(false)

    const handleError = useCallback(() => {
      setImageError(true)
    }, [])

    return (
      <>
        {imageError ? (
          <PersonIcon fontSize='large' />
        ) : (
          <img src={url} onError={handleError} alt={description} {...rest} />
        )}
      </>
    )
  }
)

export default ProfileImage
