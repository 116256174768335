import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import TagManager from 'react-gtm-module'
import React, { FC } from 'react'
import Cookies from 'js-cookie'
import { Button } from '@mui/material'

import { getStorage } from '../../utils/localStorageSupport'
import { clearToken, setUser } from '../../store/slices/config/configSlice'
import useAuth from '../../hooks/useAuth'

const storage = getStorage()

const SignOutButton: FC = () => {
  const { logout } = useAuth()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const navigate = useNavigate()

  const signOutClick = () => {
    const tagManagerArgs = {
      dataLayer: {
        event: `signout_click`,
        event_category: 'myaccount',
        event_action: `click_button`,
        event_label: 'signout_button'
      }
    }
    TagManager.dataLayer(tagManagerArgs)
  }

  return (
    <div className='profile_menu'>
      <div className='profile_menu_items'>
        <Button
          className='signout-button'
          onClick={() => {
            dispatch(setUser(null))
            dispatch(clearToken())
            signOutClick()
            logout()
            Cookies.remove('authToken', { path: '' })
            navigate('/')
            sessionStorage?.removeItem('shouldNavigateBack')
            storage?.clear()
            setTimeout(() => {
              navigate(0)
            }, 100)
          }}
        >
          {t('header.signout')}
        </Button>
      </div>
    </div>
  )
}

export default SignOutButton
