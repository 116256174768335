import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SignupState {
  step: number;
  emailOrPhone: string;
  passwordOrOtp: string;
  firstName: string;
  lastName: string;
  gender: string;
  birthdate: string;
  loading: boolean;
  error: string | null;
  success: boolean;
}

const initialState: SignupState = {
  step: 1,
  emailOrPhone: '',
  passwordOrOtp: '',
  firstName: '',
  lastName: '',
  gender: '',
  birthdate: '',
  loading: false,
  error: null,
  success: false,
};

const signupSlice = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    signupRequest: (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    },
    signupSuccess: (state) => {
      state.loading = false;
      state.success = true;
    },
    signupFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    setStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
    setEmailOrPhone: (state, action: PayloadAction<string>) => {
      state.emailOrPhone = action.payload;
    },
    setPasswordOrOtp: (state, action: PayloadAction<string>) => {
      state.passwordOrOtp = action.payload;
    },
    setFirstName: (state, action: PayloadAction<string>) => {
      state.firstName = action.payload;
    },
    setLastName: (state, action: PayloadAction<string>) => {
      state.lastName = action.payload;
    },
    setGender: (state, action: PayloadAction<string>) => {
      state.gender = action.payload;
    },
    setBirthdate: (state, action: PayloadAction<string>) => {
      state.birthdate = action.payload;
    },
  },
});

export const { signupRequest, signupSuccess, signupFailure, setStep, setEmailOrPhone, setPasswordOrOtp, setFirstName, setLastName, setGender, setBirthdate } = signupSlice.actions;

export default signupSlice.reducer;
