import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import TagManager from 'react-gtm-module'
import React, { FC, useEffect, useRef } from 'react'
import { Grid } from '@mui/material'
import { Flex } from '@brightcove/studio-components'

import './SearchSection.scss'
import ThumbnailTile from '../Tile/ThumbnailTile'
import Loading from '../Loading/Loading'
import { getPageSize } from '../../utils/utils'
import { SearchSection } from '../../types/section'
import { SearchQueryData } from '../../types/page'
import { VideoContentItem } from '../../types/item'
import { searchFailure, searchSuccess, setQueryResponse } from '../../store/slices/searchSlice'
import { userSelector } from '../../store/slices/config/configSelectors'
import { useLazySearchResultQuery } from '../../store/services/search/searchApi'
import { RootState } from '../../store'

import SearchTile from './SearchTile'

interface SearchProps {
  sectionResult: SearchSection
}

const Search: FC<SearchProps> = ({ sectionResult }) => {
  const { t } = useTranslation()
  const prevCountRef = useRef(0)
  const dispatch = useDispatch()
  const [searchResult]: any = useLazySearchResultQuery()
  const { query, searchResultLoading, pageCount, queryResult } = useSelector(
    (state: RootState) => state.search
  )
  const user = useSelector(userSelector)
  let items, headerName

  if (sectionResult && (query === '' || queryResult === null)) {
    items = sectionResult.items
    headerName = sectionResult.name || ''
  } else {
    items = null
    headerName = ''
  }

  const getSearchQueryResult = async () => {
    try {
      const response = await searchResult({
        q: encodeURIComponent(query),
        page_size: getPageSize(),
        page: pageCount
      })
      if (response?.error) {
        dispatch(searchFailure('' + response.error.data.message))
      } else {
        const tagManagerArgs = {
          dataLayer: {
            event: 'content_search',
            page_name: 'search',
            page_location: location?.href,
            search_key: query,
            search_result: response?.data?.results?.totalCount,
            user_id: user?.['uid']
          }
        }
        TagManager.dataLayer(tagManagerArgs)
        const querySearchResult: VideoContentItem[] = []
        if (queryResult?.results?.items) {
          querySearchResult.push(...[...queryResult.results.items, ...response.data.results.items])
        } else {
          querySearchResult.push(...response.data.results.items)
        }
        const latestResponse: SearchQueryData = {
          lastEvaluatedKey: '',
          results: {
            items: []
          }
        }
        latestResponse.results.items = querySearchResult
        if (
          response?.['data']?.['lastEvaluatedKey'] &&
          response?.['data']?.['lastEvaluatedKey'] !== ''
        ) {
          latestResponse.lastEvaluatedKey = encodeURIComponent(
            JSON.stringify(response['data']?.['lastEvaluatedKey'])
          )
        } else {
          latestResponse.lastEvaluatedKey = ''
        }
        dispatch(setQueryResponse(latestResponse))
        dispatch(searchSuccess(pageCount + 1))
      }
    } catch (err) {
      dispatch(searchFailure('' + err))
    }
  }

  useEffect(() => {
    if (query !== '' && prevCountRef.current == pageCount) {
      getSearchQueryResult()
    }
    const onScroll = () => {
      if (prevCountRef.current != pageCount) {
        prevCountRef.current = pageCount
        getSearchQueryResult()
      }
    }
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [query, pageCount])

  useEffect(
    () => () => {
      if (query === '') {
        prevCountRef.current = 0
      }
    },
    []
  )

  useEffect(() => {
    document.title = 'oneD: ' + t('search.header_search_placeholder')
  }, [])

  if (searchResultLoading && query !== '') {
    return <Loading />
  }

  return (
    <>
      {items && items.length && (
        <Grid className='Search-Grid' flexDirection='column'>
          <label className='Search-Grid-header'>{headerName}</label>
          <div className='Search-Grid-content'>
            <Grid container columns={{ xs: 2, sm: 3, md: 12 }} gap={8}>
              {items.map((item, index) => (
                <SearchTile key={index} item={item} section={items} />
              ))}
            </Grid>
          </div>
        </Grid>
      )}
      <Flex className='Search-Grid-Result' flexDirection='column'>
        {query && (
          <h2 className='Grid-header'>
            <span className='querySearch' style={{ color: 'white' }}>
              {t('search.result_for')}
            </span>
            <span className='querySearch' style={{ color: '#F20B7E' }}>
              &apos;{query}&apos;
            </span>
          </h2>
        )}
        {!items && queryResult?.results.items && queryResult?.results.items.length && (
          <div className='Search-Result-Grid-content'>
            {queryResult?.results.items.map((item, index) => (
              <ThumbnailTile
                key={index}
                item={item}
                showTrailer={true}
                page={'search'}
                onMouseUpIndex={() => index}
              />
            ))}
          </div>
        )}
      </Flex>
    </>
  )
}

export default Search
