import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { VideoContentItem } from '../../types/item';

interface ContinueWatchingState {
    watchLoading: boolean;
    error: string | null;
    success: boolean;
    selectedItem: VideoContentItem | null;
}

const initialState: ContinueWatchingState = {
    watchLoading: false,
    error: null,
    success: false,
    selectedItem: null
};

const continueWatchingSlice = createSlice({
    name: 'continueWatching',
    initialState,
    reducers: {
        continueWatchingStart: (state) => ({
            ...state,
            watchLoading: true,
            error: null,
            success: false,
            selectedItem: null
        }),
        continueWatchingSuccess: (state) => ({
            ...state,
            watchLoading: false,
            error: null,
            success: true,
            selectedItem: null
        }),
        continueWatchingFailure: (state, action: PayloadAction<string>) => ({
            ...state,
            watchLoading: false,
            error: action.payload,
            success: false,
            selectedItem: null
        }),
        saveSelectedItem: (state, action: PayloadAction<VideoContentItem | null>) => ({
            ...state,
            selectedItem: action.payload
        })
    },
});

export const {
    continueWatchingFailure,
    continueWatchingStart,
    continueWatchingSuccess,
    saveSelectedItem
} = continueWatchingSlice.actions;

export default continueWatchingSlice.reducer;
