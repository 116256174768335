import { useDraggable } from 'react-use-draggable-scroll'
import React, { FC, MutableRefObject, useEffect, useRef } from 'react'
import { styled } from '@mui/system'
import { Grid } from '@mui/material'

import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import PosterTile from '../Tile/PosterTile'
import { CarouselSection } from '../../types/section'
import { useLazyGetDynamicStringsQuery } from '../../store/services/dynamicStrings/dynamicStringsApi'
import { googlePublishBannerAds } from '../../hooks/useGooglePublisherTag'
import './FeaturedCarousel.scss'

export const FeatureCarouselStyles = styled('div')(({ theme }) => ({
  '& .feature-carousel-header': {
    fontFamily: 'Prompt',
    fontSize: 30,
    fontWeight: 500,
    marginLeft: 60,
    lineHeight: '45px',
    marginBottom: 10,
    letterSpacing: 0,
    textAlign: 'left',
    color: '#fff'
  },
  '& .feature-carousel-container': {
    backgroundPosition: '0, center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: '410px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginBlock: 20,
    zIndex: 0,
    '& .featured-carousel-content': {
      width: '100%',
      minHeight: 270,
      overflow: 'hidden',
      paddingLeft: '30%'
    },
    '& .poster-tile': {
      overflowX: 'scroll'
    }
  },
  [theme.breakpoints.between('sm', 'md')]: {
    '& .feature-carousel-container': {
      minHeight: 200,
      marginBlock: 0,
      height: 200,
      '& .featured-carousel-content': {
        minHeight: 100,
        display: 'flex',
        height: 180,
        alignItems: 'center'
      },
      '& .poster-tile': {
        height: 'inherit'
      }
    }
  },
  [theme.breakpoints.down('sm')]: {
    '& .feature-carousel-header': {
      fontSize: 20,
      fontWeight: 400,
      marginLeft: 20
    },
    '& .feature-carousel-container': {
      height: 170,
      marginBlock: 0,
      '& .featured-carousel-content': {
        minHeight: 'auto'
      }
    }
  }
}))

interface CarouselProps {
  section: CarouselSection
  page?: string
}

const FeaturedCarousel: FC<CarouselProps> = ({ section, page }) => {
  const [getAdSlotId]: any = useLazyGetDynamicStringsQuery()
  const scrollRef = useRef() as MutableRefObject<any>
  const { events } = useDraggable(scrollRef)

  const getAdSlotIdFromMW = async () => {
    const response = await getAdSlotId({
      q: 'web_banner_ad_slot_id'
    })
    return response
  }

  useEffect(() => {
    const adSlotId = async () => {
      const response = await getAdSlotIdFromMW()
      const adIds = document.getElementsByClassName('banner_div_carousel')
      if (adIds != null && adIds.length > 0) {
        const ids: string[] = []
        for (let i = 0; i < adIds.length; i++) {
          const adDiv = adIds[i]
          ids.push(adDiv.id)
        }
        if (response && response.data && response.data.results && response.data.results.items) {
          if (response.data.results.items.length > 0) {
            googlePublishBannerAds(response.data.results.items[0].value, ids)
          }
        }
      }
    }
    adSlotId()
  }, [section])

  useEffect(() => {
    const el: any = scrollRef.current
    if (el) {
      const onWheel = (e) => {
        if (e.deltaY === 0 || Math.abs(e.deltaY) > Math.abs(e.deltaX)) return
        e.preventDefault()
      }
      el.addEventListener('wheel', onWheel)
      return () => el.removeEventListener('wheel', onWheel)
    }
  }, [])

  return (
    <FeatureCarouselStyles>
      {section.name && <h2 className='feature-carousel-header'>{section.name}</h2>}
      <div
        className='feature-carousel-container'
        style={{
          backgroundImage: `${
            section.image
              ? `url(${section.image})`
              : 'linear-gradient(90deg, rgba(2,0,36,1) 0%, #0f0f0f 100%)'
          }`
        }}
      >
        <div className='featured-carousel-content'>
          <Grid
            container
            spacing={2}
            className='poster-tile'
            flexWrap='nowrap'
            ref={scrollRef}
            {...events}
          >
            {section.items?.map((item, index) => (
              <PosterTile
                key={index}
                item={item}
                direction={'horizontal'}
                useThumbnail={true}
                page={page || 'homepage'}
                section={section}
              />
            ))}
          </Grid>
        </div>
      </div>
      {section.showAds && <div className='banner_div_carousel' id={'bannerDiv' + section.id}></div>}
    </FeatureCarouselStyles>
  )
}

export default FeaturedCarousel
