import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ForgotPasswordState {
    loading: boolean;
    error: string | null;
    success: boolean;
}

const initialState: ForgotPasswordState = {
    loading: false,
    error: null,
    success: false,
};

const forgotPasswordSlice = createSlice({
    name: 'forgotPassword',
    initialState,
    reducers: {
        forgotPasswordStart: (state) => {
            state.loading = true;
            state.error = null;
            state.success = false;
        },
        forgotPasswordSuccess: (state) => {
            state.loading = false;
            state.error = null;
            state.success = true;
        },
        forgotPasswordFailure: (state, action: PayloadAction<string>) => {
            state.loading = false;
            state.error = action.payload;
            state.success = false;
        },
        resetForgotPasswordState: (state) => {
            state.loading = false;
            state.error = null;
            state.success = false;
        },
    },
});

export const {
    forgotPasswordStart,
    forgotPasswordSuccess,
    forgotPasswordFailure,
    resetForgotPasswordState,
} = forgotPasswordSlice.actions;

export default forgotPasswordSlice.reducer;
