import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { getApiHeader } from '../../../utils/utils'
import { ConfigParams } from '../../../types/config'
import config from '../../../config'

interface Args {
  user_id: string
  content_id: string
}

export const entitlementApi = createApi({
  reducerPath: 'entitlementApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.apiUrl}`,
    prepareHeaders: (headers, { getState }) => getApiHeader(headers, { getState }, true)
  }),
  endpoints: (builder) => ({
    checkEntitlementApi: builder.query<ConfigParams, Args>({
      query: (body) => ({
        url: `/user/check_entitlement`,
        method: 'POST',
        body
      })
    })
  })
})

export const { useLazyCheckEntitlementApiQuery } = entitlementApi
