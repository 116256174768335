const eventBus = {
  on(event: string, callback: (arg0: any) => any): void {
    document.addEventListener(event, (e) => callback(e))
  },
  dispatch(event: string, data: { message: any }): void {
    document.dispatchEvent(new CustomEvent(event, { detail: data }))
  },
  remove(event: string, callback: (arg0: any) => any | undefined): void {
    document.removeEventListener(event, callback)
  }
}

export default eventBus
