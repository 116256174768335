import { useTranslation } from 'react-i18next'
import TagManager from 'react-gtm-module'
import React, { FC } from 'react'
import classnames from 'classnames'

import { useLocalStorage } from '../../hooks/UIHooks'
import { LANGUAGES } from '../../constants'

import './LanguageSelector.scss'

const LanguageSelector: FC = () => {
  const { i18n } = useTranslation()
  const [language, setLanguage] = useLocalStorage('i18nextLng')
  const changeLanguage = (lang: string): void => {
    setLanguage(lang)
    i18n.changeLanguage(lang)
  }

  const tagManagerClick = (language) => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'header_menu',
        event_category: 'header_menu',
        event_action: 'change_language',
        event_label: language
      }
    }
    TagManager.dataLayer(tagManagerArgs)
  }

  return (
    <ul className='flex lang-selector'>
      {LANGUAGES.map((lang) => (
        <React.Fragment key={lang}>
          <li
            className={classnames('flex', {
              active_th: 'th' === language && lang === 'th',
              active_en: 'en' === language && lang === 'en'
            })}
            onClick={() => {
              const currentSel = LANGUAGES.indexOf(language)
              const selIndex = currentSel === LANGUAGES.length - 1 ? 0 : currentSel + 1
              changeLanguage(LANGUAGES[selIndex])
              tagManagerClick(LANGUAGES[selIndex])
            }}
          >
            {lang.toUpperCase()}
          </li>
          <li className='lang-separator'>/</li>
        </React.Fragment>
      ))}
    </ul>
  )
}

export default LanguageSelector
