import { styled } from '@mui/system'
import { Dialog } from '@mui/material'

export const DialogStyles = styled(Dialog)(({ theme }) => ({
  zIndex: 1,
  '& .MuiDialog-container': {
    alignItems: 'end',
    justifyContent: 'flex-end'
  },
  '& .MuiDialog-paper': {
    background: `linear-gradient(90deg, #101010 20%, #484848 100%)`,
    padding: 20,
    color: '#fff',
    boxShadow: '0 0 0 2px #707070',
    '& .cookie_disclaimer': {
      fontWeight: 500
    },
    '& .MuiButton-root': {
      width: '90%',
      height: 40,
      fontSize: 14,
      borderRadius: 12,
      textWrap: 'nowrap',
      '&.cookie-button': {
        color: '#101010',
        backgroundColor: '#d9d9d9',
        fontFamily: 'Prompt',
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '21px',
        padding: '20px'
      },
      '&.confirm-choice': {
        background: 'rgba(0,0,0,0)',
        fontFamily: 'Prompt',
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '21px',
        paddingTop: '35px'
      }
    },
    '&:hover': {}
  },
  '.d-web': {
    display: 'block'
  },
  '.d-mob': {
    display: 'none'
  },
  //   .privacy_settings_holder {
  //   flex-wrap: wrap;
  //   position: absolute;
  //   top: 0;
  //   height: 100%;
  //   width: 100%;
  //   background-repeat: repeat;
  //   align-items: center;
  //   justify-content: center;
  //   pointer-events: all;
  // }

  [theme.breakpoints.down('sm')]: {
    '& .privacy-content': {
      flexDirection: 'column',
      '& > div:last-child': {
        marginTop: 30
      }
    },
    '& .MuiDialog-paper': {
      padding: '0 10px'
    },
    '.cookie_disclaimer': {
      display: 'inline'
    },
    '.d-web': {
      display: 'none'
    },
    '.d-mob': {
      display: 'block'
    },
    '.read-more': {
      display: 'inline'
    },
    '.mob-inline': {
      display: 'flex',
      flexDirection: 'row !important'
    },
    '.confirm-choice': {
      paddingTop: '0 !important',
      marginLeft: 20,
      lineHeight: 'normal !important'
    }
  }
}))
