export const googlePublishCompanionAds = (companionAdSlotId: string, id: string): void => {
  window.googletag = window.googletag || { cmd: [] }
  window.googletag.cmd.push(() => {
    window.googletag.destroySlots()
    // Define an ad slot for div with id "banner-ad".
    // window.googletag.pubads().refresh()
    const responsiveAdSlot = window.googletag
      .defineSlot(
        companionAdSlotId,
        [
          [970, 90],
          [728, 90],
          [320, 100],
          [320, 50]
        ],
        id
      )
      ?.addService(window.googletag.companionAds())
      ?.addService(window.googletag.pubads())
    const mapping = window.googletag
      .sizeMapping()
      .addSize(
        [970, 0],
        [
          [970, 90],
          [728, 90]
        ]
      )
      .addSize(
        [320, 0],
        [
          [320, 100],
          [320, 50]
        ]
      )
      .addSize([0, 0], [])
      .build()
    if (mapping) {
      responsiveAdSlot?.defineSizeMapping(mapping)
    }
    window.googletag.pubads().enableVideoAds()

    window.googletag.companionAds().setRefreshUnfilledSlots(true)
    // Enable the CompanionAdsService.
    window.googletag.enableServices()
    // Request and render an ad for the "banner-ad" slot.
    window.googletag.display(id)
  })
}

export const googlePublishBannerAds = (bannerAdSlotId: string, arrIds: string[]): void => {
  window.googletag = window.googletag || { cmd: [] }
  googletag.cmd.push(() => {
    googletag.destroySlots()
    googletag.pubads().enableVideoAds()
    // Enable the PubAdsService.
    googletag.enableServices()
    if (arrIds.length > 0) {
      for (let i = 0; i < arrIds.length; i++) {
        const responsiveAdSlot = googletag
          .defineSlot(
            bannerAdSlotId,
            [
              [970, 90],
              [728, 90],
              [320, 100],
              [320, 50]
            ],
            arrIds[i]
          )
          ?.addService(googletag.pubads())
        const mapping = googletag
          .sizeMapping()
          .addSize([970, 0], [[970, 90]])
          .addSize([728, 0], [[728, 90]])
          .addSize(
            [320, 0],
            [
              [320, 100],
              [320, 50]
            ]
          )
          .addSize([0, 0], [])
          .build()
        if (mapping) {
          responsiveAdSlot?.defineSizeMapping(mapping)
        }
        // Request and render an ad for the "banner-ad" slot.
        googletag.display(arrIds[i])
      }
    }
  })
}

export const googlePublishBannerAdsForPlayer = (bannerAdSlotId: string, arrIds: string[]): void => {
  window.googletag = window.googletag || { cmd: [] }
  googletag.cmd.push(() => {
    //googletag.destroySlots()
    googletag.pubads().enableVideoAds()
    // Enable the PubAdsService.
    googletag.enableServices()
    if (arrIds.length > 0) {
      for (let i = 0; i < arrIds.length; i++) {
        const responsiveAdSlot = googletag
          .defineSlot(
            bannerAdSlotId,
            [
              [970, 90],
              [728, 90],
              [320, 100],
              [320, 50]
            ],
            arrIds[i]
          )
          ?.addService(googletag.pubads())
        const mapping = googletag
          .sizeMapping()
          .addSize([970, 0], [[970, 90]])
          .addSize([728, 0], [[728, 90]])
          .addSize(
            [320, 0],
            [
              [320, 100],
              [320, 50]
            ]
          )
          .addSize([0, 0], [])
          .build()
        if (mapping) {
          responsiveAdSlot?.defineSizeMapping(mapping)
        }
        // Request and render an ad for the "banner-ad" slot.
        googletag.display(arrIds[i])
      }
    }
  })
}
