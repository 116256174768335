import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {
  isSuccess: false
}

const activationSlice = createSlice({
  name: 'activation',
  initialState,
  reducers: {
    setActivationSuccess: (state, action: PayloadAction<boolean>) => {
      state.isSuccess = action.payload
    }
  }
})

export const { setActivationSuccess } = activationSlice.actions

export default activationSlice.reducer
