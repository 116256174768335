import { useTranslation } from 'react-i18next'
import TagManager from 'react-gtm-module'
import { FC, useEffect } from 'react'
import { differenceInYears } from 'date-fns'

import { getStorage } from '../../../utils/localStorageSupport'
import { useLazyGetUserProfileQuery } from '../../../store/services/user/userProfileApi'

interface SignInGTMEventsParams {
  event: string
  page_title?: string
  page_location?: string
  page_referrer?: string
  source?: string
  medium?: string
  campaign?: string
  user_id?: string
  event_category?: string
  event_action?: string
  event_label?: string
  signin_type?: string | undefined
  error_message?: string | undefined
  signin_typel?: string | undefined
  user_email?: string | undefined
  user_phone?: string | undefined
  user_gender?: string | undefined
  user_age?: string | number | undefined
}

interface GTMEventTriggerProps {
  onSignUp?: any
  isSignInSuccessful?: boolean
  signInType?: string | undefined
  isSignInUnsuccessful?: boolean
  errorMessage?: string | undefined
  isSignInForgotPassword?: boolean
  isSignInSocial?: boolean
  isSignUpButton?: boolean
}

const SignInGTMEvents: FC<GTMEventTriggerProps> = ({
  isSignInSuccessful,
  signInType,
  isSignInUnsuccessful,
  errorMessage,
  isSignInForgotPassword,
  isSignInSocial,
  isSignUpButton
}) => {
  const { t } = useTranslation()

  const sha256 = async (message: string | undefined) => {
    const msgUint8 = new TextEncoder().encode(message)
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8)
    const hashArray = Array.from(new Uint8Array(hashBuffer))
    const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('')
    return hashHex
  }
  const storage = getStorage()
  const uid = storage.getItem('uid')
  const [getProfileData] = useLazyGetUserProfileQuery()

  const calculateAge = (dob: string): any => {
    if (!dob) ''
    differenceInYears(new Date(), new Date(dob))
  }

  const pageView = (params: SignInGTMEventsParams) => {
    const tagManagerArgs = {
      dataLayer: {
        ...params
      }
    }
    return TagManager.dataLayer(tagManagerArgs)
  }

  const signInSuccessful = (params: SignInGTMEventsParams) => {
    const tagManagerArgs = {
      dataLayer: {
        ...params
      }
    }
    return TagManager.dataLayer(tagManagerArgs)
  }

  const signInUnsuccessful = (params: SignInGTMEventsParams) => {
    const tagManagerArgs = {
      dataLayer: {
        ...params
      }
    }
    return TagManager.dataLayer(tagManagerArgs)
  }
  const signInForgotPassword = (params: SignInGTMEventsParams) => {
    const tagManagerArgs = {
      dataLayer: {
        ...params
      }
    }

    return TagManager.dataLayer(tagManagerArgs)
  }

  const signInSocial = (params: SignInGTMEventsParams) => {
    const tagManagerArgs = {
      dataLayer: {
        ...params
      }
    }
    return TagManager.dataLayer(tagManagerArgs)
  }

  const signUpButton = (params: SignInGTMEventsParams) => {
    const tagManagerArgs = {
      dataLayer: {
        ...params
      }
    }
    return TagManager.dataLayer(tagManagerArgs)
  }

  const signUp = () => {
    const currentLocation = window.location.href

    pageView({
      event: 'page_view',
      page_title: t('login.sign_in') || '',
      page_location: currentLocation,
      page_referrer: '',
      source: '',
      medium: '',
      campaign: ''
    })
  }

  useEffect(() => {
    signUp()
  }, [])

  useEffect(() => {
    const SignInSuccessful = async () => {
      if (isSignInSuccessful) {
        const profile = await getProfileData()
        const userData = profile?.data
        const hashedEmail = await sha256(userData?.email)
        const hashedPhone = await sha256(userData?.phone)
        signInSuccessful({
          event: 'signin_successful',
          event_category: 'signin',
          event_action: 'click_button',
          event_label: 'signin_successful',
          signin_typel: signInType,
          user_id: uid,
          user_email: hashedEmail,
          user_phone: hashedPhone,
          user_gender: userData?.gender,
          user_age: calculateAge(userData?.date_of_birth as string)
        })
      }
    }
    SignInSuccessful()
  }, [isSignInSuccessful])

  useEffect(() => {
    if (isSignInUnsuccessful) {
      signInUnsuccessful({
        event: 'signin_unsuccessful',
        event_category: 'signin',
        event_action: 'click_button',
        event_label: 'signin_unsuccessful',
        error_message: errorMessage
      })
    }
  }, [isSignInUnsuccessful])

  useEffect(() => {
    if (isSignInForgotPassword) {
      signInForgotPassword({
        event: 'signin_forgot_password',
        event_category: 'signin',
        event_action: 'click_button',
        event_label: 'forgot_password'
      })
    }
  }, [isSignInForgotPassword])

  useEffect(() => {
    if (isSignInSocial) {
      signInSocial({
        event: `signin_select_${signInType}`,
        event_category: 'signin',
        event_action: 'click_button',
        event_label: signInType,
        signin_type: signInType
      })
    }
  }, [isSignInSocial])

  useEffect(() => {
    if (isSignUpButton) {
      signUpButton({
        event: 'signup_button',
        event_category: 'signin',
        event_action: 'click_button',
        event_label: 'signup'
      })
    }
  }, [isSignUpButton])

  return null
}
export default SignInGTMEvents
