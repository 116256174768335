import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet-async'
import React, { createElement, FC, useMemo } from 'react'
import parse from 'html-react-parser'

import { HeadData } from '../../types/page'

interface PageHeadProps {
  data?: HeadData
}

const PageHead: FC<PageHeadProps> = ({ data }) => {
  const { t } = useTranslation()

  const elements = useMemo(
    () =>
      data?.tags?.map((item, index) => {
        const tagAttrs = Object.keys(item)
          .filter((key) => key !== '_tag' && key !== '_body')
          .reduce((attrs, key) => ({ ...attrs, [key]: item[key] }), {})

        if (item._tag === 'title') {
          return createElement(item._tag, { key: index }, item._body)
        }

        return createElement(item?._tag, { key: index, ...tagAttrs })
      }),
    [data]
  )

  const htmlBlock = useMemo(
    () => (data?.htmlBlock ? parse(data?.htmlBlock) : parse('')),
    [data?.htmlBlock]
  )

  const combinedElements = useMemo(() => {
    if (htmlBlock) {
      if (elements && elements.length > 0) {
        return [...elements, ...(Array.isArray(htmlBlock) ? htmlBlock : [htmlBlock])]
      } else {
        return [htmlBlock]
      }
    } else {
      return elements
    }
  }, [elements, htmlBlock])

  return <Helmet>{!data ? <title>{t('app.title')}</title> : combinedElements}</Helmet>
}

export default PageHead
