import { useNavigate } from 'react-router'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import TagManager from 'react-gtm-module'
import React, { FC, useEffect, useState } from 'react'
import { Button, DialogActions, DialogContent } from '@mui/material'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { Flex } from '@brightcove/studio-components'

import { MarketingCampaignData } from '../../types/marketingCampaign'
import { RootState } from '../../store'
import { useLocalStorage } from '../../hooks/UIHooks'

import { DialogStyle } from './Marketing.style'

import './Marketing.scss'

interface MarketingProps {
  campaigns: MarketingCampaignData[]
  onClickClose: { (): void }
}

const Marketing: FC<MarketingProps> = ({ campaigns, onClickClose }) => {
  const { t } = useTranslation()
  const [marketingIds, setMarketingIds] = useLocalStorage('dont_show_marketing')
  const [showStatus, setShowStatus] = useState(false)
  const navigate = useNavigate()
  const { data: userData } = useSelector((state: RootState) => state.userProfile)
  const [shouldRender, setShouldRender] = useState(true)

  const dismissPopUp = () => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'popup_exit',
        event_category: 'homepage',
        event_action: 'click_button',
        event_label: campaigns[0]?.title,
        popup_name: campaigns[0]?.title,
        popup_label: campaigns[0]?.link,
        popup_config: showStatus === true ? 'yes' : 'no'
      }
    }
    TagManager.dataLayer(tagManagerArgs)
    onClickClose()
  }

  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'popup_impression',
        event_category: 'homepage',
        event_action: 'impression',
        event_label: campaigns[0]?.title,
        popup_name: campaigns[0]?.title
        // user_id: user?.uid
      }
    }
    TagManager.dataLayer(tagManagerArgs)
  }, [])

  const navigateToDetails = async (campaign) => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'popup_click',
        event_category: 'homepage',
        event_action: 'click_card',
        event_label: campaigns[0]?.title,
        popup_name: campaigns[0]?.title,
        popup_label: campaigns[0]?.link,
        popup_config: showStatus === true ? 'yes' : 'no'
      }
    }
    TagManager.dataLayer(tagManagerArgs)

    const campaignDescription = campaign?.description
    if (campaignDescription?.toLowerCase()?.includes('original')) {
      return navigate('/oned/original')
    } else if (campaignDescription?.toLowerCase()?.includes('premium')) {
      return navigate('/oned/premium')
    } else {
      return window.open(campaign.link, '_blank')
    }
  }

  const onClickDontShow = (id) => {
    setShowStatus(!showStatus)
    const currentIdArray = marketingIds ? JSON.parse(marketingIds) : []
    if (currentIdArray.includes(id)) {
      currentIdArray.splice(currentIdArray.indexOf(id), 1)
    } else {
      currentIdArray.push(id)
    }
    setMarketingIds(JSON.stringify(currentIdArray))
  }
  useEffect(() => {
    const hasActiveSubscription =
      userData?.subscription?.subscription_status?.toLowerCase() === 'active'

    const isPremiumCampaign = campaigns[0]?.description?.toLowerCase()?.includes('premium')

    if (isPremiumCampaign && hasActiveSubscription) {
      setShouldRender(false)
    }
  }, [userData, campaigns])

  return (
    <>
      {shouldRender && (
        <DialogStyle open maxWidth='md' onClose={dismissPopUp}>
          <DialogContent>
            <Flex justifyContent='center' className='campaign_holder'>
              <img
                onClick={() => {
                  navigateToDetails(campaigns[0])
                }}
                className='marketing-img-thumbnail'
                src={campaigns[0].image}
              />
            </Flex>
          </DialogContent>
          <DialogActions>
            <Flex justifyContent='space-between' className='action-button-wrapper'>
              <Flex alignItems='center'>
                <label className='custom-checkbox'>
                  <input type='checkbox' onChange={() => onClickDontShow(campaigns[0].id)} />
                  <span></span>
                </label>
                <label className='custom-checkbox-label'>{t('home.dont_show_again')}</label>
              </Flex>

              <Button onClick={() => dismissPopUp()} startIcon={<CloseOutlinedIcon />}>
                {t('home.close')}
              </Button>
            </Flex>
          </DialogActions>
        </DialogStyle>
      )}
    </>
  )
}

export default Marketing
