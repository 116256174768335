import { useTranslation } from 'react-i18next'
import React from 'react'
import { KeyboardArrowRight } from '@mui/icons-material'

interface ProfileMenuItemsI {
  id: number
  value?: string | null
  name?: string | null
  link?: string | undefined
  icon?: JSX.Element
  isComponent?: boolean
  isMobileHide?: boolean
}

export const ProfileMenuItems = (): ProfileMenuItemsI[] => {
  const { t } = useTranslation()
  return [
    {
      id: 1,
      value: t('profile.subscription'),
      name: 'subscription',
      link: '/profile/subscription',
      icon: <KeyboardArrowRight />
    },
    {
      id: 2,
      value: t('profile.profile'),
      name: 'profile',
      link: '/profile/profile-detail',
      icon: <KeyboardArrowRight />,
      isMobileHide: true
    },
    {
      id: 3,
      value: t('profile.notifications'),
      name: 'notification',
      link: '/profile/notification',
      icon: <KeyboardArrowRight />
    },
    {
      id: 4,
      value: t('profile.my_list'),
      name: 'mylist',
      link: '/my-list',
      icon: <KeyboardArrowRight />
    },
    {
      id: 5,
      value: t('profile.continue_watching'),
      name: 'continue_watching',
      link: '/continue-watching',
      icon: <KeyboardArrowRight />
    }
  ]
}
