import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface EntitlementState {
  isNonValidEntitlement: boolean
  successEntitlement: {
    subscription_end_date: string
    subscription_status: string
    user_id: string
    content_id: string
    features: {
      isAndroidTvLivePlayback: boolean
      liveSsaiAds: boolean
      isCompanionAds: boolean
      isAndroidTvAppAvailable: boolean
      vodCsaiAds: boolean
      isPIPEnabled: boolean
      isAds: boolean
      isAndroidTvVodPlayback: boolean
      maxResolutionSupported: number
      concurrency: number
      isCastingEnabled: boolean
      isPlaybackSpeed: boolean
    }
  } | null
}

const initialState: EntitlementState = {
  isNonValidEntitlement: false,
  successEntitlement: null
}

const entitlementSlice = createSlice({
  name: 'entitlement',
  initialState,
  reducers: {
    updateEntitlement: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isNonValidEntitlement: action.payload
    }),
    updateSuccessEntitlement: (state, action: PayloadAction<any>) => ({
      ...state,
      successEntitlement: action.payload
    })
  }
})

export const { updateEntitlement, updateSuccessEntitlement } = entitlementSlice.actions

export default entitlementSlice.reducer
