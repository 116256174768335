import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { getApiHeader } from '../../../utils/utils'
import config from '../../../config'

interface ValidateQACodeI {
  activation_code: string
  expiry?: number
}

export const activationApi = createApi({
  reducerPath: 'activationApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.apiUrl}`,
    prepareHeaders: (headers, { getState }) => getApiHeader(headers, { getState }, true)
  }),
  endpoints: (builder) => ({
    validateQACode: builder.mutation<ValidateQACodeI, Partial<ValidateQACodeI>>({
      query: (body) => ({
        url: `/user/device/activation_code/validate`,
        method: 'POST',
        body
      })
    })
  })
})

export const { useValidateQACodeMutation } = activationApi
