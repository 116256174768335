import { useLocation, useNavigate } from 'react-router'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import TagManager from 'react-gtm-module'
import React, { FC, useEffect } from 'react'
import { Flex } from '@brightcove/studio-components'

import { getStorage } from '../../utils/localStorageSupport'
import { RootState } from '../../store'
import { useBreakPoints } from '../../hooks/useBreakPoints'
import { useLocalStorage } from '../../hooks/UIHooks'

import Sidebar from './Sidebar'
import ProfileContent from './Components/ProfileContent'

import './ProfilePage.scss'

const ProfilePage: FC = () => {
  const { isMobile } = useBreakPoints()
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { isSmartBannerClosed } = useSelector((state: RootState) => state.notification)
  const storage = getStorage()
  const uid = storage.getItem('uid')

  useEffect(() => {
    document.title = t('app.title')
    const tagManagerArgs = {
      dataLayer: {
        event: 'page_view',
        page_title: 'myaccount',
        page_location: window.location.href,
        member_id: uid,
        user_id: uid
      }
    }
    TagManager.dataLayer(tagManagerArgs)
  }, [])

  useEffect(() => {
    if (location.pathname === '/profile/profile-detail') {
      const parentElement = document.getElementsByClassName('content')[0] as HTMLElement
      parentElement.style.background = '#222222'
    }
  })

  const [token] = useLocalStorage('token')
  if (token === undefined) navigate('/login')

  return (
    <Flex
      className={`profile-page ${isMobile ? 'isMobile' : ''}`}
      style={{
        transform: isMobile && !isSmartBannerClosed ? 'translateY(175px)' : 'translateY(84px)'
      }}
    >
      <Flex className='profile-page-wrapper' justifyContent={'space-between'}>
        <Sidebar />
        {!isMobile && <ProfileContent />}
      </Flex>
    </Flex>
  )
}

export default ProfilePage
