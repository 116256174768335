import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ContentSeasonData } from '../../types/season'
import { SectionData } from '../../types/page'

interface ContentState {
  contentLoading: boolean
  error: string | null
  success: boolean
  items: ContentSeasonData[] | undefined
  sectionResult: SectionData | null
}

const initialState: ContentState = {
  contentLoading: false,
  error: null,
  success: false,
  items: undefined,
  sectionResult: null
}

const contentSlice = createSlice({
  name: 'content',
  initialState,
  reducers: {
    contentStart: (state) => ({
      ...state,
      contentLoading: true,
      error: null,
      success: false,
      items: undefined
    }),
    setSectionResponse: (state, action: PayloadAction<SectionData | null>) => ({
      ...state,
      sectionResult: action.payload
    }),
    contentSuccess: (state, action: PayloadAction<ContentSeasonData[] | undefined>) => ({
      ...state,
      contentLoading: false,
      error: null,
      success: true,
      items: action.payload
    }),
    contentFailure: (state, action: PayloadAction<string>) => ({
      ...state,
      contentLoading: false,
      error: action.payload,
      success: false
    })
  }
})

export const { contentFailure, contentStart, contentSuccess, setSectionResponse } =
  contentSlice.actions

export default contentSlice.reducer
