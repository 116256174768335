import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import React, { FC, useEffect, useState } from 'react'
import Cookies from 'js-cookie'
import { isValid, parse } from 'date-fns'
import CircularProgress from '@mui/material/CircularProgress'
import { Button, Grid, Typography } from '@mui/material'
import CalendarTodayOutlined from '@mui/icons-material/CalendarTodayOutlined'
import { Form } from '@brightcove/studio-components'

import InputField from '../Common/Input/Input'
import GenderGroup from '../Common/GenderGroup/GenderGroup'
import SignUpGTMEvents from '../Common/GTM/SignUpGTMEvents'
import DialogMaxChars from '../Common/DialogMaxChars/DialogMaxChars'
import { getStorage } from '../../utils/localStorageSupport'
import {
  setBirthdate,
  setFirstName,
  setGender,
  setLastName,
  setStep
} from '../../store/slices/signupSlice'
import { useUpdateUserProfileMutation } from '../../store/services/signup/signupApi'
import { RootState } from '../../store/index'
import { useBreakPoints } from '../../hooks/useBreakPoints'
import useAuth from '../../hooks/useAuth'
import { charsMaxLength } from '../../constants'

import { StepTwoFormData } from './types'
import DateDropdown from './Components/DateDropdown/DateDropdown'

const StepTwo: FC = () => {
  const { t } = useTranslation()
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue
  } = useForm<StepTwoFormData>()
  const dispatch = useDispatch()
  const { signup } = useAuth()
  const navigate = useNavigate()
  const { isMobile } = useBreakPoints()

  const [updateUserProfile] = useUpdateUserProfileMutation()

  const email = useSelector((state: RootState) => state.signup.emailOrPhone)
  const password = useSelector((state: RootState) => state.signup.passwordOrOtp)
  const { dayDisable } = useSelector((state: RootState) => state.userProfile)

  const [dateOfBirth, setDateOfBirth] = useState<Date | any>(null)
  const [isDisabled, setIsDisabled] = useState(true)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const [isGTMSignUpConfirmStep2, setIsGTMSignUpConfirmStep2] = useState<boolean>(false)
  const storage = getStorage()

  const onSubmit = async (data: any) => {
    setIsLoading(true)
    setIsDisabled(true)
    const { firstName, lastName, gender, dateOfBirth: date_of_birth } = data
    dispatch(setFirstName(firstName))
    dispatch(setLastName(lastName))
    dispatch(setGender(gender))
    dispatch(setBirthdate(date_of_birth))
    const isPhoneNumber = /^(\+?\d{10,})$/
    const authToken = Cookies.get('authToken')

    const newObj = {
      firstName,
      lastName,
      date_of_birth,
      gender,
      email: email ? email : undefined,
      phone: isPhoneNumber.test(email) ? email : undefined
    }

    if (
      validInput.firstName.length > charsMaxLength ||
      validInput.lastName.length > charsMaxLength
    ) {
      setIsDialogOpen(true)
      return
    }

    try {
      if (isPhoneNumber.test(email)) {
        const taiPhoneNumber = email.startsWith('0') ? email.replace('0', '+66') : email
        newObj.phone = taiPhoneNumber
        delete newObj.email
        if (authToken) {
          const signupResponse = await updateUserProfile(newObj)
          if (signupResponse) {
            setIsGTMSignUpConfirmStep2(true)
            setTimeout(() => {
              dispatch(setStep(3))
            }, 0)
          }
        }
        return
      } else {
        delete newObj.phone
      }
      const signupResponse = await signup(email, password)
      const currentUserDetail = signupResponse.user
      if (currentUserDetail) {
        const token = await currentUserDetail.getIdToken()
        storage?.setItem('token', token)
        Cookies.set('authToken', token)
        if (token) {
          const signupResponse = await updateUserProfile(newObj)
          if (signupResponse) {
            setIsGTMSignUpConfirmStep2(true)
            setTimeout(() => {
              dispatch(setStep(3))
            }, 0)
          }
        }
      }
    } catch (error) {
      setIsLoading(false)
      setIsDisabled(false)
    }
  }

  useEffect(() => {
    document.body.className = 'body-white'
    return () => {
      document.body.className = ''
    }
  })

  const onGenderChange = (gender: string) => {
    setValue('gender', gender)

    setValidInput({
      ...validInput,
      gender: gender
    })
  }

  const onDateChange = (day: number | null, month: number | null, year: number | null) => {
    if (day && month && year) {
      setDateOfBirth(new Date(year, month - 1, day))

      const dateString = `${year}-${month}-${day}`
      const dateParse = parse(dateString, 'yyyy-M-d', new Date())
      isValid(dateParse)

      if (isValid(dateParse)) {
        setValidInput({
          ...validInput,
          dob: new Date(year, month - 1, day)
        })
      }
    } else {
      setDateOfBirth(null)
    }
  }

  useEffect(() => {
    if (dateOfBirth !== null) setValue('dateOfBirth', dateOfBirth.toDateString())
  }, [dateOfBirth])

  useEffect(() => {
    // function for handling hash change in browser, toggling modal open
    // const handleOnHashChange = () => {
    //   if (window.location.hash !== '#date') {
    //     setIsDatePickerOpen(false)
    //   }
    // }
    // event listener for hashchange event
    // window.addEventListener('hashchange', handleOnHashChange)
    // return () => window.removeEventListener('hashchange', handleOnHashChange)
  }, ['#date'])

  const [validInput, setValidInput] = useState<any>({})

  useEffect(() => {
    setIsDisabled(
      Object.keys(validInput).length === 4 && Object.values(validInput).every((val) => val !== '')
    )
  }, [setIsDisabled, validInput])

  useEffect(() => {
    if (dayDisable) {
      setIsDisabled(!dayDisable)
    }
  }, [setIsDisabled, dayDisable])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const onDialogClose = () => {
    setIsDialogOpen(false)
    setIsLoading(false)
    setIsDisabled(true)
  }

  return (
    <div>
      <SignUpGTMEvents isSignUpConfirmStep2={isGTMSignUpConfirmStep2} />
      <h1 className='info-header'>{t('signup.user_information') || ''}</h1>
      <Form className='signup-form' onSubmit={handleSubmit(onSubmit)}>
        <Grid container width={isMobile ? 358 : '85%'} spacing='10'>
          <Grid item xs={12}>
            <InputField
              label={t('signup.first_name') || ''}
              placeholder={t('signup.first_name') || ''}
              name='firstName'
              register={register}
              errors={errors}
              onSelect={(e: any) =>
                setValidInput({
                  ...validInput,
                  firstName: e.target?.value
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <InputField
              label={t('signup.last_name') || ''}
              placeholder={t('signup.last_name') || ''}
              name='lastName'
              register={register}
              errors={errors}
              onSelect={(e: any) =>
                setValidInput({
                  ...validInput,
                  lastName: e.target?.value
                })
              }
            />
          </Grid>
          <Grid
            item
            xs={12}
            spacing='2'
            container
            direction='row'
            justifyContent='flex-end'
            style={{ marginTop: 10 }}
          >
            <Grid item xs={11}>
              <GenderGroup onGenderChange={onGenderChange} isLabel />
            </Grid>
            <Grid item xs={1} />
          </Grid>

          <Grid
            item
            xs={12}
            container
            direction='row'
            justifyContent='flex-end'
            alignItems='center'
            style={{ marginTop: 10 }}
          >
            <Grid item xs={11}>
              <DateDropdown onDateChange={onDateChange} isLabel />
            </Grid>
            <Grid item xs={1}>
              <CalendarTodayOutlined
                style={{
                  fill: '#f20b7e',
                  transform: 'scale(.45) translate(-30px, 30px)',
                  height: 75,
                  width: 75
                }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              container
              justifyContent='space-between'
              spacing={2}
              style={{ marginTop: 10 }}
            >
              <Grid item xs={6}>
                <Button
                  type='button'
                  onClick={() => {
                    dispatch(setStep(1))
                    navigate('/signup')
                  }}
                  className='back-button'
                >
                  <Typography className='button_font_family'>{t('signup.back') || ''}</Typography>
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  type='submit'
                  className={`signup-button ${!isDisabled ? 'disabled' : ''}`}
                  endIcon={isLoading && <CircularProgress sx={{ color: '#fff' }} size={20} />}
                  disabled={!isDisabled}
                >
                  <Typography className='button_font_family'>
                    {t('signup.confirm') || ''}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Form>
      <DialogMaxChars isDialogOpen={isDialogOpen} onDialogClose={onDialogClose} />
    </div>
  )
}

export default StepTwo
