import { Helmet } from 'react-helmet-async'
import React, { FC } from 'react'

// need to remove below hardcoded import later
import { LINKS } from '../../../constants'
import { Image } from '../../../assets/images'

const HelmetComponent: FC = () => {
  console

  return (
    <Helmet>
      {/* <title>{document.title}</title> */}
      <meta name='smartbanner:title' content='ใช้แอปโหลดเร็วกว่า' />
      <meta name='smartbanner:author' content='ตั้งเตือน/แนะนำละครโปรด' />
      <meta name='smartbanner:price-suffix-apple' content='ดาวน์โหลดฟรี' />
      <meta name='smartbanner:icon-apple' content={Image.One31FooterLogo} />
      <meta name='smartbanner:icon-google' content={Image.One31FooterLogo} />
      <meta name='smartbanner:button' content='Download' />
      <meta name='smartbanner:button-url-apple' content={LINKS.stores.apple} />
      <meta name='smartbanner:button-url-google' content={LINKS.stores.google} />
    </Helmet>
  )
}

export default HelmetComponent
