import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { getApiHeader } from '../../../utils/utils'
import { SearchQueryData } from '../../../types/page'
import config from '../../../config'

export const searchApi = createApi({
  reducerPath: 'searchApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.apiUrl}`,
    prepareHeaders: (headers, { getState }) => getApiHeader(headers, { getState })
  }),
  endpoints: (builder) => ({
    searchResult: builder.query<SearchQueryData, { q: string; page_size: number; page: number }>({
      query: (args) => {
        const { q, page_size, page } = args
        ;('GET')
        return {
          url: `/search/results?q=${q}`,
          params: { page_size, page }
        }
      }
    })
  })
})

export const { useLazySearchResultQuery } = searchApi
