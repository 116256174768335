import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { getApiHeader } from '../../../utils/utils'
import { UserProfile, UserPreferencesObject } from '../../../types/userProfile'
import config from '../../../config'

export const userCookieApi = createApi({
  reducerPath: 'userCookieApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.apiUrl}`,
    prepareHeaders: (headers, { getState }) => getApiHeader(headers, { getState }, true)
  }),
  endpoints: (builder) => ({
    getUserCookie: builder.query<UserProfile, void>({
      query: () => ({
        url: `/user/profile/preferences`,
        method: 'GET'
      })
    }),
    updateUserCookie: builder.mutation<UserPreferencesObject, Partial<UserPreferencesObject>>({
      query: (cookies) => ({
        url: `/user/profile/preferences`,
        method: 'POST',
        body: cookies
      })
    })
  })
})

export const { useGetUserCookieQuery, useLazyGetUserCookieQuery, useUpdateUserCookieMutation } =
  userCookieApi
