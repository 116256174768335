import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import TagManager from 'react-gtm-module'
import React, { FC, useEffect, useState } from 'react'
import { Box, createTheme, styled } from '@mui/system'
import { Drawer, useMediaQuery } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { Button, Flex } from '@brightcove/studio-components'

import ProfileImage from '../Profile/Components/ProfileImage'
import LanguageSelector from '../LanguageSelector/LanguageSelector'
import useSubscriptionStatus from '../Common/SubscriptionStatus/SubscriptionStatus'
import { hideToActivationPage } from '../../utils/utils'
import { clearSearch } from '../../store/slices/searchSlice'
import { languageSelector, userSelector } from '../../store/slices/config/configSelectors'
import { RootState } from '../../store'
import { Image } from '../../assets/images'

import MenuNavigation from './Components/MenuNavigation'
import HamburgerButton from './Components/HamburgerButton'

import './Header.scss'

const DrawerStyle = styled(Drawer)(() => ({
  '& .MuiDrawer-paper': {
    width: '60%',
    background: '#101010',
    position: 'relative',
    zIndex: 10000
  }
}))

interface HeaderProps {
  isTabletView?: boolean
  isSmartBannerClosed?: boolean
}

const Header: FC<HeaderProps> = ({ isTabletView = false, isSmartBannerClosed }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const [isScrolled, setIsScrolled] = useState(false)
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 600)
  const theme = createTheme()
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const user = useSelector(userSelector)
  const language = useSelector(languageSelector)
  const location = useLocation()
  const { notificationLength } = useSelector((state: RootState) => state.notification)
  const isSubscriptionUser = useSubscriptionStatus()
  const { headerMenuItems, headerSubMenuItems } = useSelector((state: RootState) => state.menu)

  useEffect(() => {
    const onResize = () => {
      setIsMobile(window.innerWidth < 600)
    }
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset
      setIsScrolled(scrollTop > 0)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleMobileMenuToggle = () => setIsMobileMenuOpen(!isMobileMenuOpen)

  const navigate = useNavigate()
  const routeChange = () => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'header_menu',
        event_category: 'header_menu',
        event_action: 'click_menu',
        event_label: 'my_account'
      }
    }
    TagManager.dataLayer(tagManagerArgs)
    const path = isSubscriptionUser ? `/profile/subscription` : `/profile/profile-detail`
    navigate(path)
  }
  const blockedUrls = ['/activation']

  const { pathname } = useLocation()
  if (pathname.startsWith('/signup')) return null

  if (
    location.pathname === '/password-reset' ||
    location.pathname === '/en/password-reset' ||
    hideToActivationPage(blockedUrls, pathname)
  )
    return null

  const tagManagerSearchClick = () => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'header_menu',
        event_category: 'header_menu',
        event_action: 'click_menu',
        event_label: 'search'
      }
    }
    TagManager.dataLayer(tagManagerArgs)
  }

  const tagManagerBackClick = () => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'header_menu',
        event_category: 'header_menu',
        event_action: 'click_menu',
        event_label: 'back_button'
      }
    }
    TagManager.dataLayer(tagManagerArgs)
  }

  const showSearchRoute = () => {
    tagManagerSearchClick()
    if (location.pathname !== '/search' && location.pathname !== `/${language}/search`) {
      dispatch(clearSearch())
      navigate('/search')
    }
  }

  const translateY = () => {
    let translate = `none`
    if (isTabletView) {
      if (location.pathname.includes('search') || location.pathname.startsWith('/continue')) {
        translate = `translateY(-84px)`
        if (isSmartBannerClosed) {
          translate = `none`
        }
      }
      if (location.pathname.startsWith('/profile')) {
        translate = `translateY(84px)`
        if (isSmartBannerClosed) {
          translate = `none`
        }
      }
    } else {
      if (location.pathname.startsWith('/profile')) {
        translate = `none`
      }
    }
    return translate
  }
  if (pathname.startsWith('/profile') && isMobile) {
    return null
  }

  const updatedMenu = () =>
    headerMenuItems.filter(
      (item) =>
        !((item.title === 'ONED PREMIUM' || item.title === 'วันดี พรีเมี่ยม') && isSubscriptionUser)
    )

  const menuItems = [...updatedMenu(), ...headerSubMenuItems]

  return (
    <Flex
      className={`header ${isScrolled ? 'scrolled' : ''}`}
      alignItems='center'
      justifyContent='space-between'
      style={{ transform: translateY() }}
    >
      <Box
        sx={{
          display: { sm: 'none' }
        }}
        style={{ flex: '0.3' }}
      >
        {location.pathname.includes('/video') ? (
          <Flex>
            <ArrowBackIosIcon scale={4} />{' '}
            <div
              onClick={() => {
                tagManagerBackClick()
                navigate(-1)
              }}
            >
              {t('content-detail.back')}
            </div>
          </Flex>
        ) : (
          <HamburgerButton handleMobileMenuToggle={handleMobileMenuToggle} />
        )}
      </Box>
      <Flex className='header-left' alignItems='center'>
        <div className='header-navbar-container'>
          <NavLink className='header-logo' to='/'>
            {(isMobile || isTabletView) && pathname.startsWith('/oned/original') ? (
              <img
                src={Image.OneDOriginalLogo}
                alt='OneD Logo'
                style={{ transform: 'translateX(20px)' }}
              />
            ) : (
              <img src={Image.One31Logo} alt='OneD Logo' />
            )}
          </NavLink>
        </div>
        {isMobileMenuOpen ? (
          <DrawerStyle
            open={isMobileMenuOpen}
            onClose={handleMobileMenuToggle}
            sx={{
              display: { sm: 'none' }
            }}
          >
            <MenuNavigation
              isMobile={isMobileMenuOpen && isMobile}
              onMobileClick={handleMobileMenuToggle}
              className='isMobile'
              mobileMenuItems={menuItems}
            />
          </DrawerStyle>
        ) : (
          <MenuNavigation isMobile={!isMobileMenuOpen && !isMobile} />
        )}
      </Flex>
      <Flex className='header-right'>
        {!isMobileScreen && (
          <Flex alignItems='center' className='lang-container'>
            <LanguageSelector />
            <div className='search-icon-container'>
              <SearchIcon
                fontSize='large'
                style={{
                  background: 'transparent',
                  marginLeft: '12px'
                }}
                onClick={showSearchRoute}
              />
            </div>
          </Flex>
        )}
        <Flex>
          <Box
            sx={{
              display: { sm: 'none', xs: 'none', md: 'block' }
            }}
          ></Box>
          {!user && (
            <Button
              className='button pink-button sign-in-button'
              look='secondary'
              text={t('header.sign_in')}
              onClick={() => {
                const tagManagerArgs = {
                  dataLayer: {
                    event: 'header_menu',
                    event_category: 'header_menu',
                    event_action: 'click_menu',
                    event_label: 'signin'
                  }
                }
                TagManager.dataLayer(tagManagerArgs)
                navigate('/login')
              }}
            />
          )}

          {user && (
            <Button className='profile-button' look='primary' onClick={routeChange}>
              {notificationLength > 0 && <div className='notf_counter'>{notificationLength}</div>}
              {user?.photoURL ? (
                <ProfileImage
                  url={(user?.photoURL as string) || ''}
                  className='profile_image'
                  description={user.displayName}
                />
              ) : (
                <AccountCircleIcon
                  fontSize='large'
                  style={{
                    background: 'transprent'
                  }}
                />
              )}
            </Button>
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Header
