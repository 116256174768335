import _ from 'lodash'
import { configureStore } from '@reduxjs/toolkit'

import userProfileReducer from './slices/userProfileSlice'
import userCookieReducer from './slices/userCookieSlice'
import signupReducer from './slices/signupSlice'
import searchReducer from './slices/searchSlice'
import playerReducer from './slices/playerSlice'
import notificationReducer from './slices/notificationSlice'
import myListReducer from './slices/myListSlice'
import menuReducer from './slices/menuSlice'
import marketingReducer from './slices/marketingSlice'
import livetvReducer from './slices/livetvSlice'
import forgotPasswordReducer from './slices/forgotPasswordSlice'
import checkEntitlementReducer from './slices/entitlementSlice'
import continueWatchingReducer from './slices/continueWatchingSlice'
import contentReducer from './slices/contentSlice'
import configReducer from './slices/config/configSlice'
import activationSlice from './slices/activationSlice'
import { userProfileApi } from './services/user/userProfileApi'
import { userCookieApi } from './services/user/userCookieApi'
import { userCheckApi } from './services/user/userCheckApi'
import { myListApi } from './services/user/myListApi'
import { continueWatchingApi } from './services/user/continueWatchingApi'
import { signupApi } from './services/signup/signupApi'
import { searchApi } from './services/search/searchApi'
import { pageApi } from './services/page/pageApi'
import { menuApi } from './services/menu/menuApi'
import { marketingApi } from './services/marketing/marketingApi'
import { livetvApi } from './services/livetv/livetvApi'
import { entitlementApi } from './services/entitlement/entitlementApi'
import { dynamicStringsApi } from './services/dynamicStrings/dynamicStringsApi'
import { contentApi } from './services/content/contentApi'
import { aisApi } from './services/ais/aisApi'
import { activationApi } from './services/activation/activationApi'

export const store = configureStore({
  reducer: {
    config: configReducer,
    forgotPassword: forgotPasswordReducer,
    signup: signupReducer,
    userProfile: userProfileReducer,
    search: searchReducer,
    myList: myListReducer,
    continueWatching: continueWatchingReducer,
    userCookie: userCookieReducer,
    contentDetails: contentReducer,
    channelDetails: livetvReducer,
    notification: notificationReducer,
    player: playerReducer,
    menu: menuReducer,
    marketing: marketingReducer,
    activationApiReducer: activationSlice,
    entitlement: checkEntitlementReducer,
    [userProfileApi.reducerPath]: userProfileApi.reducer,
    [signupApi.reducerPath]: signupApi.reducer,
    [pageApi.reducerPath]: pageApi.reducer,
    [searchApi.reducerPath]: searchApi.reducer,
    [myListApi.reducerPath]: myListApi.reducer,
    [continueWatchingApi.reducerPath]: continueWatchingApi.reducer,
    [userCookieApi.reducerPath]: userCookieApi.reducer,
    [contentApi.reducerPath]: contentApi.reducer,
    [dynamicStringsApi.reducerPath]: dynamicStringsApi.reducer,
    [livetvApi.reducerPath]: livetvApi.reducer,
    [userCheckApi.reducerPath]: userCheckApi.reducer,
    [menuApi.reducerPath]: menuApi.reducer,
    [marketingApi.reducerPath]: marketingApi.reducer,
    [activationApi.reducerPath]: activationApi.reducer,
    [entitlementApi.reducerPath]: entitlementApi.reducer,
    [aisApi.reducerPath]: aisApi.reducer
  },
  // Adding the api middleware enables caching, invalidation, polling, and other useful features.
  middleware: (getDefaultMiddleware) =>
    _.concat(
      getDefaultMiddleware(),
      userProfileApi.middleware,
      signupApi.middleware,
      pageApi.middleware,
      searchApi.middleware,
      myListApi.middleware,
      continueWatchingApi.middleware,
      userCookieApi.middleware,
      contentApi.middleware,
      dynamicStringsApi.middleware,
      livetvApi.middleware,
      userCheckApi.middleware,
      menuApi.middleware,
      marketingApi.middleware,
      activationApi.middleware,
      entitlementApi.middleware,
      aisApi.middleware
    )
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
