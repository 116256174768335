import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import TagManager from 'react-gtm-module'
import React, { FC, useEffect } from 'react'
import './Content.scss'
import { styled } from '@mui/system'
import { Container, Typography } from '@mui/material'

import {
  contentFailure,
  contentStart,
  contentSuccess,
  setSectionResponse
} from '../../store/slices/contentSlice'
import { userSelector } from '../../store/slices/config/configSelectors'
import { useLazyGetContentDetailsQuery } from '../../store/services/content/contentApi'
import { RootState } from '../../store'
import Sections from '../../components/Sections/Sections'
import Loading from '../../components/Loading/Loading'

export const ContainerStyles = styled(Container)(() => ({
  display: 'flex',
  flexDirection: 'column',
  transform: 'translateY(110px)',
  marginBottom: 110,
  '& h2': {
    color: '#fff',
    fontFamily: 'Prompt',
    fontSize: 30,
    fontStyle: 'normal',
    fontWeight: 500,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 30
  }
}))
const Content: FC = () => {
  const dispatch = useDispatch()
  const [contentSection]: any = useLazyGetContentDetailsQuery()
  const { path } = useParams()
  const { contentLoading, sectionResult } = useSelector((state: RootState) => state.contentDetails)
  const { menuItems } = useSelector((state: RootState) => state.menu)
  const { i18n } = useTranslation()
  const i18nLang = i18n.resolvedLanguage?.toLowerCase()
  const user = useSelector(userSelector)

  let contentPageId: any = ''

  const getHeading = () => {
    if (menuItems?.headerItems)
      for (const item of menuItems.headerItems) {
        if (path == item?.name) {
          contentPageId = item?.id
          if (i18nLang === 'th') return item?.name_th
          else if (i18nLang === 'en') return item?.name
        }
      }
  }

  useEffect(() => {
    document.title = 'oneD: ' + getHeading()
  }, [path, menuItems?.headerItems])

  useEffect(() => {
    dispatch(contentStart())
    const getContent = async () => {
      contentPageId = menuItems?.headerItems?.find((item) => item?.name === path)?.id
      const contentName = menuItems?.headerItems?.find((item) => item?.name === path)?.name
      if (contentName && contentName !== undefined) {
        const tagManagerArgs = {
          dataLayer: {
            event: 'page_view',
            page_name: 'catalog',
            page_location: location?.href,
            catalog_name: contentName,
            user_id: user?.['uid']
          }
        }
        TagManager.dataLayer(tagManagerArgs)
      }
      if (contentPageId && contentPageId !== undefined) {
        try {
          const sectResp = await contentSection({ id: contentPageId })
          dispatch(setSectionResponse(sectResp))
          dispatch(contentSuccess())
        } catch (err) {
          dispatch(contentFailure('' + err))
        }
      }
    }
    getContent()
  }, [path, menuItems?.headerItems])

  if (contentLoading) {
    return <Loading />
  }

  const getSections = (sectionArray) => {
    if (sectionArray && sectionArray.length > 0) {
      return [sectionArray[0]]
    }
  }

  return (
    <>
      <ContainerStyles>
        <Typography variant='h2'>{getHeading()}</Typography>
        <Sections sections={getSections(sectionResult?.['data']?.sections)} />
      </ContainerStyles>
    </>
  )
}

export default Content
