import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import TagManager from 'react-gtm-module'
import React, { FC, useEffect } from 'react'
import { Flex } from '@brightcove/studio-components'

import { getStorage } from '../../utils/localStorageSupport'
import { MyListObject } from '../../types/userProfile'
import { VideoContentItem } from '../../types/item'
import { setUserProfileData } from '../../store/slices/userProfileSlice'
import { myListFailure, myListStart, myListSuccess } from '../../store/slices/myListSlice'
import { userSelector } from '../../store/slices/config/configSelectors'
import { useLazyGetUserProfileQuery } from '../../store/services/user/userProfileApi'
import { useUpdateMyListMutation } from '../../store/services/user/myListApi'
import { RootState } from '../../store'
import './AddToMyList.scss'
import { SVGImage } from '../../assets/images'

interface AddToMyListProps {
  item?: VideoContentItem
  section?: any
  page?: string
}

const AddToMyList: FC<AddToMyListProps> = ({ item, section, page }) => {
  const { t } = useTranslation()
  const { data } = useSelector((state: RootState) => state.userProfile)
  const user = useSelector(userSelector)
  const dispatch = useDispatch()
  const [updateMyList]: any = useUpdateMyListMutation()
  const [getProfileData] = useLazyGetUserProfileQuery()
  const { myListLoading } = useSelector((state: RootState) => state.myList)

  const navigate = useNavigate()
  const storage = getStorage()

  const createTagParam = () => {
    if (page == 'homepage') {
      return {
        dataLayer: {
          event: 'add_to_list',
          event_category: 'homepage',
          event_action: 'add_to_list',
          page_title: 'homepage',
          event_label: 'add_to_list_button',
          collection_id: section?.id,
          collection_position: section?.position,
          collection_title: section?.name,
          content_id: item?.video_id,
          content_position: section?.items?.findIndex((obj) => obj?.video_id === item?.video_id),
          content_name: item?.display_name,
          content_type: item?.content_type,
          content_genre: item?.custom_fields?.['genre'],
          maturity_rating: item?.custom_fields?.['rating'],
          studio: item?.custom_fields?.['studio'],
          main_actor: item?.custom_fields?.['cast']
        }
      }
    } else if (page == 'vod_player') {
      return {
        dataLayer: {
          event: 'add_to_list',
          event_category: 'video',
          event_action: 'add_to_list',
          event_label: 'add_to_list_button',
          content_name: item?.display_name,
          content_type: item?.content_type,
          content_genre: item?.custom_fields?.['genre'],
          maturity_rating: item?.custom_fields?.['rating'],
          studio: item?.custom_fields?.['studio'],
          main_actor: item?.custom_fields?.['cast']
        }
      }
    }
  }

  const addToMyList = async () => {
    const tagManagerArgs = createTagParam()
    tagManagerArgs && TagManager.dataLayer(tagManagerArgs)
    if (user) {
      dispatch(myListStart(item?.video_id))
      try {
        const myListArray: string[] = []
        if (item) {
          myListArray?.push(item.video_id)
        }
        const addToListObj: MyListObject = {
          type: checkIfAddedToMyList() ? 'delete' : 'insert',
          items: myListArray
        }
        await updateMyList(addToListObj)
        const profileResponse = await getProfileData()
        dispatch(setUserProfileData(profileResponse?.['data']))
        dispatch(myListSuccess())
      } catch (err) {
        dispatch(myListFailure('' + err))
      }
    } else {
      navigate('/login')
    }
  }

  useEffect(() => {
    if (myListLoading != '' && myListLoading === item?.['video_id']) {
      if (document.getElementsByClassName(`spinner_mylist ${item?.video_id}`)[0]) {
        document
          .getElementsByClassName(`spinner_mylist ${item?.video_id}`)[0]
          .classList.remove('hide_loader')
      }
      if (document.getElementsByClassName(`add_done_container_parent ${item?.video_id}`)[0]) {
        document
          .getElementsByClassName(`add_done_container_parent ${item?.video_id}`)[0]
          .classList.add('hide_loader')
      }
    } else {
      if (document.getElementsByClassName(`spinner_mylist ${item?.video_id}`)[0]) {
        document
          .getElementsByClassName(`spinner_mylist ${item?.video_id}`)[0]
          .classList.add('hide_loader')
      }
      if (document.getElementsByClassName(`add_done_container_parent ${item?.video_id}`)[0]) {
        document
          .getElementsByClassName(`add_done_container_parent ${item?.video_id}`)[0]
          .classList.remove('hide_loader')
      }
    }
  }, [myListLoading])

  const checkIfAddedToMyList = () => {
    if (!data?.favorites || !item || storage?.getItem('token') === null) {
      return false
    }
    return data?.favorites?.filter((e) => e?.video_id === item?.video_id).length > 0
  }

  return (
    <Flex style={{ alignItems: 'center', textAlign: 'center' }} flexDirection='column'>
      <div onClick={() => addToMyList()}>
        <div className={`spinner_mylist hide_loader ${item?.video_id}`}></div>
        <div className={`add_done_container_parent ${item?.video_id}`}>
          <div className='add_done_container' style={{ marginBottom: '-6px' }}>
            {checkIfAddedToMyList() ? (
              <img className='add_my_list_done' src={SVGImage.TickIcon} />
            ) : (
              <img className='add_my_list' src={SVGImage.PlusIcon} />
            )}
          </div>
          <label
            className='Tile-info-add-text'
            style={{ color: checkIfAddedToMyList() ? '#F20B7E' : '#aaa' }}
          >
            {t('search.my_list')}
          </label>
        </div>
      </div>
    </Flex>
  )
}

export default AddToMyList
