import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import React, { FC, useState } from 'react'
import { Button, DialogContent } from '@mui/material'
import { Flex, FlexItem } from '@brightcove/studio-components'

import { cookiePolicyLink } from '../../utils/utils'
import { UserPreferencesObject } from '../../types/userProfile'
import { setUserProfileData } from '../../store/slices/userProfileSlice'
import {
  userCookieFailure,
  userCookieStart,
  userCookieSuccess
} from '../../store/slices/userCookieSlice'
import { userSelector } from '../../store/slices/config/configSelectors'
import { useLazyGetUserProfileQuery } from '../../store/services/user/userProfileApi'
import { useUpdateUserCookieMutation } from '../../store/services/user/userCookieApi'
import { useLocalStorage } from '../../hooks/UIHooks'
import { requestNotificationPermission } from '../../firebase'

import PrivacySettings from './PrivacySettings'
import { DialogStyles } from './PrivacyDialog.style'

interface PrivacyDialogProps {
  onUpdateDialog: { (): void }
}

const PrivacyDialog: FC<PrivacyDialogProps> = ({ onUpdateDialog }) => {
  const user = useSelector(userSelector)
  const dispatch = useDispatch()
  const [updateCookieDetails] = useUpdateUserCookieMutation()
  const [getProfileData] = useLazyGetUserProfileQuery()
  const [, setCookiesSettingsSession] = useLocalStorage('cookies_settings', '')
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(true)
  const [isShowPrivacy, setIsShowPrivacy] = useState<boolean>(false)
  const { i18n } = useTranslation()
  const i18nLang = i18n.resolvedLanguage?.toLowerCase()
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const [expanded, setExpanded] = useState(false)

  const toggleExpanded = () => {
    setExpanded(!expanded)
  }

  const saveUserCookiePreference = async () => {
    const userCookie: UserPreferencesObject = {
      cookies: {
        performace: true,
        functionality: true,
        targeting: true,
        advertising: true
      }
    }
    dispatch(userCookieStart())
    try {
      if (user) {
        sessionStorage?.removeItem('cookies_settings')
        await updateCookieDetails(userCookie)
        const profileResponse = await getProfileData()
        setCookiesSettingsSession(JSON.stringify(userCookie))
        dispatch(setUserProfileData(profileResponse?.['data']))
        dispatch(userCookieSuccess())
      } else {
        setCookiesSettingsSession(JSON.stringify(userCookie))
      }
      onUpdateDialog()
    } catch (err) {
      dispatch(userCookieFailure('' + err))
    }
  }

  const showPrivacySettings = () => {
    requestNotificationPermission()
    setIsShowPrivacy(true)
    setIsDialogOpen(false)
  }

  const onCloseDialog = () => {
    requestNotificationPermission()
    setIsDialogOpen(false)
    saveUserCookiePreference()
  }

  const restrictedPaths = ['/login', '/password-reset', '/signup']

  if (restrictedPaths.includes(pathname)) return null

  return (
    <>
      <DialogStyles
        open={isDialogOpen}
        maxWidth='md'
        disableScrollLock
        hideBackdrop
        disableEnforceFocus
        disableAutoFocus
      >
        <DialogContent>
          <Flex className='privacy-content'>
            <FlexItem flex='1' className='d-web'>
              <p className='cookie_disclaimer'>{t('home.cookie_disclaimer')}</p>
              <p className='cookie_more_info'>
                {t('home.more_info')}
                <a
                  className='cookie_cookie_policy'
                  href={`${cookiePolicyLink(i18nLang || '')}`}
                  target='_blank'
                  rel='noreferrer'
                >
                  {t('home.cookie_policy')}
                </a>
              </p>
            </FlexItem>
            <FlexItem flex='1' className='d-mob'>
              <p className='cookie_disclaimer'>
                {expanded
                  ? t('home.cookie_disclaimer')
                  : t('home.cookie_disclaimer').slice(0, 100) +
                    (t('home.cookie_disclaimer').length > 100 ? '...' : '')}
              </p>
              {t('home.cookie_disclaimer').length > 100 && (
                <p className='read-more cookie_cookie_policy' onClick={toggleExpanded}>
                  {!expanded && t('home.read_more')}
                </p>
              )}
              {expanded && (
                <p className='cookie_more_info'>
                  {t('home.more_info')}
                  <a
                    className='cookie_cookie_policy'
                    href={`${cookiePolicyLink(i18nLang || '')}`}
                    target='_blank'
                    rel='noreferrer'
                  >
                    {t('home.cookie_policy')}
                  </a>
                </p>
              )}
            </FlexItem>

            <FlexItem flex='.3' alignSelf='center'>
              <Flex
                flexDirection='column'
                alignItems='flex-end'
                justifyContent='center'
                className='mob-inline'
              >
                <Button className='cookie-button' onClick={onCloseDialog}>
                  {t('home.accept_all')}
                </Button>
                <Button
                  onClick={() => {
                    showPrivacySettings()
                  }}
                  className='confirm-choice'
                >
                  {t('home.cookie_settings')}
                </Button>
              </Flex>
            </FlexItem>
          </Flex>
        </DialogContent>
      </DialogStyles>
      {isShowPrivacy && (
        <div className='privacy_settings_holder'>
          <PrivacySettings
            onUpdateSettings={() => {
              onUpdateDialog()
            }}
          />
        </div>
      )}
    </>
  )
}

export default PrivacyDialog
