import { z } from 'zod'
import { Link, NavLink, useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Trans, useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import React, { FC, useEffect, useState } from 'react' //useEffect
import Cookies from 'js-cookie'
import parse from 'html-react-parser'
import { ConfirmationResult, RecaptchaVerifier, UserCredential, UserProfile } from 'firebase/auth'
import CircularProgress from '@mui/material/CircularProgress'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment
} from '@mui/material'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Visibility from '@mui/icons-material/Visibility'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { zodResolver } from '@hookform/resolvers/zod'
import { Checkbox, Flex, Form, Label } from '@brightcove/studio-components'

import { LinkText } from '../LinkText/LinkText'
import LanguageSelector from '../LanguageSelector/LanguageSelector'
import InputField from '../Common/Input/Input'
import SignInGTMEvents from '../Common/GTM/SignInGTMEvents'
import { isEmptyObject } from '../../utils/utils'
import { getStorage, isLocalStorageSupported } from '../../utils/localStorageSupport'
import { forgotPasswordStart } from '../../store/slices/forgotPasswordSlice'
import { User } from '../../store/slices/config/types'
import { hideLogIn, setToken, setUser } from '../../store/slices/config/configSlice'
import {
  useLazyGetUserProfileQuery,
  useUpdateUserProfileMutation
} from '../../store/services/user/userProfileApi'
import { useLazyGetUserCookieQuery } from '../../store/services/user/userCookieApi'
import { useLazyUserCheckPhoneQuery } from '../../store/services/user/userCheckApi'
import { RootState } from '../../store'
import { useBreakPoints } from '../../hooks/useBreakPoints'
import useAuth from '../../hooks/useAuth'
import { useLocalStorage } from '../../hooks/UIHooks'
import { auth } from '../../firebase'
import { Image, SVGImage } from '../../assets/images'
import './Login.scss'

import OTPDialog from './OTPScreen/OTPDialog'
import ErrorDialog from './Components/ErrorDialog'

const loginFormSchema = ({ ...rest }) => {
  const mobileValidation = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/)
  if (rest.isMobileValidation) {
    return z.object({
      emailOrPhone: z.string().regex(mobileValidation, rest.emailError)
    })
  } else {
    return z.object({
      emailOrPhone: z.string().email(rest.emailError),
      passwordOrOtp: z.string().min(8, rest.passwordError)
    })
  }
}

interface errorState {
  isError: boolean
  message?: string
  errorMessage?: string
}

const { REACT_APP_REDEEM_REDIRECT_URL, REACT_APP_AIS_REDIRECT_URL } = process.env

const Login: FC = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const [verificationId, setVerificationId] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [otpSent, setOtpSent] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const [confirmObj, setConfirmObj] = useState<ConfirmationResult | null>(null)
  const [isMobileValidation, setIsMobileValidation] = useState(false)
  const { isMobile } = useBreakPoints()
  const [, setCookiesUpdated] = useLocalStorage('cookies_updated')
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean | undefined>(false)
  const [isPhoneDisabled, setIsPhoneDisabled] = useState<boolean | undefined>(false)
  const [isInvalidOtp, setIsInvalidOtp] = useState<boolean | undefined>(false)
  const [errorDialog, setErrorDialog] = useState<errorState>({ isError: false, message: '' })
  // const [isShowOTPTimer, setIsShowOTPTimer] = useState<boolean>(true)
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(true)
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [isTimerCompleted, setIsTimerCompleted] = useState<boolean>(false)
  const [isGMTSignInSuccessful, setIsGMTSignInSuccessful] = useState<boolean>(false)
  const [GTMSignInType, setGTMSignInType] = useState<string>('')
  const [isGTMSignInUnsuccessful, setIsGTMSignInUnsuccessful] = useState<boolean>(false)
  const [GTMErrorMessage, setGTMErrorMessage] = useState<any>()
  const [isGMTSignInForgotPassword, setIsGMTSignInForgotPassword] = useState<boolean>(false)
  const [isGTMSignInSocial, setIsGTMSignInSocial] = useState<boolean>(false)
  const [isGTMSignUpButton, setIsGTMSignUpButton] = useState<boolean>(false)
  const [getProfileData] = useLazyGetUserProfileQuery()

  const { t } = useTranslation()
  const navigate = useNavigate()
  const { login, facebookLogin, googleLogin, appleLogin, loginWithPhone } = useAuth()
  const [getCookiePreference] = useLazyGetUserCookieQuery()
  const [updateUserProfile] = useUpdateUserProfileMutation()
  const { currentLocation } = useSelector((state: RootState) => state.menu)
  const [userCheckPhone]: any = useLazyUserCheckPhoneQuery()
  const [isPhoneDialogOpen, setIsPhoneDialogOpen] = useState<boolean>(false)
  const storage = getStorage()

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
    watch,
    clearErrors,
    setValue,
    setFocus
  } = useForm({
    resolver: zodResolver(
      loginFormSchema({
        emailError: t('login.invalid_email_tel'),
        passwordError: t('login.invalid_password'),
        isMobileValidation
      })
    ),
    mode: 'onSubmit',
    shouldUnregister: false,
    progressive: true
  })

  const emailOrPhone = watch('emailOrPhone', '')
  const passwordOrOtp = watch('passwordOrOtp', '')

  const isSubmitDisabled =
    isMobileValidation && emailOrPhone.length > 9
      ? emailOrPhone.length
      : emailOrPhone.length && passwordOrOtp.length

  const isPasswordVisible = (): boolean | undefined => {
    if (emailOrPhone.length < 10) return true

    const hasNonNumeric = /[a-zA-Z@.]+/.test(emailOrPhone)
    return hasNonNumeric
  }

  const getUserCookiePreference = async () => {
    if (localStorage?.getItem('token')) {
      const data = await getCookiePreference()
      if (Object.keys(data?.['data']?.['cookies']).length > 0) {
        setCookiesUpdated(true)
        const element = document.getElementsByClassName('cookie_dialog_container')[0]
        if (element) {
          element.classList.add('hide_settings')
        }
        const settingsElement = document.getElementsByClassName('privacy_settings_holder')[0]
        if (settingsElement) {
          settingsElement.classList.add('hide_settings')
        }
      }
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const checkSubscription = () => {
    getProfileData().then((profileResponse) => {
      if (profileResponse?.data?.subscription?.subscription_status?.toLowerCase() === 'active') {
        return true
      } else if (
        profileResponse?.data?.subscription?.subscription_status?.toLowerCase() === 'inactive' ||
        isEmptyObject(profileResponse?.data?.subscription)
      ) {
        return false
      }
    })
  }

  const navigatingLocation = () => {
    getProfileData().then((profileResponse) => {
      const subscriptionStatus = profileResponse.data?.subscription?.subscription_status
      const spAccountId = profileResponse.data?.id

      if (location.state) {
        const { type, packageId } = location.state
        if (type === 'REDEEM') {
          window.location.href = `${REACT_APP_REDEEM_REDIRECT_URL}?spAccountId=${spAccountId}`
        } else if (type === 'ACTIVATE') {
          window.location.href = `${REACT_APP_AIS_REDIRECT_URL}?spAccountId=${spAccountId}&packageId=${packageId}`
        }
      } else {
        if (currentLocation?.includes('activation')) {
          navigate(currentLocation as string)
        } else {
          subscriptionStatus ? navigate(currentLocation as string) : navigate('/subscription')
        }
      }
    })

    // if (location.state?.aisContinue && !subscriptionStatus) {
    //   const packageId = location.state.packageId ?? ''
    //   navigate('/ais/activate', { state: { previousPath: location.pathname, packageId } })
    //   return
    // }
    // if (location.state?.previousPath !== '') {
    //   navigate(location.state?.previousPath)
    //   return
    // }
    // if (currentLocation?.includes('activation')) {
    //   navigate(currentLocation as string)
    // } else {
    //   subscriptionStatus ? navigate(currentLocation as string) : navigate('/subscription')
    // }
  }

  const handleSignIn = async (data) => {
    setIsButtonDisabled(true)
    const recaptchaVerifier: any = new RecaptchaVerifier(
      'recaptcha-container',
      {
        size: 'invisible'
      },
      auth
    )
    const { emailOrPhone, passwordOrOtp } = data
    setPhoneNumber(emailOrPhone)
    try {
      if (isPasswordVisible()) {
        const userCreds = await login(emailOrPhone, passwordOrOtp)
        if (userCreds.user) {
          dispatch(setUser(userCreds.user.toJSON() as User))
          const token = storage?.getItem('token')
          dispatch(setToken(token))
          getUserCookiePreference()
          !isLocalStorageSupported()
            ? sessionStorage?.setItem('shouldNavigateBack', 'true')
            : storage.setItem('shouldNavigateBack', 'true')
          setIsGMTSignInSuccessful(true)
          setGTMSignInType('email')
          setTimeout(() => {
            navigatingLocation()
          }, 100)
        }
      } else {
        let taiPhoneNumber = ''
        if (emailOrPhone.startsWith(0)) {
          taiPhoneNumber = emailOrPhone.replace(0, '+66')
        }
        // Verifying the user if already signed in from phone number
        const response = await userCheckPhone(taiPhoneNumber || emailOrPhone)
        // Sign in with phone number and OTP
        if (!response.isSuccess) {
          setIsPhoneDialogOpen(true)
          return
        }
        await recaptchaVerifier.verify()
        if (recaptchaVerifier?.destroyed === false) {
          const confirmationResult = await loginWithPhone(
            taiPhoneNumber || emailOrPhone,
            recaptchaVerifier
          )
          setVerificationId(confirmationResult.verificationId)
          setConfirmObj(confirmationResult)
          setOtpSent(true)
          getUserCookiePreference()
          recaptchaVerifier.clear()
        }
      }
      reset({
        emailOrPhone: '',
        passwordOrOtp: ''
      })
    } catch (error: any) {
      setIsButtonDisabled(false)
      setIsGTMSignInUnsuccessful(true)
      setTimeout(() => {
        setIsGTMSignInUnsuccessful(false)
      }, 1000)
      setGTMErrorMessage('Invalid user/password')
      if (error.code === 'auth/invalid-phone-number') {
        setError('emailOrPhone', {
          type: 'manual',
          message: t('signup.enter_valid_email_phone') as string
        })
      }
      if (error?.code === 'auth/user-not-found') {
        setError('emailOrPhone', {
          type: 'manual',
          message: t('login.invalid_email_tel') as string
        })
      }
      if (error?.code === 'auth/wrong-password') {
        setError('passwordOrOtp', {
          type: 'manual',
          message: t('login.invalid_password') as string
        })
      }
      if (error?.code === 'auth/too-many-requests') {
        setErrorDialog({
          isError: true,
          message: error?.message
        })
      }
      if (error?.code === 'auth/invalid-verification-code') {
        setErrorDialog({
          isError: false
        })
        setIsInvalidOtp(true)
      }
    }
  }

  const onPhoneDialogClose = () => {
    setIsPhoneDialogOpen(false)
    setIsButtonDisabled(false)
    setValue('emailOrPhone', '')
    setFocus('emailOrPhone')
  }

  useEffect(() => {
    if (!isPhoneDialogOpen) setFocus('emailOrPhone')
  }, [isPhoneDialogOpen])

  const onIsTimerFinished = (isFinished: boolean) => setIsTimerCompleted(isFinished)

  const handleOTPSubmit = async (data): Promise<void> => {
    // setIsShowOTPTimer(false)
    if (isTimerCompleted) return

    try {
      const { otp } = data
      if (otp === undefined || otp.length < 6) {
        setIsInvalidOtp(true)
        return
      } else {
        setIsInvalidOtp(false)
      }

      const currentUserDetail = await confirmObj?.confirm(otp)
      if (currentUserDetail?.user) {
        const token = await currentUserDetail.user.getIdToken()
        storage?.setItem('token', token)
        Cookies.set('authToken', token)
        setOtpSent(false)
        !isLocalStorageSupported()
          ? sessionStorage?.setItem('shouldNavigateBack', 'true')
          : storage.setItem('shouldNavigateBack', 'true')
        setIsGMTSignInSuccessful(true)
        setGTMSignInType('phone')
        setTimeout(() => {
          navigatingLocation()
        }, 100)
      }
    } catch (error: any) {
      if (error?.code === 'auth/invalid-verification-code') {
        setErrorDialog({
          isError: false
        })
        setIsInvalidOtp(true)
      }
    }
  }

  // Event handler for checkbox change
  const handleCheckboxChange = (event): void => {
    setIsChecked(event)
  }

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const hideLogInHandler = (event) => {
    const redirect = event?.target?.getAttribute('data-redirect')
    if (redirect.includes('password')) {
      setIsGMTSignInForgotPassword(true)
    }
    if (redirect.includes('signup')) {
      setIsGTMSignUpButton(true)
    }
    dispatch(hideLogIn())
    dispatch(forgotPasswordStart())
    setTimeout(() => {
      navigate(redirect)
    }, 0)
  }

  type Providers = 'google' | 'facebook' | 'apple'

  const onSocialLogin = async (provider: Providers) => {
    try {
      let firebaseProvider: UserCredential | null = null
      switch (provider) {
        case 'google':
          setIsGTMSignInSocial(true)

          setGTMSignInType('google')
          firebaseProvider = await googleLogin()
          break
        case 'facebook':
          setIsGTMSignInSocial(true)

          setGTMSignInType('facebook')
          firebaseProvider = await facebookLogin()
          break
        case 'apple':
          setIsGTMSignInSocial(true)

          setGTMSignInType('apple')
          firebaseProvider = await appleLogin()
          break
        default:
          setIsGTMSignInSocial(false)

          throw new Error('Unsupported provider')
      }
      if (firebaseProvider) {
        const user = firebaseProvider.user
        if (user) {
          const displayName = user?.displayName
          const splitName = displayName?.split(' ')

          const data: UserProfile = {
            date_of_birth: undefined,
            email: user?.email
              ? user?.email
              : firebaseProvider?.['_tokenResponse']?.['email']
                ? firebaseProvider?.['_tokenResponse']?.['email']
                : '',
            firstName: splitName?.length && splitName[0],
            lastName: splitName?.length && splitName?.slice(1).join(' '),
            gender: '',
            phone: user.phoneNumber
          }
          await updateUserProfile(data)
          !isLocalStorageSupported()
            ? sessionStorage?.setItem('shouldNavigateBack', 'true')
            : storage.setItem('shouldNavigateBack', 'true')
          navigatingLocation()
        }
      }
    } catch (error) {
      // Error handling
    }
  }

  useEffect(() => {
    if (!isPasswordVisible()) {
      setIsMobileValidation(true)
    } else {
      setIsMobileValidation(false)
    }
    const hasNonNumeric = /[a-zA-Z@.]+/.test(emailOrPhone)
    if (!hasNonNumeric && emailOrPhone && emailOrPhone.length < 10) {
      setIsPhoneDisabled(true)
    } else if (emailOrPhone && emailOrPhone.length >= 10) {
      setIsPhoneDisabled(false)
    }
    if (hasNonNumeric) setIsPhoneDisabled(false)
  }, [isPasswordVisible])

  const onDialogClose = () => {
    setIsDialogOpen(false)
    setIsButtonDisabled(false)
    navigate(0)
  }

  // if (sessionStorage.getItem('shouldNavigateBack')) {
  //   navigate(currentLocation as string)
  // }

  return (
    <>
      {/* <RedirectBack auth={auth} /> */}
      <Flex
        className='login'
        alignItems='center'
        justifyContent='flex-start'
        flexDirection='column'
      >
        {isMobile && (
          <div className='mobile-login-header'>
            <Flex>
              <ArrowBackIosIcon scale={4} />{' '}
              <div onClick={() => navigate(-1)}>{t('login.back')}</div>
            </Flex>
            <LanguageSelector />
          </div>
        )}
        <Flex
          className='login-wrapper'
          alignItems='center'
          justifyContent='center'
          flexDirection='column'
        >
          <NavLink className='' to='/'>
            <img className='login-logo' src={Image.One31Logo} alt='OneD logo' />
          </NavLink>
          <span className='login-header'>{parse(t('login.header'))}</span>

          <Form className='login-form' onSubmit={handleSubmit(handleSignIn)}>
            <div className='login-column-layout'>
              <Label className='login-label'>{t('login.email_tel_label') || ''}</Label>
              <InputField
                name='emailOrPhone'
                register={register}
                errors={errors}
                clearErrors={clearErrors}
                setValue={setValue}
                defaultValue={emailOrPhone || ''}
              />
            </div>

            {isPasswordVisible() && (
              <div className='login-column-layout'>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Label className='login-label'>{t('login.password_label') || ''}</Label>
                  <Link
                    className='login-forgot-password-link'
                    to=''
                    data-redirect='/password-reset'
                    onClick={hideLogInHandler}
                  >
                    {t('login.forgot_password')}
                  </Link>
                </div>
                <InputField
                  name='passwordOrOtp'
                  type={showPassword ? 'text' : 'password'}
                  register={register}
                  errors={!errors.emailOrPhone ? errors : undefined}
                  clearErrors={clearErrors}
                  setValue={setValue}
                  defaultValue={passwordOrOtp || ''}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                        edge='end'
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </div>
            )}
            {otpSent && (
              <OTPDialog
                handleOTPSubmit={handleOTPSubmit}
                verificationId={verificationId}
                isInvalidOtp={isInvalidOtp}
                // isShowOTPTimer={isShowOTPTimer}
                isDialogOpen={isDialogOpen}
                onDialogClose={onDialogClose}
                phoneNumber={phoneNumber}
                isTimerFinished={onIsTimerFinished}
                isTimerExpired={isTimerCompleted}
              />
            )}

            <Button
              sx={{
                '&.Mui-disabled': {
                  opacity: 0.5,
                  backgroundColor: '#F20B7E',
                  color: 'white'
                },
                borderRadius: '12px',
                backgroundColor: '#F20B7E',
                fontSize: '18px',
                color: 'white',
                fontFamily: 'Prompt',
                fontWeight: '500',
                lineHeight: '23px',
                '&:hover': {
                  cursor: 'pointer',
                  backgroundColor: '#F20B7E'
                },
                padding: '16px 24px'
              }}
              type='submit'
              variant='contained'
              fullWidth
              endIcon={isButtonDisabled && <CircularProgress sx={{ color: '#fff' }} size={20} />}
              disabled={
                !isSubmitDisabled ||
                isPhoneDisabled ||
                isButtonDisabled ||
                (isMobileValidation && isPasswordVisible())
              }
            >
              {t('login.sign_in')}
            </Button>
          </Form>

          <Flex alignItems='center' justifyContent='center' className='login-separator'>
            <span>{t('login.or')}</span>
          </Flex>
          <Flex alignItems='center' justifyContent='center'>
            <Checkbox
              className='login-terms-conditions'
              key='terms_and_conditions'
              onChange={handleCheckboxChange}
              checked={isChecked}
            >
              <Trans
                i18nKey='login.policy_links'
                components={{
                  link_terms: (
                    <LinkText
                      defaultText='ข้อกำหนดและเงื่อนไข'
                      linkKey='terms'
                      textKey='legal.terms'
                      className='login-link-text'
                    />
                  ),
                  link_privacy: (
                    <LinkText
                      defaultText='นโยบายความเป็นส่วนตัว'
                      linkKey='privacy'
                      textKey='legal.privacy'
                      className='login-link-text'
                    />
                  )
                }}
              />
            </Checkbox>
          </Flex>
          <Flex
            className={!isChecked ? 'login-with-social disabled-button' : 'login-with-social'}
            alignItems='center'
            justifyContent='center'
            flexDirection='column'
          >
            <Button
              className='button social-button facebook'
              onClick={() => onSocialLogin('facebook')}
              disabled={!isChecked}
            >
              <Flex alignItems='center' justifyContent='center'>
                <img className='social-icon' src={Image.FacebookIcon} alt='Facebook Icon' />
                <span className='social-text'>{t('login.sign_in_with_facebook')}</span>
              </Flex>
            </Button>
            <Button
              className='button white-button social-button'
              onClick={() => onSocialLogin('google')}
              disabled={!isChecked}
            >
              <Flex alignItems='center' justifyContent='center'>
                <img className='social-icon' src={Image.GoogleIcon} alt='Google Icon' />
                <span className='social-text-ga'>{t('login.sign_in_with_google')}</span>
              </Flex>
            </Button>
            <Button
              className='button white-button social-button'
              onClick={() => onSocialLogin('apple')}
              disabled={!isChecked}
            >
              <Flex alignItems='center' justifyContent='center'>
                <img className='social-icon' src={SVGImage.AppleIcon} alt='Apple Icon' />
                <span className='social-text-ga'>{t('login.sign_in_with_apple')}</span>
              </Flex>
            </Button>
          </Flex>
          <div className='login-sign-up-link'>
            <span>{t('login.not_a_member')}?</span>
            <Link to='' data-redirect='/signup' onClick={hideLogInHandler}>
              {t('login.sign_up')}
            </Link>
          </div>
          <div id='recaptcha-container' style={{ display: 'none' }}></div>
        </Flex>
        {errorDialog.isError && (
          <ErrorDialog
            message={errorDialog.message}
            setClosed={() => {
              setIsButtonDisabled(false)
              setErrorDialog({ isError: false, message: '' })
            }}
          />
        )}
      </Flex>
      <SignInGTMEvents
        isSignInSuccessful={isGMTSignInSuccessful}
        signInType={GTMSignInType}
        isSignInUnsuccessful={isGTMSignInUnsuccessful}
        errorMessage={GTMErrorMessage}
        isSignInForgotPassword={isGMTSignInForgotPassword}
        isSignInSocial={isGTMSignInSocial}
        isSignUpButton={isGTMSignUpButton}
      />
      {/* Showing dialog box for existing user who registered with phone, but not subscribed  */}
      <Dialog
        open={isPhoneDialogOpen}
        PaperProps={{
          className: 'login_error_phone-number'
        }}
      >
        <DialogContent>
          <DialogTitle variant='h6'>{t('login.dialog.title')}</DialogTitle>
          <DialogTitle variant='body1'>{t('login.dialog.description')}</DialogTitle>
          <DialogActions>
            <Button onClick={onPhoneDialogClose}>{t('login.dialog.signup')}</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default Login
