import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import React, { FC } from 'react'

import { Image } from '../../../../assets/images'

import { LogoStyles } from './Logo.style'

const ActivationLogo: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <LogoStyles className='activation-logo' onClick={() => navigate('/home')}>
      <img className='logo' src={Image.One31Logo} alt='OneD Logo' />
      <h3 className='logo_label'>{t('activation.logo_label')}</h3>
    </LogoStyles>
  )
}

export default ActivationLogo
