import React, { FC } from 'react'

interface HamburgerButtonI {
  handleMobileMenuToggle: () => void
}
const HamburgerButton: FC<HamburgerButtonI> = ({ handleMobileMenuToggle }) => (
  <div className='header-mobile-menu-icon' onClick={() => handleMobileMenuToggle()}>
    <span></span>
    <span></span>
    <span></span>
  </div>
)
export default HamburgerButton
