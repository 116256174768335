import { FacebookShareButton } from 'react-share'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet-async'
import React, { FC } from 'react'
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded'
import { Flex } from '@brightcove/studio-components'

import { VideoContentItem } from '../../types/item'

interface FbShareProps {
  item?: VideoContentItem
  onClickFb: { (): void }
}
const FbShare: FC<FbShareProps> = ({ onClickFb, item }) => {
  const { t } = useTranslation()
  return (
    <Flex flexDirection='row' className={'share-label'}>
      <Helmet>
        <meta property='og:description' content={item?.description} />
        <meta property='og:image' content={item?.images?.['poster']?.src} />
      </Helmet>
      <FacebookShareButton
        className={'share-label'}
        url={location?.href}
        title={item?.display_name}
        beforeOnClick={() => {
          onClickFb()
        }}
      >
        <FacebookRoundedIcon
          fontSize='medium'
          className={'share-icon white_icon'}
          style={{
            fill: 'white',
            width: '20px',
            height: '20px'
          }}
        />
        <label className={'share-label-text'}>{t('content-detail.fb_share')}</label>
      </FacebookShareButton>
    </Flex>
  )
}

export default FbShare
