import { useTranslation } from 'react-i18next'
import React, { FC } from 'react'
import TableRow from '@mui/material/TableRow'
import TableHead from '@mui/material/TableHead'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import { Table } from '@mui/material'

import { PackageItemsStyle } from './PackageItems.style'

interface PackageItemsI {
  plans: any // need to change once real data
}

const PackageItems: FC<PackageItemsI> = ({ plans }) => {
  const { t } = useTranslation()
  return (
    <PackageItemsStyle>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell align='left'>{t('pricing.package_plans')}</TableCell>
            <TableCell align='center'>{t('pricing.free')}</TableCell>
            <TableCell align='center'>{t('pricing.premium')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {plans.map((plan) => (
            <TableRow key={plan.title} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component='th' scope='row'>
                {plan.title}{' '}
                {plan.subtitle && <span style={{ color: '#F20B7E' }}>{plan.subtitle}</span>}
              </TableCell>
              <TableCell align='center'>
                {plan.free.text}
                {plan.free.icon ? plan.free.icon : null}
              </TableCell>
              <TableCell align='center'>
                {plan.premium.text}
                {plan.premium.icon ? plan.premium.icon : null}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </PackageItemsStyle>
  )
}

export default PackageItems
