import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { getApiHeader } from '../../../utils/utils'
import { UserProfile } from '../../../types/userProfile'
import config from '../../../config'

export const userProfileApi = createApi({
  reducerPath: 'userProfileApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.apiUrl}`,
    prepareHeaders: (headers, { getState }) => getApiHeader(headers, { getState }, true)
  }),
  endpoints: (builder) => ({
    getUserProfile: builder.query<UserProfile | null, void>({
      query: () => ({
        url: `/user/profile`,
        method: 'GET'
      })
    }),
    updateUserProfile: builder.mutation<UserProfile, Partial<UserProfile>>({
      query: (userProfileData) => ({
        url: `/user/profile`,
        method: 'POST',
        body: userProfileData
      })
    })
  })
})

export const { useGetUserProfileQuery, useUpdateUserProfileMutation, useLazyGetUserProfileQuery } =
  userProfileApi
