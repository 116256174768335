import { useNavigate, useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import React, { FC, useEffect, useState } from 'react'
import './ContentDetailsPage.scss'

import { getStorage } from '../../utils/localStorageSupport'
import { updateEntitlement, updateSuccessEntitlement } from '../../store/slices/entitlementSlice'
import { useGetVideoDetailPageQuery } from '../../store/services/page/pageApi'
import { useLazyCheckEntitlementApiQuery } from '../../store/services/entitlement/entitlementApi'
import { RootState } from '../../store'
import { entitlementErrorCodes, entitlementPayload } from '../../constants'
import Sections from '../../components/Sections/Sections'
import PageHead from '../../components/PageHead/PageHead'
import Loading from '../../components/Loading/Loading'
import DialogSubscribe from '../../components/Common/DialogSubscribe/DialogSubscribe'

const ContentDetailsPage: FC = () => {
  const [isPageLoading, setIsPageLoading] = useState(true)
  const { id } = useParams()
  const { data, isLoading } = useGetVideoDetailPageQuery({ id })
  const [checkEntitlementApi] = useLazyCheckEntitlementApiQuery()
  const { isLogged } = useSelector((state: RootState) => state.userProfile)
  const { isNonValidEntitlement } = useSelector((state: RootState) => state.entitlement)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isSubscribeDialogOpen, setIsSubscribeDialogOpen] = useState(false)
  const [availableAfterValue, setAvailableAfterValue] = useState(null)
  const storage = getStorage()

  const uid = storage?.getItem('uid')

  // const { currentLocation } = useSelector((state: RootState) => state.menu)

  useEffect(() => {
    if (isLogged) {
      fetchData()
    }
  }, [isLogged])

  const fetchData = async () => {
    const payload = entitlementPayload(uid as string, id as string)

    try {
      const response: any = await checkEntitlementApi(payload)
      setIsPageLoading(false)
      if (response.status === 'rejected') {
        const errorCode = response?.error?.data?.code
        dispatch(updateEntitlement(true))
        if (entitlementErrorCodes.includes(errorCode)) {
          if (response?.error?.data?.message?.includes('availableAfter')) {
            const pattern = /availableAfter: (\d+)/
            setAvailableAfterValue(response?.error?.data?.message.match(pattern)[1])
          }
          setIsSubscribeDialogOpen(true)
        }
      } else if (response.status === 'fulfilled') {
        dispatch(updateEntitlement(false))
        dispatch(updateSuccessEntitlement(response.data))
      }
    } catch (error: any) {
      if (entitlementErrorCodes.includes(error.code)) {
        dispatch(updateSuccessEntitlement(true))
      }
    }
  }

  const handleSubscribeDialogClose = () => {
    setIsSubscribeDialogOpen(false)
  }

  const handleSubscribeDialogToSubscribe = () => {
    setIsSubscribeDialogOpen(false)
    navigate('/oned/premium')
  }

  useEffect(() => {
    if (isNonValidEntitlement) {
      // alert()
    }
  }, [isNonValidEntitlement])

  useEffect(() => {
    fetchData()
  }, [id])

  if (isLoading) {
    return <Loading />
  }
  return (
    <div>
      <PageHead data={data?.head} />
      {isPageLoading ? (
        <Loading />
      ) : (
        <>
          <div className='main-content-detail'>
            <div className='main-container'>
              <Sections sections={data?.sections} page={'vod_player'} />
            </div>
          </div>
          <DialogSubscribe
            isDialogOpen={isSubscribeDialogOpen}
            message={availableAfterValue ? 'dialog.sub_title_error' : 'dialog.sub_title_success'}
            availableAfter={availableAfterValue}
            onDialogClose={handleSubscribeDialogClose}
            onSubscribeNow={handleSubscribeDialogToSubscribe}
          />
        </>
      )}
    </div>
  )
}

export default ContentDetailsPage
