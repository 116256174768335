import SuccessTick from './subscription/check-hD45blZCuI.png'
import OneDLogo from './subscription/LOGO-ONE.png'
import ShowPasswordIcon from './login/icon_password_show.svg'
import HidePasswordIcon from './login/icon_password_hide.svg'
import GoogleIcon from './login/icon_google.png'
import FacebookIcon from './login/icon_facebook.png'
import AppleIcon from './login/icon_apple.svg'
import OneDOriginalLogo from './header/logo_One_D_Original.png'
import One31Logo from './header/logo.png'
import TwitterIcon from './footer/x-twitter.svg'
import OneDOriginalSelected from './footer/oneDOriginalSelected.svg'
import OneDOriginal from './footer/oneDOriginal.svg'
import One31FooterLogo from './footer/logo.png'
import YoutubeLogo from './footer/icon_youtube.svg'
import TikTokIcon from './footer/icon_tiktok.svg'
import InstagramLogo from './footer/icon_instagram.svg'
import FacebookLogo from './footer/icon_facebook.svg'
import TickIcon from './custom/tick.svg'
import ShareIcon from './custom/share.svg'
import SearchIcon from './custom/search.svg'
import prev_item from './custom/rewind.svg'
import QRImageStage from './custom/qr-code-stage.png'
import QRImageQA from './custom/qr-code-qa.png'
import QRImagePROD from './custom/qr-code-prod.png'
import PricingBg from './custom/pricingBg.png'
import PlusIcon from './custom/plus.svg'
import PlayWhiteIcon from './custom/play_white.svg'
import PlayIcon from './custom/play.svg'
import NotfClose from './custom/notf_close.svg'
import next_item from './custom/forward.svg'
import ProblemIcon from './content/problem_icon.svg'

const Image = {
  One31Logo,
  One31FooterLogo,
  FacebookIcon,
  GoogleIcon,
  OneDLogo,
  SuccessTick,
  OneDOriginalLogo,
  PricingBg,
  QRImageQA,
  QRImageStage,
  QRImagePROD
}

const SVGImage = {
  ProblemIcon,
  AppleIcon,
  ShowPasswordIcon,
  HidePasswordIcon,
  TikTokIcon,
  FacebookLogo,
  ShareIcon,
  TickIcon,
  SearchIcon,
  PlayIcon,
  PlayWhiteIcon,
  NotfClose,
  next_item,
  prev_item,
  PlusIcon,
  TwitterIcon,
  InstagramLogo,
  YoutubeLogo,
  OneDOriginal,
  OneDOriginalSelected
}

export { Image, SVGImage }
