import { useTranslation } from 'react-i18next'
import TagManager from 'react-gtm-module'
import React, { FC } from 'react'
import classnames from 'classnames'

import './LinkText.scss'

import { LINKS } from '../../constants'

interface LinkTextProps {
  defaultText: string
  textKey: string
  linkKey: string
  className?: string
}

export const LinkText: FC<LinkTextProps> = ({ defaultText, linkKey, textKey, className }) => {
  const { i18n } = useTranslation()
  const i18nLang = i18n.resolvedLanguage?.toLowerCase()
  const { t } = useTranslation()

  const getDescendantProp = (obj, desc) => {
    const arr = desc.split('.')
    while (arr.length) {
      obj = obj[arr.shift()]
    }
    return obj
  }

  let title = t(textKey)
  if (title === textKey) {
    title = defaultText
  }
  const to = getDescendantProp(LINKS, linkKey + '.' + i18nLang)
  const tagManagerClick = () => {
    if (linkKey === 'terms' || linkKey === 'privacy') {
      const tagManagerArgs = {
        dataLayer: {
          event: 'footer_menu',
          event_category: 'footer_menu',
          event_action: 'click_menu',
          event_label:
            linkKey === 'terms' ? 'terms_and_condition' : linkKey === 'privacy' ? 'policy' : ''
        }
      }
      TagManager.dataLayer(tagManagerArgs)
    }
  }
  return (
    <a
      href={to || '#'}
      target='_blank'
      title={title || defaultText}
      rel='noreferrer'
      className={classnames('link-text', className)}
      onClick={tagManagerClick}
    >
      {title || defaultText}
    </a>
  )
}
